import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { logout } from "../features/User";
import { WEB_THEME } from "../features/Exchange";
import { logourl } from "../utils/api";
import $ from "jquery";

export default function MyNavbar(props) {
  const { isLoggedIn } = useSelector((state) => state?.user?.value);
  const { switch_theme } = useSelector((state) => state?.exchange?.value);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [close, setClose] = useState(false);
  const langs = {
    en: "English/USD",
    hi: "Hindi"
  };
  // const [currentLang, setCurrentLang] = useState(langs.en);
  function toogleTheme(e) {
    if (e.target.checked) {
      dispatch(WEB_THEME({ switchtheme: "light" }));
      localStorage.removeItem("tradingview.current_theme.name");
      localStorage.setItem("exchange_inrx_theme", "light");
      const body = document.getElementsByTagName("body")[0];
      body.classList.add("is_light");
      body.classList.remove("is_dark");
    } else {
      dispatch(WEB_THEME({ switchtheme: "dark" }));
      localStorage.removeItem("tradingview.current_theme.name");
      localStorage.setItem("exchange_inrx_theme", "dark");
      const body = document.getElementsByTagName("body")[0];
      body.classList.add("is_dark");
      body.classList.remove("is_light");
    }
    let html = document.getElementsByTagName("html")[0];
    console.log(html.dataset, "im calling");
  }

  return (
    <>
      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
          <Link className="navbar-brand" to="/">
            <img
              src={`${logourl}images/logo.png`}
              className=""
              style={{ height: "25px" }}
              alt="logo"
            />
          </Link>

          <button
            class="navbar-toggler navbar_btn"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
            onClick={() => {
              $(".modal-backdrop.fade.show").remove();
            }}
          >
            {/* <span class="navbar-toggler-icon"></span> */}
            <span>
              <img src="/images/menu.png" className="brightness" />
            </span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mb-lg-0 align-items-center ms-auto">
            <li className="nav-item">
                    <NavLink
                      to="/exchange/btc-inr"
                      className="nav-link"
                      onClick={() => {
                        $(".offcanvas").removeClass("show");
                      }}
                    >
                      Exchange
                    </NavLink>
                  </li>
                  <li className="nav-item">
                  <NavLink
                    to="/p2pOrder"
                    className="nav-link fs-16"
                    onClick={() => {
                      const Exchange =
                        document.getElementsByClassName("btn-close")[0];
                      Exchange.click();
                    }}
                  >
                   p2p
                  </NavLink>
                </li>
              {isLoggedIn ? (
                <>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdownMenuLink"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Account
                    </a>
                    <ul class="dropdown-menu  dropdown-menu-dark">
                      <li>
                        <NavLink
                          to="/Security_Settings"
                          className="dropdown-item"
                          onClick={() => {
                            $(".offcanvas").removeClass("show");
                          }}
                        >
                          Security Settings
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/Referral_Bonus"
                          className="dropdown-item"
                          onClick={() => {
                            $(".offcanvas").removeClass("show");
                          }}
                        >
                          Referral Bonus
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/change_password"
                          className="dropdown-item"
                          onClick={() => {
                            $(".offcanvas").removeClass("show");
                          }}
                        >
                          Change Password
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      to="/fund"
                      className="nav-link"
                      onClick={() => {
                        $(".offcanvas").removeClass("show");
                      }}
                    >
                      Wallet
                    </NavLink>
                  </li>
                </>
              ) :null}
            </ul>
            <div className="">
              <ul className="navbar-nav ms-auto mb-lg-0 align-items-center">
                {isLoggedIn ? (
                  <>
                    <li className="nav-item">
                      <NavLink
                        to="/"
                        className="btn btn-danger btn-sm text-white me-2 rounded-pill"
                        onClick={(e) => {
                          e.preventDefault();
                          localStorage.removeItem("exchange_inrx_userID");
                          dispatch(logout());
                          const log_out =
                            document.getElementsByClassName("btn-close")[0];
                          log_out.click();
                        }}
                      >
                        <i class="fa fa-sign-out me-1" aria-hidden="true"></i>
                        Logout
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="nav-item">
                      <NavLink
                        to="/register"
                        className="nav-link"
                        onClick={() => {
                          $(".offcanvas").removeClass("show");
                        }}
                      >
                        Register
                      </NavLink>
                    </li>
                    <li className="nav-item btn btn-warning btn-sm rounded-pill">
                      <NavLink
                        to="/login"
                        className="p-2 text-dark"
                        onClick={() => {
                          $(".offcanvas").removeClass("show");
                        }}
                      >
                        Sign In
                      </NavLink>
                    </li>
                  </>
                )}

                <ul>
                  <li>
                    <div className="ms-2 pointer">
                      <input
                        type="checkbox"
                        className="checkbox"
                        id="checkbox"
                        checked={`${switch_theme == "light" ? "checked" : ""}`}
                        onClick={(e) => {
                          toogleTheme(e);
                        }}
                      />
                      <label
                        htmlFor="checkbox"
                        className="labeltog"
                        style={{
                          background:
                            switch_theme == "dark" ? "bg-dark" : "bg-light"
                        }}
                      >
                        <i className="fas fa-moon fs-6"></i>
                        <i className="fas fa-sun fs-6"></i>
                        <div className="ball"></div>
                      </label>
                    </div>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <div
        class="offcanvas offcanvas-start d-xs-block d-sm-block d-lg-none d-xl-none"
        // data-bs-scroll="true"
        tabindex="-1"
        id="offcanvasRight"
        data-bs-backdrop="false"
        aria-labelledby="offcanvasRightLabel"
        style={{ width: "100%" }}
      >
        <div class="offcanvas-header">
          <h1 class="offcanvas-title">
            <a class="navbar-brand" href="/">
              <img
                src="/images/Aconomic_1.png"
                class="drop-shadow"
                alt="logo"
                height="30"
              />
            </a>
          </h1>
          <button
            type="button"
            class="bg-light btn-close"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>

        <div class="offcanvas-body">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item">
                  <NavLink
                    to="/exchange/btc-inr"
                    className="nav-link fs-16"
                    onClick={() => {
                      const Exchange =
                        document.getElementsByClassName("btn-close")[0];
                      Exchange.click();
                    }}
                  >
                    Exchange
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/p2pOrder"
                    className="nav-link fs-16"
                    onClick={() => {
                      const Exchange =
                        document.getElementsByClassName("btn-close")[0];
                      Exchange.click();
                    }}
                  >
                   p2p
                  </NavLink>
                </li>
            {isLoggedIn ? (
              <>
                <li className="nav-item">
                  <NavLink
                    to="/fund"
                    className="nav-link fs-16"
                    onClick={() => {
                      const Wallet =
                        document.getElementsByClassName("btn-close")[0];
                      Wallet.click();
                    }}
                  >
                    Wallet
                  </NavLink>
                </li>

                <li className="nav-item">
                  <NavLink
                    to="/Security_Settings"
                    className="nav-link fs-16"
                    onClick={() => {
                      const Security =
                        document.getElementsByClassName("btn-close")[0];
                      Security.click();
                    }}
                  >
                    Security Settings
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/Referral_Bonus"
                    className="nav-link fs-16"
                    onClick={() => {
                      const Referral =
                        document.getElementsByClassName("btn-close")[0];
                      Referral.click();
                    }}
                  >
                    Referral Bonus
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/change_password"
                    className="nav-link fs-16"
                    onClick={() => {
                      const changePassword =
                        document.getElementsByClassName("btn-close")[0];
                      changePassword.click();
                    }}
                  >
                    Change Password
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/"
                    className="btn btn-danger mb-2"
                    onClick={(e) => {
                      e.preventDefault();
                      localStorage.removeItem("exchange_inrx_userID");
                      dispatch(logout());
                      const log_out =
                        document.getElementsByClassName("btn-close")[0];
                      log_out.click();
                    }}
                  >
                    Log Out
                  </NavLink>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <NavLink
                    to="/register"
                    className="nav-link fs-16"
                    onClick={() => {
                      const Register =
                        document.getElementsByClassName("btn-close")[0];
                      Register.click();
                    }}
                  >
                    {" "}
                    Register
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/login"
                    className="nav-link fs-16"
                    onClick={() => {
                      const login =
                        document.getElementsByClassName("btn-close")[0];
                      login.click();
                    }}
                  >
                    Login
                  </NavLink>
                </li>
              </>
            )}

            <ul>
              <li style={{ listStyleType: "none" }}>
                <div className="">
                  <input
                    type="checkbox"
                    className="checkbox"
                    id="checkbox"
                    checked={`${switch_theme == "light" ? "checked" : ""}`}
                    onClick={(e) => {
                      toogleTheme(e);
                    }}
                  />
                  <label
                    htmlFor="checkbox"
                    className="labeltog"
                    style={{
                      background:
                        switch_theme == "dark" ? "bg-dark" : "rgb(167 173 175)"
                    }}
                  >
                    <i className="fas fa-moon fs-6"></i>
                    <i className="fas fa-sun fs-6"></i>
                    <div
                      className="ball"
                      style={{
                        backgroungColor:
                          switch_theme == "dark"
                            ? "#1b3f48 !important"
                            : "#ffffff !important"
                      }}
                    ></div>
                  </label>
                </div>
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </>
  );
}
