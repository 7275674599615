export const lightTheme = {
    appbgdark: "#fff ",
    navcolor:"#23aa82", 
    blueblack:"#fff",
    tablecolor:"#424347",
    texttrans:"uppercase",
    balance:"#23aa82",
    darkblue:"#fff",
    colorm:"#23aa82",
    borderm:"1px solid #23aa82",
    darkgrey:" #18614b",
    headfont:"14px",
    headc:"#23aa82",
    headbg:"#fff",
    navtab:"#fff",
    activetabcolor:"#fff",
    navatabbga:"#1c4854",
    navlinkbg:"#fff",
    navlinkcol:"#23aa82",
    footerbg:"#fff",
    footercol:"#fff",
    tblcol:"#279ee7",
    border1:" 1px solid #dae1e0",
    tblbor:" 2px solid #23aa82",
    orderarecolor:"#2c8f54",
    accountbackground:"#fff",
    cardchildbg:"#edf0f1",
    cardtextcolor:"#000",
    cardheadcol:"#084298",
    cardheadbg:"#f5f9ff",
    cardshadow:" 3px 3px 3px #abadb1",
    childtext:"#000",
    tablebackground:"#fff",
    memub:"#f5f9ff",
    divider:"blue",
    Transaction:"",
    ftcolor:"#000",
    bgcolor:"#fff"
}
export const darkTheme = {
    appbgdark: "#12161c",
    blueblack:"#000",
    balance:"#fff",
    darkblue:"#070915",
    colorm:"#9aa1b9",
    borderm:"1px solid #2b2e3a",
    darkgrey:"#212833 ",
    headfont:"14px",
    headc:"#9da3bd",
    headbg:"#fff",
    navtab:"#000",
    activetabcolor:"#c5cce9 ",
    navatabbga:" #1c4854",
    cardshadow:" 3px 3px 3px #abadb1",
    navlinkbg:"#000",
    navlinkcol:"#c5cce9",
    footerbg:"#000",
    footercol:"#000",
    tblcol:"#c5cce9",
    border1:" 1px solid #282d40",
    tblbor:" 2px solid currentColor",
    orderarecolor:" #ced5f5",
    accountbackground:"#12161c",
    cardchildbg:"#435154;",
    cardtextcolor:"#8ca351",
    cardheadcol:"#f5f9ff",
    cardheadbg:"#084298",
    // cardhover:"linear-gradient(to right bottom,rgb(3,6,13),#406cd6)",
    childtext:"#fff",
    tablebackground:"linear-gradient(to right bottom, rgb(3, 6, 13), rgb(20 35 71))",
    tblcolor:"#fff",
    depositbg:"#0c0c29",
    padding:"10px",
    dcol:"#fff",
    memub:"#fff",
    divider:"-webkit-linear-gradient(top, rgb(49, 64, 41), rgb(10, 54, 66))",
    Transaction:"white",
    ftcolor:"#fff",
    bgcolor:"#000"
}