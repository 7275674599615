import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  N_checkKYCStatus,
  N_getCity,
  N_getCountry,
  N_getState,
  N_userInfoSubmit
} from "../../utils/api_functions";
const KycManualform = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state?.user?.value);
  const userid = userInfo.user_id;
  const [loading, setLoading] = useState();
  const [countryData, setCountry] = useState();
  const [stateData, setState] = useState();
  const [cityData, setCity] = useState();
  const [type_of_kyc, setTypeofKyc] = useState("Individual");
  const [fname, setFname] = useState();
  const [mname, setMname] = useState();
  const [lname, setLname] = useState();
  const [dob, setDob] = useState();
  const [address, setAddress] = useState();
  const [pincode, setPincode] = useState();
  const [visibl, setVisiblity] = useState("h");
  useEffect(() => {
    N_checkKYCStatus(userid).then((res) => {
      let d = res.data;
      if (d.status === 200) {
        if (d.params.kyc_status === 1 || d.params.kyc_status === -1)
          navigate("../exchange/btc-inr", { replace: true });
      }
    });
  }, []);
  function changeCountry(event) {
    N_getState(event.target.value, userid)
      .then((dt) => {
        let res = dt.data;
        if (res.status === 200) {
          // console.log("resofcountry: ",res);
          // setCity();
          setState(res.params.country_data);
          changeState("country");
        }
      })
      .catch((e) => {
        console.log("con erer:", e);
      });
  }

  function changeState(event) {
    if (event == "country") {
      N_getCity(
        document.getElementById("country").value,
        document.getElementById("state").value,
        userid
      )
        .then((dt) => {
          let res = dt.data;
          if (res.status === 200) {
            // console.log("resofcountry: ",res);
            setCity(res.params.country_data);
          }
        })
        .catch((e) => {
          console.log("con erer:", e);
        });
    } else {
      N_getCity(
        document.getElementById("country").value,
        event.target.value,
        userid
      )
        .then((dt) => {
          let res = dt.data;
          if (res.status === 200) {
            // console.log("resofcountry: ",res);
            setCity(res.params.country_data);
          }
        })
        .catch((e) => {
          console.log("con erer:", e);
        });
    }
  }

  useEffect(() => {
    N_getCountry(userid)
      .then((dt) => {
        let res = dt.data;
        if (res.status === 200) {
          // console.log("resofcountry: ",res);
          setCountry(res.params.country_data);
        }
      })
      .catch((e) => {
        console.log("con erer:", e);
      });

    N_getState("India", userid)
      .then((dt) => {
        let res = dt.data;
        if (res.status === 200) {
          // console.log("resofstate: ",res);
          setState(res.params.country_data);
        }
      })
      .catch((e) => {
        console.log("erer:", e);
      });
    N_getCity("India", "Andaman and Nicobar Islands", userid)
      .then((dt) => {
        let res = dt.data;
        if (res.status === 200) {
          // console.log("resofcity: ",res);
          setCity(res.params.country_data);
        }
      })
      .catch((e) => {
        console.log("erer:", e);
      });
  }, []);

  const submit = (e) => {
    e.preventDefault();
    const body = {
      type_of_kyc: type_of_kyc,
      fname: fname ? fname : "",
      mname: mname ? mname : "",
      lname: lname ? lname : "",
      dob: dob ? dob : "",
      address: address,
      country: document.getElementById("country").value,
      state: document.getElementById("state").value,
      city: document.getElementById("city").value,
      pincode: pincode ? pincode : "",
      user_id: userid
    };
    console.log(body);
    setLoading(true);
    N_userInfoSubmit(body)
      .then((dt) => {
        let res = dt.data;
        if (res.status === 200) {
          navigate("../kyc-final", { replace: true });
        }
      })
      .catch((e) => {
        console.log("erer:", e);
      });
  };

  return (
    <>
      {/* <Kychead/> */}
      <div class="page_title">
        <div class="container">
          <div class="page_title_inner">
            <div class="row">
              <div class="col-lg-12 col-12">
                {" "}
                <h1>Identify Document</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-3">
        <div className="row">
          <div className="col-lg-6">
            <img src="/images/kyc.png" className="img-fluid" alt=""/>
          </div>
          <div className="col-lg-6 m-auto">
            <div className="card">
              <div className="card-body">
                <form
                  className="signupform"
                  onSubmit={submit}
                  method="post"
                  id="welcome_form"
                >
                  <div className="mb-3 mt-3">
                    <label class="text-muted"  className="text-muted">TYPE OF KYC</label>
                    <select
                      id="type_of_kyc"
                      name="type_of_kyc"
                      className="form-control selectclas"
                      data-role="select-dropdown"
                      onChange={(e) => {
                        setTypeofKyc(e.target.value);
                      }}
                    >
                      <option value="Individual">Individual</option>
                      <option value="Corporate">Corporate</option>
                    </select>
                  </div>

                  <div id="personal_info" className="vis">
                    <div className="row">
                      <div className="col-lg-4 mb-3">
                        <div className="">
                          <label class="text-muted"  htmlFor="user_fname">FIRST NAME <span class="text-danger" >*</span></label>
                          <input
                            type="text"
                            name="user_fname"
                            required="required"
                            onChange={(e) => {
                              setFname(e.target.value);
                            }}
                            className="form-control buy-sell-form-bg buy-sell-theme"
                            id="user_fname"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <div className="">
                          <label class="text-muted"  htmlFor="user_mname">MIDDLE NAME</label>
                          <input
                            type="text"
                            name="user_mname"
                            onChange={(e) => {
                              setMname(e.target.value);
                            }}
                            className="form-control buy-sell-form-bg buy-sell-theme"
                            id="user_mname"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <div className="">
                          <label class="text-muted"  htmlFor="user_lname">LAST NAME</label>
                          <input
                            type="text"
                            name="user_lname"
                            onChange={(e) => {
                              setLname(e.target.value);
                            }}
                            className="form-control buy-sell-form-bg buy-sell-theme"
                            id="user_lname"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label class="text-muted"  htmlFor="user_dob">DOB <span class="text-danger" >*</span></label>
                      <input
                        type="date"
                        name="user_dob"
                        id="user_dob"
                        required="required"
                        onChange={(e) => {
                          setDob(e.target.value);
                        }}
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        placeholder="DD-MM-YYYY"
                      />
                    </div>
                    <div className="mb-3">
                      <label class="text-muted"  htmlFor="user_address">ADDRESS <span class="text-danger" >*</span></label>
                      <textarea
                        className="form-control buy-sell-form-bg buy-sell-theme"                        
                        id="user_address"
                        required="required"
                        rows="2"
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <label class="text-muted"  htmlFor="country">COUNTRY <span class="text-danger">*</span></label>
                      <select
                        id="country"
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        onChange={(e) => {
                          changeCountry(e);
                        }}                        
                        data-role="select-dropdown"
                      >
                        {countryData
                          ? countryData.map((country) => (
                              <option
                                value={country}
                                selected={country == "India" ? "selected" : ""}
                              >
                                {country}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 mb-3">
                        <div className="">
                          <label class="text-muted"  htmlFor="state">STATE <span class="text-danger" >*</span></label>
                          <select
                            id="state"
                            className="form-control buy-sell-form-bg buy-sell-theme"
                            onChange={(e) => {
                              changeState(e);
                            }}                           
                            data-role="select-dropdown"
                          >
                            {stateData
                              ? stateData.map((state) => (
                                  <option value={state}>{state}</option>
                                ))
                              : ""}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-4 mb-3">
                        {" "}
                        <div className="">
                          <label class="text-muted"  htmlFor="country">CITY <span class="text-danger" >*</span></label>
                          <select
                            id="city"
                            className="form-control buy-sell-form-bg buy-sell-theme"
                           
                            data-role="select-dropdown"
                          >
                            {cityData
                              ? cityData?.map((city) => (
                                  <option value={city}>{city}</option>
                                ))
                              : ""}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <div className="">
                          <label class="text-muted"  htmlFor="user_pincode">PINCODE <span class="text-danger" >*</span></label>
                          <input
                            type="text"
                            name="user_pincode"
                            required="required"
                            className="form-control buy-sell-form-bg buy-sell-theme"
                            id="user_pincode"
                            onChange={(e) => {
                              setPincode(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-grid">
                      <button
                        type="submit"
                        id="btn_submit"
                        className="btn btn-primary btn-lg"
                      >
                        {loading ? (
                          <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                        ) : null}
                        <span id="reg">SUBMIT</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KycManualform;
