import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../features/User";
import { api } from "../../utils/api";
const EmailLogin = () => {
  const [eye, seteye] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const password = useRef({});
  password.current = watch("password", "");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [authenticator, setAuthenticator] = useState(0);
  const loginUser = async (data) => {
    if (authenticator == 2 || authenticator == 1) {
      setIsAuthenticating(true);
      api
        .post("/login", data)
        .then((res) => {
          if (res.status === 200) {
            dispatch(login({ isLoggedIn: true, userInfo: res.data.params }));
            localStorage.setItem(
              "exchange_inrx_userID",
              res.data.params.user_id
            );
            navigate("../exchange/btc-inr", { replace: true });
          } else {
            setIsAuthenticating(false);
            toast.error(res.data.params.message);
          }
        })
        .catch((error) => {
          setIsAuthenticating(false);
          console.log(error?.response?.data?.message ?? error.message);
          toast.error(error?.response?.data?.message ?? error.message);
        });
    } else {
      setIsAuthenticating(true);
      api.post("/get-auth-google-new", data).then((res) => {
        let d = res.data;
        console.log(d , res,"res.status vipin ");
        if (d.status == 200) {
        
          if (d.authenticator_status == 0) {
            api
              .post("/login", data)
              .then((res) => {
                if (res.status === 200) {
                  toast.success(d.message)
                  dispatch(
                    login({ isLoggedIn: true, userInfo: res.data.params })
                  );
                  localStorage.setItem(
                    "exchange_inrx_userID",
                    res.data.params.user_id
                  );
                  navigate("../exchange/btc-inr", { replace: true });
                } else{
                  setIsAuthenticating(false);
                  toast.error(res.data.params.message);
                }
              })
              .catch((error) => {
                setIsAuthenticating(false);
                console.log(error?.response?.data?.message ?? error.message);
                toast.error(error?.response?.data?.message ?? error.message);
              });
          } else {
            setAuthenticator(d.authenticator_status);
            setIsAuthenticating(false);
          }
        } else {
          toast.error(d.message);
          setIsAuthenticating(false);
        }
      }).catch((error) => {
        setIsAuthenticating(false);
        console.log(error?.response?.data?.message ?? error.message);
        toast.error(error?.response?.data?.message ?? error.message);
      });
    }
  };
  const hsPassword = (_id) => {
    var _idv = document.querySelector("#" + _id);
    if (_idv.getAttribute("type") == "text") {
      _idv.setAttribute("type", "password");
      seteye(false)
      
    } else {
      _idv.setAttribute("type", "text");
        seteye(true);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(loginUser)} autocomplete="off">
        <input
          type="email"
          className="login_input-- form-control mb-3"
          placeholder="Email"
          autocomplete="off"
          readOnly
          onFocus={(e)=>{e.target.removeAttribute("readOnly")}}
          name="email"
          {...register("email", {
            required: "Email  is required.",
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
        />
        {errors?.email && (
          <div classNameName="app-text-danger">{errors?.email?.message}</div>
        )}
        {errors?.email?.type === "pattern" && (
          <div classNameName="app-text-danger">Enter valid email address.</div>
        )}
        {authenticator == 2 || authenticator == 1 ? (
          <input
            type="number"
            className="login_input-- form-control mb-3"
            placeholder={authenticator == 2?'Enter Google Authenticator Code':'Enter Mobile Number OTP'}
            name="otp"
            {...register("otp", {
              required: "You must specify a OTP",
              minLength: {
                value: 6,
                message: "OTP must have at least 6 characters",
              },
            })}
          />
        ) : null}
         <div className={`form-group`}>
          <div className="input-group">
        <input
          type="password"
          className="login_input form-control"
          placeholder="Password"
          id="password"
          name="password"
          {...register("password", {
            required: "You must specify a password",
            minLength: {
              value: 8,
              message: "Password must have at least 8 characters",
            },
          })}
        />
        <span className="input-group-text">
            <a
              href="#view_qr"
              className=""
              onClick={(e) => {
                hsPassword("password");
              }}
            >
              {eye ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#666"
                  className="bi bi-eye-slash-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                  <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#666"
                  className="bi bi-eye-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                </svg>
              )}
            </a>
        </span>
        </div>
      </div>
        {errors?.password && (
          <div classNameName="app-text-danger">{errors?.password?.message}</div>
        )}

        <div className="d-flex justify-content-between">
          <div className="form-group form-check terms_and_policy--">
            <input
              type="checkbox"
              className="form-check-input"
              id="exampleCheck1"
            />
            <label className="form-check-label" for="exampleCheck1">
            <span> Remember the username </span>{" "}
            </label>
          </div>
          
          <div>        
              <Link to="/forgot_password"  className="text-info">Forgot Password?</Link>
              <br />
          </div>
       </div>

      <div className="d-grid">
        <button type="submit" className="btn btn-warning btn-lg">
          {isAuthenticating ? (
            <>
              <span
                className="spinner-grow spinner-grow-sm me-1"
                role="status"
                aria-hidden="true"
              ></span>
              Authenticating...
            </>
          ) : (
            <>Sign In</>
          )}
        </button>
      </div>
      </form>
    </div>
  );
};

export default EmailLogin;
