import { useEffect} from "react";
import { useTranslation } from "react-i18next";
import "react-notifications/lib/notifications.css";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import EmailLogin from "./auth/EmailLogin";
import MobileLogin from "./auth/MobileLogin";

export default function Login(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLoggedIn } = useSelector((state) => state?.user?.value);

  useEffect(() => {
    if (isLoggedIn) {
      console.log("cmae in loguse::");
      navigate("../exchange/btc-inr", { replace: true });
    }
  }, []);
  
  return (
    <div className="container">
      <div className="login_area page_minheight">
        <div className="row">
          <div className="col-lg-5 m-auto">
            <div className="mb-3 m-auto col-md-6 col-12">
            <ul className="nav nav-pills mb-3" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    style={{height:"100%"}}
                  >
                     <i className="fa fa-envelope me-2"></i>Via Email 
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                     <i className="fa fa-phone me-2"></i> Via Phone
                  </button>
                </li>
              </ul>

            </div>
        
           
            <div className="login_area_block-- card">
          
              <div className="tab-content login_form" id="myTabContent">

              <h1 className="font-dongle fs-1 my-0 py-0 mb-3">{t("Login")}</h1>
              
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                 
                  <EmailLogin />
                  
                  <label for="exampleCheck1" className="mt-2 regi_acc" >
                    <span >
                      Do you have an account{" "}
                      <Link to="/register" className="text-success">Register ?</Link>
                    </span>
                    <br />
                  </label>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                
                  <MobileLogin />
                 
                  <label for="exampleCheck1" className="mt-2 regi_acc">
                    <span>
                      Do you have an account{" "}
                      <Link to="/register" className="text-success">Register?</Link>
                    </span>
                    <br />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
