import { createRef, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { api } from "../utils/api";
import { useSelector } from "react-redux";

export default function ChangePassword(props) {
  const { userInfo } = useSelector((state) => state?.user?.value);
  const accessToken = userInfo.user_id;
  const [isProgress, setIsProgress] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();
  const password = createRef(); //useRef({});
  password.current = watch("password", "");

  const changePassword = (data) => {
    console.log(data);
    setIsProgress(true);
    api
      .post("/set-password", data)
      .then((res) => {
        toast.success(res?.data?.message);
        console.log(res.data);
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message ??
            error?.message ??
            "Something went wrong."
        );
      })
      .then(() => {
        setIsProgress(false);
      });
  };

  return (
    <>
      <div class="page_title">
        <div class="container">
          <div class="page_title_inner">
            <div class="row">
              <div class="col-lg-9 col-12">
                {" "}
                <h1> Change Password</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container page_minheight mt-5">
        <div className="row">
          <div className="col-lg-6 m-auto">
            <div className="card card-body">
              <h1 className="font-dongle fs-1 my-0 py-0">
                Change your password.
              </h1>
              <form onSubmit={handleSubmit(changePassword)}>
                <div className="form-floating mb-2">
                  <input
                    type="hidden"
                    name="access_token"
                    value={accessToken}
                    {...register("access_token")}
                  />
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="New Password"
                    {...register("password", {
                      required: "You must specify a password",
                      minLength: {
                        value: 8,
                        message: "Password must have at least 8 characters"
                      }
                    })}
                  />
                  <label for="password">New Password</label>
                </div>
                {errors?.password && (
                  <div classNameName="app-text-danger">
                    {errors?.password?.message}
                  </div>
                )}
                <div className="form-floating mb-2">
                  <input
                    type="password"
                    className="form-control"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    {...register("confirm_password", {
                      required: "Confirm password",
                      validate: (value) =>
                        value === password.current ||
                        "The passwords do not match"
                    })}
                  />
                  <label for="confirmPassword">Confirm Password</label>
                </div>
                {errors?.confirm_password && (
                  <div classNameName="app-text-danger">
                    {errors?.confirm_password?.message}
                  </div>
                )}
                <div className="d-grid gap-2">
                  <button className="btn btn-lg btn-warning mb-2" type="submit">
                    {isProgress ? (
                      <>
                        <span
                          className="spinner-grow spinner-grow-sm me-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Updating Password....
                      </>
                    ) : (
                      "Change Password"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
