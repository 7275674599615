import React, { useEffect,useState } from 'react'
import { N_getSupportedCurrency } from '../../utils/api_functions'
import Switch from "react-switch";
const Fees = () => {
    const [get_cstcoin, setcstCoin] =useState(0);
  const [set_enable, setEnable] = useState(0);
    useEffect(()=>{
N_getSupportedCurrency().then((res)=>{
    //console.log(res.data,"im fesss");
    res.data.map((item)=>{
        if (item.paired_with === "is_paired_vrx") {
            setcstCoin(item);
            console.log(get_cstcoin,"get_cstcoin");
          }
    })
})
    },[])
  return (
    <div
      class="modal fade"
      id="Fees"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="FeesLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="FeesdropLabel">
            Fees
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div
              className="col-12"
              
            >
              <div className="">
             
                <article>
                  <div className="col-md-10">
                    <div className="">
                      <div className="">
                      <h6 className="">Pay trading fees with ELUX </h6> 
                      <h6><Switch
                      onChange={(e) => setEnable(set_enable ? 0 : 1)}
                      checked={set_enable}
                    /></h6>
                      </div>
                      <div className="text-secondary mb-2 d-flex">
                     <p class="fs-12 text-muted"> Enable this option to pay trading fees with:</p>
                      </div>
                      <ul className="mb-2 fs-14">
                        <li className="mb-2">
                          <i
                            className="fa fa-circle mr-2"
                            aria-hidden="true"
                          ></i>{" "}
                          ELUX you buy from the exchange
                        </li>
                        <li className="mb-2">
                          <i
                            className="fa fa-circle mr-2"
                            aria-hidden="true"
                          ></i>{" "}
                         Unlocked ELUX balance reserved for trading fees
                        </li>
                       
                      </ul>
                      <div className="text-secondary ">
                        
                      Note: You'll get 50% discount if you pay fees via ELUX
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  )
}

export default Fees