import React from "react";
import { useState } from "react";

const Blog = () => {
  const [f1_block, setf1Block] = useState(true);
  const [f2_block, setf2Block] = useState(false);

  return (
    <>
      <div style={{ paddingTop: "120px" }}>
        {/* <section className="" id="blog">
      <div class="page-head section row-vm light">
				<div class="container">
					<div class="row text-center">
						<div class="col-md-12">
							<h2>Blog Single</h2>
						</div>
					</div>
				</div>
			</div>
      </section> */}
        <div class="section section-pad">
          <div class="container">
            <div class="row">
              <div class="col-md-8 res-m-bttm">
                {f1_block ? (
                  <div class="post post-single">
                    <div class="post-thumb">
                      {/* <img alt="" src="images/blog1.jpg" /> */}
                    </div>
                    <div class="post-entry mt-2">
                      <h3>How is an Elucks Trading Exchange Different from Other Trading Exchange?</h3>
					  <p>
                  An Elucks Trading Exchange is a platform for peer-to-peer trading of digital assets such as cryptocurrencies. The <a href="https://elucks.exchange">Elucks trading exchange </a>is different because it provides a platform where both buyers and sellers can trade cryptocurrency with each other. Unlike other platforms that are only used by one party which has to negotiate with the other through an agent, the Elucks Trading Exchange makes these two parties transact with each other more directly. This direct method of buying or selling cryptocurrency is a big change from other platforms because it eliminates the middleman.
                      </p>
                      <h4>Benefits of the Elucks trading Exchange for Traders - Why use the platform?</h4>
                      <p>
					  The Elucks trading Exchange is a new way for traders to share information effectively. The exchange has several features that make it more useful than other platforms, such as the ability to create different types of orders and being able to import data from other exchanges. <br /><br />
                Elucks provides a platform for traders to share and receive trade ideas through their private forums. Traders can also have to access the educational resources and webinars hosted by Elucks. Elucks offers significant advantages over other trading platforms because of its unique features, such as:<br />
                -	Ability to create market and limit orders.<br />
                -	Ability to import data from other exchanges.<br />
                -	Information on the best trades created by investors.<br />
                -	Information on the best trades created by AI.
                      </p>
                      <h4>
					  How Does Elucks Trading Exchange Change the Face of Trading?
                      </h4>
					  <p>
						Elucks trading exchange is a new trading platform that allows traders to buy and sell cryptocurrencies without any intermediaries. Unlike Elucks exchanges, it doesn’t require you to hand over your personal information and funds.<br /><br />
						This new trading platform offers a cheaper price for transactions and more liquidity. It also allows traders to trade cryptocurrencies without having to go through the whole process of signing up for an account on any exchange.<br /><br />
						The Elucks Trading Exchange is a powerful business platform that is changing the face of trading. The Elucks Trading Exchange is a revolutionary trading platform that allows investors all around the world to trade stocks and other assets from their browsers. The platform is free from corruption and manipulation because it's built on blockchain technology, giving this platform a clear advantage over traditional trading markets that are often riddled with fraud.
						</p>
					  <h4>
					  Start Using The Elucks Trading Exchange Today To Become Financially Free!
                      </h4>
					  <p>
                  The Elucks Trading Exchange is an online platform that has been designed to help users to start trading and investing in a safe, efficient and profitable way. <br /><br />
                    The Elucks Trading Exchange aims to create the best platform for the exchange of financial instruments that are controlled by central authorities or banks.<br /><br />
                    Users who are new to trading can use the platform’s automatic trading software which will generate a portfolio of five different investments at random. It includes stocks, forex, cryptocurrencies, commodities, and bonds. There is also an option for users to copy other traders who have been successful with their investments. The trading software also provides a ‘simulator’ that allows users to see how successful their investments will be. The simulator is also available for experienced traders and investors who want to simulate different scenarios. The <a href="https://elucks.exchange">trading software</a> is compatible with all desktop and mobile devices. To get started, users need to create an account and start trading.
                    </p>
                    </div>
                  </div>
                ) : null}
                {f2_block ? (
                     <div class="post post-single">
					 <div class="post-thumb">
					   {/* <img alt="" src="images/blog2.jpg" /> */}
					 </div>
					 <div class="post-entry mt-2">
					   <h3>What is Elucks Exchange and What are the Benefits of Implementing it?</h3>
					   <p>
                  Elucks exchange is a digital marketplace. It is an online platform that provides the possibility to buy and sell goods or services either directly or through the use of a third party. Elucks Exchange suggests that this system would provide consumers with the opportunity to purchase goods and services using their own cryptocurrency, rather than fiat currency. Elucks exchange is the best solution to our current economic and financial distress.<br /> <br />
                    The Elucks Exchange creates a new digital commons that allows for any individual to offer their goods and services in economic exchange with other people across the globe in an open-source environment. Elucks Exchange is a new type of platform that has been designed to unify many different crypto-based exchanges in one single platform for exchanging various crypto assets as well as fiat currencies. <br /><br />
                    The benefits of implementing Elucks Exchange include:<br />
                    -The ability for consumers to spend their cryptocurrency on goods and services within a trusted ecosystem<br />
                    -More opportunities for users of cryptocurrencies to spend them<br />
                    -A reduction in fees paid on transactions through this system<br />
                    -Eliminating fraud from digital exchanges<br />
                    The Elucks Exchange helps weed out fraudulent transactions and reduces the number of scammers who want to take advantage of other people’s hard-earned money.
                    </p>
					   <h4>How Elucks Systems Works and Why they are Better than Traditional Aconomies</h4>
					   <p>
					   Elucks differs in many ways from one. Firstly, they function through elucks exchange. All good transactions are dictated by this central authority and they must comply with its laws and regulations to be regarded as legitimate transactions.  Elucks allows for better regulation of the currency markets by implementing stricter laws around their functioning and providing oversight on how the money flows. <br /><br />
                    Elucks has no central center of control and is more susceptible to exploitation by other parties with more power than the rest of the system. This means that eluckss are a little easier to pull off scams or frauds with and will likely lack strict oversight over how they work. <br /><br />
                    Traditional elucks are based on an exchange system which means that each member within the economy needs to exchange goods with other members, which creates inefficiency and creates a gap between demand and supply.
                    </p>
					   <h4>
					   The Advantages of Trading with Elucks Exchange Platform
					   </h4>
					   <p>
                  Elucks Exchanges are systems where everything is controlled and coordinated by a central entity. We see this system in our day-to-day lives with governments, corporations, and large institutions controlling the resources and operations of a group or community. <br /><br />
                    The traditional way of trading digital currencies such as bitcoin and ether has been through Elucks exchanges that have proven to be vulnerable to hacking. Elucks exchanges offer a solution to these problems and can provide an alternative way for traders to connect with each other and trade their assets with relative security. <br /><br />
                    Elucks Exchange uses blockchain technology to process transactions and the use of smart contracts for security. There is also no chance of fraud because you have a record of your transaction on the blockchain.
                    </p>
					 </div>
				   </div>
                ) : null}
              </div>
              <div class="col-md-3 col-md-offset-1">
                <div class="sidebar-right wgs-box">
                  {/* <div class="wgs-search">
								<div class="wgs-content">
									<div class="form-group">
										<input type="text" class="form-control"  placeholder="Search..." />
										<button class="search-btn"><i class="fa fa-search" aria-hidden="true"></i></button>
									</div>
								</div>
								<div class="gaps size-1x"></div>
							</div> */}
                  <div class="wgs-post">
                    <h5 class="wgs-title">Recent Blog</h5>
                    <div class="wgs-content">
                      <div class="wgs-post-single mb-4">
                        <div class="wgs-post-thumb">
                          {/* <img src="images/blog1.jpg" alt="post-thumb" /> */}
                        </div>
                        <div class="wgs-post-entry">
                          <h6 class="wgs-post-title">
                            <a
							style={{cursor:"pointer"}}
                              onClick={() => {
                                if (!f1_block) {
                                  setf1Block(!f1_block);
                                  setf2Block(!f2_block);
                                }
                              }}
                            >
                              Elucks Trading Exchange – Will this Become the
                              Next Big Thing in Trading?
                            </a>
                          </h6>
                        </div>
                      </div>
                      <div class="wgs-post-single">
                        <div class="wgs-post-thumb">
                          {/* <img src="images/blog2.jpg" alt="post-thumb" /> */}
                        </div>
                        <div class="wgs-post-entry">
                          <h6 class="wgs-post-title">
                            <a
							style={{cursor:"pointer"}}
                              onClick={() => {
                                if (!f2_block) {
                                  setf1Block(!f1_block);
                                  setf2Block(!f2_block);
                                }
                              }}
                            >
                              Elucks Trading Exchange & How It Helps to Trade
                              Cryptocurrency Safely
                            </a>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
