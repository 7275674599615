import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import numeral from "numeral";
import { round, mul } from "../utils/Math";
import {
  set_Buy_Order_Inr_Price,
  set_Buy_Order_Volume,
  set_Sell_Order_Volume,
  set_Sell_Order_Inr_Price
} from "../../src/features/Exchange";
function MarketDepth() {
  const dispatch = useDispatch();
  const { sellOrder, buyOrder, active_coin, active_paired_currency } = useSelector(
    (state) => state?.exchange?.value
  );
  
  const [sellorderdata, Setsellorderdata] = useState([]);
  const [Buy_order_data, setBuy_order_data] = useState([]);
  const [inr_price, setInr_Price] = useState("");

  const emptydata = ["--", "--", "--", 2, 4];
  const { coin } = useParams();
  const p = coin.split("-");
  const p1 = active_coin.toLowerCase();
  const p2 = active_paired_currency.toLowerCase();
  const coina = p1 + p2;
  useEffect(() => {
    const sd = sellOrder
      ? Object.values(sellOrder).length > 0
        ? sellOrder[coina]
        : []
      : [];
    Setsellorderdata(sd);

    const buy_ord = buyOrder
      ? Object.values(buyOrder).length > 0
        ? buyOrder[coina]
        : []
      : [];
    setBuy_order_data(buy_ord);
  }, [coin, sellOrder, buyOrder, active_coin, active_paired_currency]);

  const orderbuy = (pricebuy) => {
    dispatch(set_Buy_Order_Inr_Price({ data: pricebuy.current_price_inr }));
    dispatch(set_Buy_Order_Volume({ data: pricebuy.volume }));
    console.log(pricebuy.current_price_inr, pricebuy.volume, "price buy Atul");
  };

  const ordersell = (pricesell) => {
    dispatch(set_Sell_Order_Inr_Price({ data: pricesell.current_price }));
    dispatch(set_Sell_Order_Volume({ data: pricesell.vol }));
    console.log(pricesell.current_price, pricesell.vol, "price sell vipin ");
  };

  return (
    <>
      <div className="col-lg-6-- blue_back-- no_pad">
        <div class="card mt-1 order_book_tbl_scroll">
          <div className="card-header">Order Book</div>
          <div className="buy_sell_tbl_scroll">
            <table className="table coins_table no_pad">
              <thead>
                <tr>
                  <th scope="col" className="light_blue">
                    <span>Price</span> <span className="text-uppercase">({p2})</span>
                  </th>
                  <th scope="col" className="light_blue">
                    <span>Volume</span> <span className="text-uppercase">({p1})</span>
                  </th>
                  <th scope="col" className="light_blue">
                    <span> Total</span> <span className="text-uppercase">({p2})</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sellorderdata
                  ? sellorderdata.map((item, i) => (
                      <>
                        <tr
                          key={i}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            orderbuy({
                              current_price_inr: item.raw_price,
                              volume: item.volume
                            });
                          }}
                        >
                          <th scope="row" className="red">
                            {item.raw_price}
                          </th>
                          <td>{round(item.volume)}</td>
                          <td>{mul(item.raw_price, item.volume)}</td>
                        </tr>
                        {/* <tr>
                          <th>{inr_price}</th>
                        </tr> */}
                      </>
                    ))
                  : emptydata?.map((item, i) => (
                      <tr key={i}>
                        <th scope="row" className="red">
                          {/* -- */}
                        </th>
                        {/* <td>110</td> */}
                        {/* <td>--</td> */}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
          <div className="mt-1 buy_sell_tbl_scroll">
            <table className="table coins_table no_pad ">
              <thead className="pd_tb_10">
                <tr>
                  <th scope="col" className="light_blue">
                    <span>Price</span>  <span className="text-uppercase">({p2})</span>
                  </th>
                  <th scope="col" className="light_blue">
                    <span>Volume</span>  <span className="text-uppercase">({p1})</span>
                  </th>
                  <th scope="col" className="light_blue">
                    <span> Total</span> <span className="text-uppercase"> ({p2})</span>
                  </th>
                </tr>
              </thead>
              <tbody>
              {Buy_order_data
                ? Buy_order_data.map((item, i) => (
                    <tr key={i}  style={{ cursor: "pointer" }}
                    onClick={() => {ordersell({current_price:item.raw_price,vol:item.volume})}}
                    >
                      <th scope="row" className="lght_green">
                      {item.raw_price}
                      </th>
                      <td>{round(item.volume)}</td>
                      <td>
                        {
                          mul(item.raw_price,item.volume)}
                      </td>
                    </tr>
                  ))
                : emptydata?.map((item, i) => (
                    <tr key={i}>
                      <th scope="row" className="lght_green"></th>
                    </tr>
                  ))}
            </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default MarketDepth;
