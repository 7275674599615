import React from "react";
import { useState } from "react";
import Currency_preference from "./currency_preference";
import Fees from "./Fees";
import Kyc from "./Kyc";
import Payment from "./Payment";
import PrivacyControl from "./PrivacyControl";
import Twofactor from "./Twofactor";

const CardComponent = ({ titte, text, icon, button, Twofa, id }) => {
  const [showdrop, setshodrop] = useState(Twofa);
  return (
  
      <div class="card shadow-sm">
      <div class="card-body py-2">
        <div className="row align-items-center">
          <div className="col-lg-1 col-12 icon_bg">
            <div className="icon_bg"> 
             <i className={icon || ""}></i>
            </div>
          </div>
          <div className="col-lg-9 col-12">         
            <h6 class="card-title">
            {" "}
   
          {titte || ""}
        </h6>
        <p class="card-height fs-13">{text || ""}</p>
          </div>
          <div className="col-lg-2 col-12 text-end">
          <button
          className="btn btn-light"
          type="button"
          data-bs-toggle="modal"
          data-bs-target={id}          
          onClick={() => {
            setshodrop(Twofa);
          }}
        >
          {button || ""}
        </button>
          </div>
        </div>

        {showdrop === 1 ? <Twofactor /> : null}
        {showdrop === 2 ? <PrivacyControl /> : null}
        {showdrop === 3 ? <Kyc /> : null}
        {showdrop === 4 ? <Payment /> : null}
        {showdrop === 5 ? <Currency_preference /> : null}
        {showdrop === 6 ? <Fees /> : null}
     
      </div>
    </div>
  
  );
};

export default CardComponent;
