import { logourl } from "../utils/api";

export default function Footer (props) {
  return (
    <footer className={`footer ftcolr`}>
      <div className="container">
        <div className="footer__main">
          <div className="row">
         
            <div className="col-xl-4 col-md-4">
              <div className="widget-link s1">
                <h6 className="title">Quick Links</h6>
                <ul>
                  <li><a href="/fees">Tranding Fee</a></li>
                  <li><a href="/blog">Blog</a></li>
                  {/* <li><a href="/">Spot</a></li>
                  <li><a href="/">Inverse Perpetual</a></li>
                  <li><a href="/">USDT Perpetual</a></li>
                  <li><a href="/">Exchange</a></li>
                  <li><a href="/">Launchpad</a></li>
                  <li><a href="/">Binance Pay</a></li> */}
                </ul> 
              </div>
            </div>
            <div className="col-xl-4 col-md-4">
              <div className="widget-link s2">
                <h6 className="title">Contact</h6>
                 <ul className="fs-14">
                 <li>+12 345 678 9101</li>
                  <li>support@elucks.com</li>
                  {/* <li><a href="/">Buy Crypto</a></li>
                  <li><a href="/">Markets</a></li>
                  <li><a href="/fees">Tranding Fee</a></li>
                  <li><a href="/">Affiliate Program</a></li>
                  <li><a href="/">Referral Program</a></li>
                  <li><a href="/">API</a></li> */}
                </ul> 
              </div>
            </div>
            {/* <div className="col-xl-4 col-md-4">
              <div className="widget-link s3">
                <h6 className="title">Support</h6>
                 <ul>
                 
                
                  <li><a href="/term-use">Term Condition</a></li>
                  <li><a href="/">Submit a request</a></li>
                  <li><a href="/">API Documentation</a></li>
                  <li><a href="/">Trading Rules</a></li> 
                </ul> 
              </div>
            </div> */}

            <div className="col-xl-4 col-md-8">
              <div className="info">
                <a href="index.html" className="logo">
                  <img src={`${logourl}images/logo.png`} alt="" className="mt-3" width="200"/>
                </a>
               <div className="h6">
                
                 </div>
                 <p className="mt-3 fs-14">Elucks exchange is a business that allows customers to trade cryptocurrencies for other assets.</p>
               
              </div>
            </div>

            {/* <div className="col-xl-2 col-md-4">
              <div className="widget-link s4">
                <h6 className="title">ABOUT US</h6>
                 <ul>
                  <li><a href="/">About Elucks</a></li>
                  <li><a href="/">Authenticity Check</a></li>
                  <li><a href="/">Careers</a></li>
                  <li><a href="/">Business Contacts</a></li>
                  <li><a href="/blog">Blog</a></li>
                </ul> 
              </div>
            </div> */}
          </div>
        </div>
        <div className="row pb-3">
          <div className="container">
          <hr/>
            <div className="d-flex gap-3 justify-content-center">
            <span>© 2023 Elucks</span>            
            <a href="/support" className="text-dark">Support</a>
            <a href="/privacy-policy" className="text-dark">Privacy policy</a>
            <a href="/terms-use" className="text-dark">Terms & Conditions</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
