import React from 'react'

const Support = () => {
  return (
    <section className="section-spacing " style={{paddingTop:'100px'}}>
    <div class="container page_minheight">
        <div class="row justify-content-center">
            <div class="col-lg-6">
                <div class="section-title text-center">
                    <span>get in touch with us</span>
                    <h3 class="title">Contact Elucks Today</h3>
                    <p>Have any question? Write to us and we’ll get back to you shortly.</p>
                </div>
            </div>
            
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <form action="#">
                        <div class="row">
                            <div class="mb-4 col-lg-6 d-flex justify-content-center align-items-center">
                                <div class="form-floating flex-fill">
                                    <input type="text" className='form-control form-control-sm' id='name' placeholder="Name" />
                                    <label for="name">Name</label>
                                </div>
                            </div>
                            <div class="mb-4 col-lg-6 d-flex justify-content-center align-items-center">
                                <div class="form-floating flex-fill">
                                    <input type="text" className='form-control form-control-sm' id='email' placeholder="Name" />
                                    <label for="email">Email</label>
                                </div>
                            </div>
                            <div class="mb-4 col-lg-12 d-flex justify-content-center align-items-center">
                                <div class="form-floating flex-fill">
                                    <input type="text" className='form-control form-control-sm' id="subject" placeholder="Name" />
                                    <label for="subject">Subject</label>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-floating flex-fill">
                                    <textarea className='form-control form-control-sm mb-2' rows="10" id="message" placeholder="Message"></textarea>
                                    <label for="message">Message</label>
                                    <button  type="submit" className='btn btn-warning' style={{float:'right'}}>Send Message</button>
                                </div>
                            </div>
                        </div>
                </form>
            </div>
        </div>
    </div>
</section>
  )
}

export default Support