import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  N_createBuyOffer,
  N_get_wallet,
  N_OrdersHistory,
  N_createSellOffer,
  N_executeOrder,
} from "../utils/api_functions";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  set_user_order_close,
  set_user_order_pending,
} from "../features/Exchange";
import { useDispatch } from "react-redux";
import { round, add, mul, sub, div } from '../utils/Math';
const BuyNSell = () => {
  const { isLoggedIn, userInfo } = useSelector((state) => state?.user?.value);
  const {
    coins,
    active_coin_details,
    active_paired_currency,
    paired_currency,
    active_coin,
    buy_order_inr_price,
    buy_order_volume,
    sell_order_inr_price,
    sell_order_volume,
    paired_currency_price,
  } = useSelector((state) => state?.exchange?.value);
  // console.log("active_coin_details", active_coin_details);

  const [wallet_details, setWalletDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total_inr, setTotalInr] = useState(0);
  const [amount, setAmount] = React.useState(0.0);
  const [samount, setsAmount] = React.useState(0.0);
  const [total, setTotal] = React.useState(0);
  const [SellTotal, setSellTotal] = React.useState(0);
  const [currencyPrice1, setcurrencyPrice1] = useState("");
  const [sellprice, setsellprice] = useState("");
  const [range, setRange] = useState(1);
  const [sellrange, setSellRange] = useState(1);
  const [selloader , setSellLoader] = useState(false);
  const [buyloader , setBuyLoader] = useState(false);


  const [atSellPrice, setAtSellPrice] = React.useState(
    round(active_coin_details?.current_price_inr) ?? round(active_coin_details?.current_price_inr)
  );
  const [atBuyPrice, setAtBuyPrice] = React.useState(
    round(active_coin_details?.current_price_inr) ?? round(active_coin_details?.current_price_inr)
  );
  
  const [inr_price, setInr_Price] = React.useState(buy_order_inr_price);
  let browser_currency_pair;
  let coins1 = coins;
  const userid = userInfo.user_id;
  const user_id = userInfo.user_id;
  const dispatch = useDispatch();
 

  const { coin } = useParams();
  if (coin) {
    browser_currency_pair = coin.split("-");
  }

  useEffect(()=>{
    const cp = coins.filter((i)=>{return browser_currency_pair[0] == i.symbol.toLowerCase()});
      const x=round(cp[0]?.current_price_inr)
      let xprice = active_paired_currency=="USDT"?(x/paired_currency_price.USDT):active_paired_currency=="BTC"?(x/paired_currency_price.BTC):active_paired_currency=="ELUX"?(x/paired_currency_price.ELUX):x;
      setAtBuyPrice(round(xprice))
      setAtSellPrice(round(xprice))
  },[coins,active_paired_currency,paired_currency_price])

  function getCoinRate(coin) {
    let res = coins1.find((d) => d.symbol === coin.toUpperCase());
     
    if (coin === "USDT") {
      return 1;
    } else {
      return round(res?.current_price_inr)
    }
  }

  function getWallets() {
    N_get_wallet(userid).then((d) => {
      if (d.status == 200) {
        let total = 0;
        let final_data = Object.keys(d.data.params.wallets).map((res, i) => {
          let rate = getCoinRate(res, browser_currency_pair[1]);
          let inr_val =mul(rate, d.data.params.wallets[res]?.balance);
          total = add(total,inr_val);

          return {
            id: d.data.params.wallets[res]?.id,
            deposit_limit: d.data.params.wallets[res]?.deposit_limit
              ? d.data.params.wallets[res].deposit_limit
              : 0,
            icon: d.data.params.wallets[res]?.icon,
            symbol: d.data.params.wallets[res]?.symbol.toUpperCase(),
            name: d.data.params.wallets[res]?.name,
            status: d.data.params.wallets[res]?.status,
            withdral_fee: d.data.params.wallets[res]?.withdrawal_fee,
            locked:round(d.data.params.wallets[res]?.locked),
            address: d.data.params.wallets[res]?.wallet_address,
            balance: round(d.data.params.wallets[res]?.balance),
            avl_balance: sub(d.data.params.wallets[res]?.balance, d.data.params.wallets[res]?.locked),
            inr_balance: inr_val,
          };
        });
        setWalletDetails(final_data);
        setTotalInr(total);
        setTimeout(() => setLoading(false), 800);
      } else {
        console.log(d);
      }
    });
  }

  // useEffect(() => {
  //   getWallets();
  // }, []);

  useEffect(() => {
    getWallets();

  }, [active_paired_currency, coins]);

  useEffect(() => {

    if (wallet_details) {
      wallet_details.filter((item) => {
        if (item?.symbol === active_paired_currency) {
          setcurrencyPrice1(item.avl_balance);
        }
      });
    }
    if (wallet_details) {
      wallet_details.filter((item) => {
        if (item.symbol === active_coin) {
          setsellprice(item.avl_balance);
        }
      });
    }
  }, [active_coin_details, currencyPrice1, active_paired_currency]);
  useEffect(() => {
    if (wallet_details) {
      wallet_details.filter((item) => {
        if (item?.symbol === active_paired_currency) {
          setcurrencyPrice1(item.avl_balance);
        }
      });
    }
    if (wallet_details) {
      wallet_details.filter((item) => {
        if (item.symbol === active_coin) {
          setsellprice(item.avl_balance);
        }
      });
    }
  });

  const getUserOrder = () => {
    N_OrdersHistory(user_id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            set_user_order_pending({
              pending_order: res.data.params.trade_history.pending,
            })
          );
          dispatch(
            set_user_order_close({
              close_order: res.data.params.trade_history.compleated,
            })
          );
        } else {
          console.log("error to fetch open and close orders: ", res);
        }
      })
      .catch((e) => console.log(e));
  };

  // const getUserOrderclose = () => {
  //   N_OrdersHistory(user_id)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         dispatch(
  //           set_user_order_close({
  //             close_order: res.data.params.trade_history.compleated,
  //           })
  //         );
  //       } else {
  //         console.log("error to fetch open and close orders: ", res);
  //       }
  //     })
  //     .catch((e) => console.log(e));
  // };

  //  Buy function start
  function buyCoin(atBuyPrice, amount, c, cp) {
    setBuyLoader(true);
    N_createBuyOffer(atBuyPrice, amount, c, cp, user_id).then((data) => {
      if (data.status === 200) {
        const d = data.data;
        toast.success(d.message);
        setBuyLoader(false);
        getWallets();
        getUserOrder();
        N_executeOrder(d.result.order_id, user_id, d.result.type)
        .then((res)=>{
          if(res.data.status == 200) {
            setBuyLoader(false);
            getUserOrder();
            getWallets();
            
          }
        });
      } else if (data.status === 400) {
        toast.error(data.data.message);
        setBuyLoader(false);
        getWallets();
        getUserOrder();
        
      }
    });
  }
  // Buy function close

  // sell section

  function sellCoin(atSellPrice, amount, c, cp) {
    setSellLoader(true);
    N_createSellOffer(atSellPrice, amount, c, cp, user_id)
      .then((data) => {
        if (data.status === 200) {
          const d = data.data;
          toast.success(d.message);
          getWallets();
          getUserOrder();
          setSellLoader(false);
          N_executeOrder(d.result.order_id, user_id, d.result.type)
          .then((res)=>{
            if(res.data.status == 200) {
              getUserOrder();
              getWallets();
              
            }
          });
         
        } else if (data.status === 400) {
          getWallets();
          getUserOrder();
          toast.error(data.data.message);
          setSellLoader(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // sell section close
  // buy 

  useEffect(() => {
    setAtBuyPrice(buy_order_inr_price)
    setAmount(round(buy_order_volume));
    setTotal(buy_order_inr_price*buy_order_volume)
  }, [buy_order_inr_price, buy_order_volume]);

//  sell

  useEffect(() => {
    setAtSellPrice(sell_order_inr_price)
    setsAmount(sell_order_volume);
    setSellTotal(sell_order_inr_price*sell_order_volume)
  }, [sell_order_inr_price, sell_order_volume]);

  useEffect(() => {
    setAtBuyPrice(round(Number(active_coin_details.current_price_inr==0?0:active_coin_details.current_price_inr)));
    setAtSellPrice(round(Number(active_coin_details.current_price_inr)));
    setAmount("0");
    setsAmount("0")
    setSellTotal("0")
    setTotal("0")
  }, [active_coin_details]);



  return (
    <>
      <ul className="nav nav-tabs tabs buy_sell " role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#Limit_Order"
            type="button"
            role="tab"
          >
           Buy
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#Market_Order"
            type="button"
            role="tab"
          >
            Sell
          </button>
        </li>
      </ul>
      <div className={isLoggedIn==false?"hgt_580":"tab-content hgt_253"} id="myTabContent">
        <div className="tab-content">
          <div
            className="tab-pane fade show active"
            id="Limit_Order"
            role="tabpanel"
          >
            <div className="col-lg-12 col-md-12 col-sm-12 col-sm-12  limit_order_area">
              <div className="limit_order_area_block">
                <div className="text-end">
                <span className="me-1 text-dark">Balance: </span>
                  <span className="">
                    {active_paired_currency}{" "}
                    <span className="balance">
                      {currencyPrice1 ? currencyPrice1 : "0.0000000"}
                    </span>
                  </span>
                </div>
                <div className="mb-2">
                  <label>Price</label>
                  <input
                    type="text"
                    className="form-control"
                    value={atBuyPrice}
                    onChange={(e) => {
                      setAtBuyPrice( e.target.value
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..*?)\..*/g, "$1")
                        );
                      // setAtSellPrice( e.target.value
                      //   .replace(/[^0-9.]/g, "")
                      //   .replace(/(\..*?)\..*/g, "$1")
                      //   );
                      setTotal(
                        e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1") * amount
                      );
                    }}
                  />
                </div>
                <div>
                  <label>Volume</label>
                  <input
                    type="number"
                    className="form-control"
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                      setTotal(e.target.value * atBuyPrice);
                    }}
                  />
                </div>
                <div className="price-range-slider">
                  <input
                    type="range"                    
                    id="slider-range"
                    className="range-bar text-white"
                    value={range}
                    onChange={(e) => {
                      if (e.target.value >= 1) {
                        setRange(e.target.value);
                        const tamt = (currencyPrice1 * e.target.value) / 100;
                        setTotal(tamt);
                        setAmount(round(tamt / atBuyPrice));
                      }
                    }}
                  />
                  <span id="demo" className="text-info mx-2">
                    {range}%
                  </span>
                  <div className="range-value">
                    <span className="me-1">Total {active_paired_currency}</span>
                    <span>
                      <input type="text" value={round(total)} readonly />
                    </span>

                    {/* <span>Hide</span> */}
                  </div>
                  {/* <div className="no_pad fee_area">
                    <strong>
                      <span>
                        Fee{" "}
                        <i className="fa fa-sort-desc" aria-hidden="true"></i>
                      </span>
                      <span className="fees">500</span>
                    </strong>
                    <strong>
                      <span className="lght_green">Earn</span>
                      <span>0.05%</span>
                      <span>/</span>
                      <span>Pay</span>
                      <span className="red">0.02%</span>
                    </strong>
                  </div> */}
                </div>
                {isLoggedIn ? (
                  <div
                    className="btn btn-success login_register text-white mb-1"
                    onClick={() => {
                      if (isLoggedIn) {
                        const c = coin.split("-");
                        buyCoin(atBuyPrice, amount, c[0], c[1]);
                        // toast.success("Successfully");
                        setAmount("0");
                        setTotal("0");
                      } else {
                        toast.error("First login then perform buy/sell");
                      }
                    }}
                  >
                    {buyloader?<div className="spinner-border text-light m-0" style={{height:"0px !important",width:"0px !important;"}}></div>:"Buy"}
                    
                  </div>
                ) : (
                  <div className="login_register mt-2">
                    <a href="/login" className="">Login</a> or{" "}
                    <a href="/register" className="">Register</a> to trade
                  </div>
                )}
              </div>
            </div>

          </div>
          <div
            className="tab-pane fade"
            id="Market_Order"
            role="tabpanel"
          >

            {/* selling code */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-sm-12 limit_order_area p-2">
              <div className="limit_order_area_block">
                <div className="text-end">
                  <label className="me-1 text-dark">Balance: </label>
                  <span className="fw-bold">
                  {" "}{active_coin}{" "}
                    
                    <span className="balance">
                      {sellprice ? sellprice : "0.000000"}
                    </span>
                  </span>
                </div>
                <div className="mb-2">
                  <label>Price</label>
                  <input
                    type="text"
                    value={atSellPrice}
                    className="form-control"
                    onChange={(e) => {
                      // setAtBuyPrice(
                      //   e.target.value
                      //     .replace(/[^0-9.]/g, "")
                      //     .replace(/(\..*?)\..*/g, "$1")
                      // );
                      setAtSellPrice(
                        e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1")
                      );

                      setSellTotal(
                        e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1") * samount
                      );
                    }}
                  />
                </div>
                <div>
                  <label>Volume</label>
                  <input
                    type="number"
                    className="form-control"
                    value={samount}
                    onChange={(e) => {
                      setsAmount(e.target.value);
                      setSellTotal(e.target.value * atSellPrice);
                    }}
                  />
                </div>
                <div className="price-range-slider">
                  <input
                    type="range"
                    id="slider-range"
                    className="range-bar text-white"
                    value={sellrange}
                    onChange={(e) => {
                      if (e.target.value >= 1) {
                        setSellRange(e.target.value);
                        const tamt = (sellprice * e.target.value) / 100;
                        setSellTotal(tamt * atSellPrice);
                        setsAmount(tamt);
                      }
                    }}
                  />
                  <span id="demo" className="text-info mx-2">
                    {sellrange}%
                  </span>
                  <p className="range-value">
                    <span className="me-1">Total {active_paired_currency}</span>
                    <span>
                      <input type="text" value={round(SellTotal)} readonly />
                    </span>
                    {/* <span>0</span> */}
                  </p>
                  {/* <div className="no_pad fee_area">
                    <strong>
                      <span>
                        Fee{" "}
                        <i className="fa fa-sort-desc" aria-hidden="true"></i>
                      </span>
                      <span className="fees">500</span>
                    </strong>
                    <strong>
                      <span className="lght_green">Earn</span>
                      <span>0.05%</span>
                      <span>/</span>
                      <span>Pay</span>
                      <span className="red">0.02%</span>
                    </strong>
                  </div> */}
                </div>

                {isLoggedIn ? (
                  <div
                    className="login_register btn btn-danger text-white"
                    onClick={() => {
                      if (isLoggedIn) {
                        const c = coin.split("-");
                        sellCoin(atSellPrice, samount, c[0], c[1]);
                        // toast.success("Successfully");
                        setsAmount("0");
                        setSellTotal("0");
                      } else {
                        toast.error("First login then perform buy/sell");
                      }
                    }}
                  >
                    {selloader? <div className="spinner-border text-light"></div>:"Sell"}
                   
                    </div>
                ) : (
                  <div className="login_register">
                    <a href="/login">Login</a> or{" "}
                    <a href="/register">Register</a> to trade
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="tab-pane fade" id="Market_Order" role="tabpanel">
          2
        </div>
        <div className="tab-pane fade" id="Trigger_Order_Two" role="tabpanel">
          3
        </div> */}
      </div>

      {/* selliing code */}
    </>
  );
};

export default BuyNSell;
