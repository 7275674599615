import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { N_OrdersHistory } from "../utils/api_functions";
import { N_cancleOrderById } from "../utils/api_functions";
import { NotificationManager } from "react-notifications";
import swal from 'sweetalert';
import toast from "react-hot-toast";
import {
  set_user_order_close,
  set_user_order_pending,
} from "../features/Exchange";
import { useDispatch } from "react-redux";
import { round } from "../utils/Math";

function OrdersTab() {
  const { isLoggedIn, userInfo } = useSelector((state) => state?.user?.value);
  const { user_order_pending, user_order_close } = useSelector(
    (state) => state?.exchange?.value
  );

  const user_id = userInfo.user_id;
  const [pendingOrder, setpendingOrder] = useState([]);
  const [closeOrder, setcloseOrder] = useState([]);
  const dispatch = useDispatch();
  
 const  getHistory = () => {
    N_OrdersHistory(user_id).then((res) => {
      if (res.status === 200) {
        let corder = res.data.params.trade_history.compleated;
        let close_order = corder.sort((a,b)=>b.timestamp-a.timestamp);

        let porder = res.data.params.trade_history.pending;
        let pending_order = porder.sort((a,b)=>b.timestamp-a.timestamp);

        dispatch(
          set_user_order_close({
            close_order: close_order
          })
        );
        dispatch(
          set_user_order_pending({
            pending_order: pending_order
          })
        );
      } else {
        console.log("error to fetch open and close orders: ", res);
      }
    });
  }

  const deleteOrder = (order_id) => {
    N_cancleOrderById(user_id, order_id)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message);
          getHistory();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  };



  useEffect(() => {
    getHistory()
  }, []);
  
  // useEffect(() => {
  //   setpendingOrder(
  //     user_order_pending.sort(
  //       (a, b) => Number(b.timestamp) - Number(a.timestamp)
  //     )
  //   );
  //   setcloseOrder(
  //     user_order_close.sort((a, b) => Number(b.timestamp) - Number(a.timestamp))
  //   );
  // }, []);

 const confirmbox=(orderid)=>{
  swal({
    title: "Are you sure?",
    text: "Once deleted, you will not be able to recover this imaginary file!",
    // icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {
      swal("Your list has been deleted!", {
        icon: "success",
        
      },
      deleteOrder(orderid),
      );
    } else {
      swal("Your imaginary list is safe!");
    }
  });

 }

  return (
    <>
      <ul className="nav nav-tabs tabs" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#Open_Order"
            type="button"
            role="tab"
          >
            Open Order
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#Trigger_Order"
            type="button"
            role="tab"
          >
            Trigger Order
          </button>
        </li>
        {/* <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="order-tab"
            data-bs-toggle="tab"
            data-bs-target="#Order_History"
            type="button"
            role="tab"
          >
            24H Order History
          </button>
        </li> */}
      </ul>
      <div className="tab-content hgt_254" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="Open_Order"
          role="tabpanel"
        >
          {/*<div className="open_order_area">
             <a href="" className="active">
              Ordinary
            </a> 
             <a href="" className="">
              Trigger Order
            </a>
            <a href="" className="">
              Hide Order Pair
            </a> 
          </div>*/}

          {/* open orders */}
          {isLoggedIn ? (
            <div className="">
              <table className="table coins_table">
                <thead className="">
                  <tr>
                    <th scope="col" className="light_blue">
                      Date
                    </th>
                    <th scope="col" className="light_blue">
                      Type
                    </th>
                    <th scope="col" className="light_blue">
                      Pair
                    </th>
                    <th scope="col" className="light_blue">
                      Price
                    </th>
                    <th scope="col" className="light_blue">
                      Volume
                    </th>
                    <th scope="col" className="light_blue">
                      Total
                    </th>
                    <th scope="col" className="light_blue">
                      Fees
                    </th>
                    <th scope="col" className="light_blue">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoggedIn ? (
                    user_order_pending.map((item) => (
                      <tr  style={{borderLeft: "2px solid red"}}>
                        <th>
                          {new Date(
                            Number(item.timestamp)
                          ).toLocaleDateString()}
                        </th>
                        <th className="text-uppercase">{item.type=="buy"?<span className="text-success">{item.currency_type}</span>:<span className="text-danger">{item.currency_type}</span>}</th>
                        <th className="text-uppercase">{item.type=="buy"?<span className="text-success">{item.compare_currency}</span>:<span className="text-danger">{item.compare_currency}</span>}</th>
                        <th>{item.raw_price}</th>
                        <th>{item.volume}</th>
                        <th>{item.total_executed}</th>
                        <th>{item.fees}</th>
                        {item.type=="buy"?<div className="text-success pt-0">
                        <i class="fa fa-trash" data-toggle="tooltip" data-placement="top" title="Delete" style={{cursor:"pointer"}}
                        onClick={() => {
                            confirmbox(item.order_id)
                        }}
                       ></i>
                        </div>:<div className="text-danger pt-0">
                        <i class="fa fa-trash" data-toggle="tooltip" data-placement="top" title="Delete" style={{cursor:"pointer"}}
                        onClick={() => {
                            confirmbox(item.order_id)
                        }}
                       ></i>
                        </div>}
                       
                      </tr>
                    ))
                  ) : (
                    <div className="login_register">
                      <a href="/login">Login</a> or{" "}
                      <a href="/register">Register</a> to check
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="login_register">
              <a href="/login">Login</a> or <a href="/register">Register</a> to
              check
            </div>
          )}
        </div>
        {/* end open order */}
        <div className="tab-pane fade" id="Trigger_Order" role="tabpanel">
          <div className="">
            {/* <table className="table coins_table mt0 "></table> */}
            {/* close_order */}
            {isLoggedIn ? (
              <div className="">
                <table className="table coins_table">
                  <thead className="">
                    <tr>
                      <th scope="col" className="light_blue">
                        Date
                      </th>
                      <th scope="col" className="light_blue">
                        Type
                      </th>
                      <th scope="col" className="light_blue">
                        Pair
                      </th>
                      <th scope="col" className="light_blue">
                        Price
                      </th>
                      <th scope="col" className="light_blue">
                        Volume
                      </th>
                      <th scope="col" className="light_blue">
                        Executed
                      </th>
                      <th scope="col" className="light_blue">
                        Fees
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoggedIn ? (
                      user_order_close.map((item) => (
                        <tr  style={{borderLeft: "2px solid green"}}>
                          <th>
                            {new Date(
                              Number(item.timestamp)
                            ).toLocaleDateString()}
                          </th>
                          <th className="text-uppercase ">{item.type=="buy"?<span className="text-success">{item.currency_type}</span>:<span className="text-danger">{item.currency_type}</span>}</th>
                          <th className="text-uppercase ">{item.type=="buy"?<span className="text-success">{item.compare_currency}</span>:<span className="text-danger">{item.compare_currency}</span>}</th>
                          <th>{item.raw_price}</th>
                          <th>{item.volume}</th>
                          <th>{item.total_executed}</th>
                          <th>{item.fees}</th>
                        </tr>
                      ))
                    ) : (
                      <div className="login_register">
                        <a href="/login">Login</a> or{" "}
                        <a href="/register">Register</a> to check
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="login_register">
                <a href="/login">Login</a> or <a href="/register">Register</a>{" "}
                to check
              </div>
            )}
          </div>
        </div>
        {/* <div className="tab-pane fade Order_His " id="Order_History" role="tabpanel">
          vipin
        </div> */}
      </div>
    </>
  );
}

export default OrdersTab;
