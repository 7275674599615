import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import numeral from "numeral";
import {round} from '../utils/Math'

function TradeHistory() {
  const { orderHistory, active_coin, active_paired_currency } = useSelector(
    (state) => state?.exchange?.value
  );
  const [tradeHis, setTradHistory] = useState();
  useEffect(()=>{
    // console.log("orderHistory, active_coin, active_paired_currency", orderHistory, active_coin, active_paired_currency)
    setTradHistory(orderHistory);
  }, [orderHistory, active_coin, active_paired_currency])
  
  // const isSell;
  return (
    <>
      <div className="card no_pad custom_scrollbar">
        {/* <h3 className="head_pad">Trade History</h3> */}
        <div className="trade_history px-2 custom_scrollbar">
          <table className="table coins_table">
            <thead>
              <tr>
                <th scope="col" className="light_blue">
                  Price
                </th>
                <th scope="col" className="light_blue text-center">
                  Volume
                </th>
                <th scope="col" className="light_blue text-end">
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {tradeHis && Object.values(tradeHis).length > 0 ? (
                tradeHis[
                  active_coin.toLowerCase() +
                    active_paired_currency.toLowerCase()
                ] ? (
                  tradeHis[
                    active_coin.toLowerCase() +
                      active_paired_currency.toLowerCase()
                  ].map((item,index) => {

                    return (
                    // { index === 9?false:parseFloat(item.raw_price)>=parseFloat(orderHistory)?false:true}
                      <tr>
                        <th scope="row" className="red">
                          {/* <i
                            className={`fas ${
                              isSell ? "fa-arrow-down" : "fa-arrow-up"
                            }`}
                          ></i> */}
                          {round(item.raw_price)}
                        </th>
                        <td>{round(item.volume)}</td>
                        <td>
                          {new Date(item.timestamp)
                            .toLocaleString()
                            .slice(0, 8)}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: "center" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "100px",
                        }}
                      >
                        <h5
                          className="text-secondary"
                          style={{ fontSize: "12px" }}
                        >
                          No history found!
                        </h5>
                      </div>
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan={4} style={{ textAlign: "center" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "100px",
                      }}
                    >
                      <h5 className="text-secondary">No history found!</h5>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default TradeHistory;
