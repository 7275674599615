import React, { useEffect} from "react";
import Exchange from "./pages/Exchange";
import { Toaster } from "react-hot-toast";
import { Route, Routes, Navigate, useParams } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import Register from "./pages/Register";
import Footer from "./components/Footer";
import Wallet from "./pages/Wallet";
import Login from "./pages/Login";
import { useDispatch, useSelector } from "react-redux";
import createSocketClient from "./socket/socket";
import MyNavbar from "./components/MyNavbar";
import ForgotPassword from "./pages/ForgotPassword";
import TransactionHistory from "./pages/TransactionHistory";
import Withdraw from "./pages/Withdraw";
import Deposit from "./pages/Deposit";
import {
  buy_Order,
  cmcPayload,
  order_History,
  sell_Order,
  GET_CURRENCY_DATA,
  WEB_THEME
} from "./features/Exchange";
import WalletFund from "./pages/WalletFund";
import pretty_print from "./utils/pretty_print";
import Account from "./pages/Account_section/Account";
import ReferalBonus from "./pages/Account_section/ReferalBonus";
import Twofactor from "./pages/Account_section/Twofactor.jsx";
import Kyform from "./pages/Account_section/Kyform";
import { N_getWebsiteData } from "./utils/api_functions";
import { GET_WEBSITE_DATA } from "./features/WebData";
import Home from "./pages/Home";
import Order from "./pages/Order";
import NewHome from "./new_component/new_home";
import Fees from "./pages/Fees";
import Blog from "./pages/Blog";
import Support from "./pages/Support";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermUse from "./pages/TermUse";
import KycManualform from "./pages/Account_section/KycManualform";
import AuthKyc from "./pages/Account_section/AuthKyc";
import Security_Setting from "./pages/Account_section/Security_Setting";
import ChangePassword from "./pages/ChangePassword";
import P2P from "./pages/P2P";
import P2Ppage from "./pages/P2Ppage";
import Funding from "./pages/Funding";
import P2pOrder from "./pages/p2pOrder.jsx";

const Router = ({ props }) => {
  const dispatch = useDispatch();
  const { switch_theme } = useSelector((state) => state?.exchange?.value);
  const { isLoggedIn } = useSelector((state) => state?.user?.value);
  // const { coin } = useParams();
  // // const coinsss = coin.split("-");
  // console.log("coinsss", coin);
  useEffect(() => {
    const soc = createSocketClient("kujgwvfq-a-ghosttown-z-1fhhup0p6");
    soc.on("cmc_updated", (res) => {
      console.log("cmc res", res);
      dispatch(cmcPayload({ coins: res, cmcLoading: false }));
          dispatch(GET_CURRENCY_DATA({cur_graph:{currency_coin: Date.now(), currency_price: 2}}))
    });

    soc.on("order_history_updated", (res) => {
      dispatch(order_History({ orderHistory: res }));
    });

    soc.on("buy_order_updated", (res) => {
      dispatch(buy_Order({ buyOrder: res }));
    });

    soc.on("sell_order_updated", (res) => {
      dispatch(sell_Order({ sellOrder: res }));
    });
    N_getWebsiteData().then((res)=>{
      if (res.status === 200) {
        
       dispatch(GET_WEBSITE_DATA({webdata: res.data.params.website}));
       return res.data.params.website;
     }
       
      })

      dispatch(WEB_THEME({ switchtheme: switch_theme?switch_theme:'light' }));
      const body = document.getElementsByTagName('body')[0]
      if(switch_theme=='dark'){
        body.classList.add("is_dark")
        body.classList.remove("is_light")
      } else{
        body.classList.add("is_light")
        body.classList.remove("is_dark")
      }
  }, []);
  return (
    <>
      <MyNavbar />
      <Routes>
        <Route exact path="/exchange" element={<Exchange />}>
         <Route path=":coin" element={<Exchange />} />
     </Route>

        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />}>
            <Route path=":id" element={<Register />} />
        </Route>
        {/* <Route exact path="/p2p" element={<P2P />}>
            <Route path=":id" element={<P2P />} />
        </Route>
        <Route exact path="/p2ppage"
          element={isLoggedIn?<P2Ppage />:<Login />}
        /> */}
        <Route exact path="/forgot_password" element={<ForgotPassword />} />
         <Route exact path="/change_password"
          element={isLoggedIn?<ChangePassword />:<Login />}
        />
        <Route exact path="/fund"
          element={isLoggedIn?<WalletFund />:<Login />}
        />
         <Route exact path="/funding"
          element={isLoggedIn?<Funding />:<Login />}
        />
        <Route exact path="/withdraw"
          element={isLoggedIn?<Withdraw />:<Login />}
        />
        <Route exact path="/deposit"
          element={isLoggedIn?<Deposit />:<Login />}
        />
        <Route exact path="/transationhistory"
          element={isLoggedIn?<TransactionHistory />:<Login />}
        />
        <Route exact path="/Security_Settings"
          element={isLoggedIn?<Security_Setting />:<Login />}
        />
        <Route exact path="/Referral_Bonus"
          element={isLoggedIn?<ReferalBonus />:<Login />}
        />
        <Route exact path="/Kyc"
          element={isLoggedIn?<Kyform />:<Login />}
        />
        <Route exact path="/manual-Kyc"
          element={isLoggedIn?<KycManualform />:<Login />}
        />
        <Route exact path="/kyc-final"
          element={isLoggedIn?<AuthKyc />:<Login />}
        />
        <Route exact path="/p2pOrder" element={<P2pOrder />} />
        <Route exact path="/blog" element={<Blog />}/>
        <Route exact path="/fees" element={<Fees />}/>
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />}/>
        <Route exact path="/term-use" element={<TermUse />}/>
        <Route exact path="/support" element={<Support />}/>
        <Route exact path="/" element={<NewHome/>}/>
      </Routes>
          <div>
        <Toaster />
         </div>
    </>
  );
};

export default Router;
