import React, { useState } from "react";
import { useEffect } from "react";
import AccountTab from "./AccountTab";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { N_getReferals } from "../../utils/api_functions";
const ReferalBonus = () => {
  const { webData } = useSelector((state) => state?.WebData?.value);
  const { user_id } = useSelector((state) => state?.user?.value.userInfo);
  const [totalRefral, setRefral] = useState([]);
  const [totalEarn, setTotalEarn] = useState(0);


  useEffect(()=>{
    N_getReferals(user_id)
    .then((data)=>{
      let res = data.data;
      if(res.status == 200) {
        setRefral(res.params.total_referals)
        setTotalEarn(res.params.total_referal_earning)
      }
    })
  },[])
  return (
    <>
      <div className="accountbg">
        <AccountTab />
        {/* <div
        class="alert alert-primary p-1 mt-2 col-lg-8 col-md-11 col-sm-9 col-xs-6 m-auto worn_content"
        role="alert"
        style={{ borderColor: "#639cec" }}
      >
        <p className="p-0 p-warning">
          {" "}
          <i className="fas fa-warning" /> Please do not disclose the password
          of your account, SMS and Google Authentication codes to anyone,
          including our staff. Beware of online fraud. Hotbit staff will not ask
          for your account information or password in any means.
        </p>
      </div> */}

        <div className="page_minheight">
          <div className="container my-4">
            <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-4 mb-2">
                <div class="custom_card shadow_sm">
                  <img src="images/users.png" className="card_img" />
                  <div className="custom_cards_text">
                    <h3>{totalRefral.length}</h3>
                    <p>TOTAL REFERRED FRIENDS</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-4 mb-2">
                <div class="custom_card shadow_sm">
                  <img src="images/commision-eraned.png" className="card_img" />
                  <div className="custom_cards_text">
                    <h3>{totalEarn}</h3>
                    <p>Total Commissions Earned</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-4 mb-2">
                <div class="custom_card shadow_sm">
                  <img src="images/commision-rate.png" className="card_img" />
                  <div className="custom_cards_text">
                    <h3>{webData.referral_fee} {webData.referral_coin}</h3>
                    <p>Your Commissions Rate</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* table */}

          <div className="container">
            <div className="spacer pb-4">
              <h4>Total Referral List</h4>
              <div class="table-responsive">
                <table class="table tbl  table_shadow">
                  <thead>
                    <tr className="bg-light">
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Dates</th>
                      <th scope="col">Coin</th>
                      <th scope="col">COMMISSION</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {totalRefral.length>0 ? totalRefral?.map((d, index) => (
                      <tr>
                        <td>{++index}</td>
                        <td>{d?.name}</td>
                        <td>
                          {d?.time
                            ? new Date(Number(d?.time)).toLocaleString()
                            : "00:00"}
                        </td>
                        <td>{d?.wallet_type}</td>
                        <td>{d?.valume}</td>
                        <td>
                          {d?.kyc_status == 1
                            ? "Verified"
                            : d?.kyc_status == 2
                            ? "Rejected"
                            : d.kyc_status == -1
                            ? "Pending"
                            : "Not Filled"}
                        </td>
                      </tr>
                    )):<tr> <td className="text-center text-muted" colSpan={6}>No Records Found</td></tr>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferalBonus;
