import React, { useEffect } from "react";
import "./homeStyle.css";
import { useSelector } from "react-redux";
import { round } from "../utils/Math";
// import Coin_convert_hpage from "./Coin_convert_hpage";
import Coin_convert_hpage from "../pages/Coin_convert_hpage";
// import Cards from "./Cards";
import Faq from "../pages/Faq";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

export default function NewHome() {
  const { coins } = useSelector((state) => state?.exchange?.value);
  const usdtprice = coins && coins.find((item)=>(item.symbol)=='USDT');
  return (
    <div className="">
      <section className="slider_bg">
        <div className="container">
          <div className="slider_bg_inner">
            <div className="row">
              <div className="col-xl-8 col-md-12">
                <div className="banner__content">
                  <h1 className="title text-uppercase">
                    Buy & Sell Digital Assets <br />
                    In The ELUCKS
                  </h1>
                  <p className="fs-20 desc mt-3">
                    Elucks is the easiest, safest, and fastest way to buy & sell
                    crypto asset exchange.
                  </p>
                  <a href="/exchange/btc-inr" className="btn btn warning">
                    <span>Get started now</span>
                  </a>
                </div>
              </div>
              <div className="col-xl-4 col-md-12">
                <div className="banner__image">
                  <img src="assets/images/layout/banner-01.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={`crypto`}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="crypto__main">
                <div className="flat-tabs">
                  <div className="content-tab">
                    <div className="content-inner align-items-center">
                      {coins.length > 0 ? (
                        coins.map((item) => {
                          if (
                            item.symbol == "BTC" ||
                            item.symbol == "ETH" ||
                            item.symbol == "BNB" ||
                            item.symbol == "ELUX"
                          )
                            return (
                              <div className="crypto-box active">
                                <div className="top">
                                  <a href="/">
                                    <img
                                      src={item.icon}
                                      height="20"
                                      width="20"
                                    />{" "}
                                    <span className="ms-1">{item.name}</span>
                                    <span className="unit">
                                      {item.symbol}/INR
                                    </span>
                                  </a>
                                </div>
                                <h6 className="price">
                                ₹{ Math.round(item.current_price_inr * 10000) / 10000 != 0
                                  ? Math.round(item.current_price_inr * 10000) / 10000
                                  : Math.round(item.current_price_inr * 100000000) /
                                    100000000}
                                </h6>
                                <h6 className="price">
                                  ${ Math.round((item.current_price_inr/usdtprice.current_price_inr) * 10000) / 10000 != 0
                                  ? Math.round((item.current_price_inr/usdtprice.current_price_inr) * 10000) / 10000
                                  : Math.round((item.current_price_inr/usdtprice.current_price_inr) * 100000000) /
                                    100000000}
                                </h6>
                                <div className="bottom">
                                  <p className="my-0">
                                    {item.market_cap}
                                    <span
                                      className={
                                        item.direction_inr == "down"
                                          ? "sale critical"
                                          : "sale success"
                                      }
                                    >
                                      {item.direction_inr == "down" ? "-" : "+"}
                                     { Math.round((item.price_change_percentage_1h_inr) * 10000) / 10000 != 0
                                  ? Math.round((item.price_change_percentage_1h_inr) * 10000) / 10000
                                  : Math.round((item.price_change_percentage_1h_inr) * 100000000) /
                                    100000000}%{" "}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            );
                        })
                      ) : (
                        <div className="text-center m-auto">
                          <i className="loading-icon fas fa-spinner fa-spin text-warning fs-1 "></i>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={`coin-list`}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block-text">
                <h3 className="heading">Market Update</h3>
              </div>

              <div className="coin-list__main">
                <div className="flat-tabs">
                  <div className="content-tab">
                    <div className="content-inner">
                      <table className="table tbl">
                        <thead>
                          <tr>
                            <th scope="col">icon</th>
                            <th scope="col">Name</th>
                            <th scope="col">Price</th>
                            <th scope="col">24h %</th>
                            <th scope="col">Market Cap</th>
                            <th scope="col">Trade</th>
                          </tr>
                        </thead>
                        <tbody>
                          {coins.length > 0 ? (
                            coins.map((item) => {
                              if (item.symbol != "INR" && item.symbol != "USDT")
                                return (
                                  <tr>
                                    <td>
                                      <img
                                        src={item.icon}
                                        height="20"
                                        width="20"
                                      />
                                    </td>
                                    <td>
                                      <span>{item.name}</span>
                                      <span className="unit">
                                        ({item.symbol})
                                      </span>
                                    </td>
                                    <td className="boild">
                                    ${ Math.round((item.current_price_inr/usdtprice.current_price_inr) * 10000) / 10000 != 0
                                  ? Math.round((item.current_price_inr/usdtprice.current_price_inr) * 10000) / 10000
                                  : Math.round((item.current_price_inr/usdtprice.current_price_inr) * 100000000) /
                                    100000000}
                                    </td>
                                    <td className={item.direction_inr}>
                                      {item.direction_inr == "down" ? "-" : "+"}
                                      {item.price_change_percentage_1h_inr}%
                                    </td>
                                    <td className="boild">{item.market_cap}</td>
                                    <td>
                                      <a
                                        href={`/exchange/${item.symbol.toLowerCase()}-inr`}
                                        className="btn btn-outline-info"
                                      >
                                        Trade
                                      </a>
                                    </td>
                                  </tr>
                                );
                            })
                          ) : (
                            <tr>
                              <td colspan={6} className="text-center">
                                <i className="loading-icon fas fa-spinner fa-spin text-warning fs-1 "></i>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`work`}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block-text center">
                <h3 className="heading">How It Work</h3>
                <p className="fs-20 desc">
                  A crypto exchange is a platform for buying and selling
                  cryptocurrencies.
                </p>
              </div>

              <div className="work__main">
                <div className="work-box">
                  <div className="image">
                    <img src="assets/images/icon/Cloud.png" alt="" />
                  </div>
                  <div className="content">
                    <p className="step">SignUp</p>
                    {/* <p className="text">
                    Stacks is a production-ready library of stackable content
                    blocks built in React Native.
                  </p> */}
                  </div>
                  <img
                    className="line"
                    src="assets/images/icon/connect-line.png"
                    alt=""
                  />
                </div>
                <div className="work-box">
                  <div className="image">
                    <img src="assets/images/icon/Wallet.png" alt="" />
                  </div>
                  <div className="content">
                    <p className="step">Create Wallet & Deposit</p>
                    {/* <p className="text">
                    Stacks is a production-ready library of stackable content
                    blocks built in React Native.
                  </p> */}
                  </div>
                  <img
                    className="line"
                    src="assets/images/icon/connect-line.png"
                    alt=""
                  />
                </div>
                <div className="work-box">
                  <div className="image">
                    <img src="assets/images/icon/Mining.png" alt="" />
                  </div>
                  <div className="content">
                    <p className="step">Start trading</p>
                    <a href="#" className="title"></a>
                    {/* <p className="text">
                    Stacks is a production-ready library of stackable content
                    blocks built in React Native.
                  </p> */}
                  </div>
                  <img
                    className="line"
                    src="assets/images/icon/connect-line.png"
                    alt=""
                  />
                </div>
                <div className="work-box">
                  <div className="image">
                    <img src="assets/images/icon/Comparison.png" alt="" />
                  </div>
                  <div className="content">
                    <p className="step">Earn Money</p>
                    {/* <p className="text">
                    Stacks is a production-ready library of stackable content
                    blocks built in React Native.
                  </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-sale">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-7">
              <div class="block-text">
                <h4 class="heading">Earn the crypto</h4>
                <p class="desc">
                  Discover how specific cryptocurrencies work — and get a bit of
                  each crypto to try out for yourself.
                </p>
              </div>
            </div>
            <div class="col-md-5">
              <div class="button">
                <a href="/register" className="btn btn-warning">
                  Create Account
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="work">
        <div class="container">
          <div class="row align-items-center">
          <div className="col-lg order-1 order-lg-1 d-flex flex-column align-items-lg-center justify-content-lg-center">
            <div className="container ">
              <h1 className="fw-bold mb-4 text-lg-left w-75 ">
                Wherever you go, Stay with us
              </h1>
              <p className=" text-lg-left fs-5 w-75 grey_text">
                Experience the convenience of trading cryptocurrencies on your
                smartphone. Download our app now to stay connected to the market
                and manage your portfolio wherever you are
              </p>
            </div>
            <div className="container mt-lg-5 mt-md-5">
              <h6>Get the Mobile App Now!</h6>
              <div className="d-flex gap-3 mt-2">
                <a href="/img/app-release.apk" className="app-btn">
                  <img src="/img/googlePlay.svg" className="img-fluid" />
                </a>
              </div>
            </div>
          </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
    
  );
}
