import React, { useState, useEffect } from "react";
import WalletTab from "../components/WalletTab";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { N_transectionHistory } from "../utils/api_functions";
const TransactionHistory = () => {
  const { user_id } = useSelector((state) => state?.user?.value.userInfo);
  const [history, setHistory] = useState("");
  const [loading, setLoading] = useState(true);
  const { switch_theme } = useSelector((state) => state?.exchange?.value);
  const navigate = useNavigate();

  const userid = user_id;
  useEffect(() => {
    N_transectionHistory(userid)
      .then((dt) => {
        // if (status !== -5) setHistory(dt);
        if (dt.status === 200) {
          setHistory(dt.data.params.result);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div className="accountbg account_tab bgcolr">
      <WalletTab />
      <div className="container text-repo" style={{ minHeight: "34em" }}>
        <div className="box-body">
          <div className="table-responsive mt-5">
            <table className="table tbl">
              <thead>
                <tr>
                  <th>ASSET</th>
                  <th>TYPE</th>
                  <th>VOLUME</th>
                  <th>STATUS</th>
                  <th>TIME</th>
                </tr>
              </thead>
              {history ? (
                <tbody>
                  {history
                    ? history.map((d, index) => (
                        <tr key={index * 5}>
                          <td>{d.symbol}</td>
                          <td>{d.type}</td>
                          <td>{d.amount}</td>
                          <td>
                            {d.status == 1
                              ? "Success"
                              : d.status == -2
                              ? "Cancel"
                              : "Pending"}
                          </td>
                          <td>{new Date(d.createdAt).toLocaleString()}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              ) : null}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
