import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import WalletTab from "../components/WalletTab";
import { N_DepositUpdate, N_TransferP2P, N_get_wallet } from "../utils/api_functions";

import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { add, mul, round, sub } from "../utils/Math";
import { set_Total_Inr_Price } from "../../src/features/Exchange";

const WalletFund = () => {
  const dispatch = useDispatch();
  const { coins, currency_prefix, user_fav_currency, active_paired_currency } =
    useSelector((state) => state?.exchange?.value);
  const { user_id } = useSelector((state) => state?.user?.value.userInfo);

  const userid = user_id;
  const [wallet_details, setWalletDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total_inr, setTotalInr] = useState(0);
  const [transfersymbol, setTransferSymbol] = useState();
  const [transferType, setTransferType] = useState();
  const [transferAmount, setTransferAmount] = useState(0);
  const navigate = useNavigate();

  let coins1 = coins;
  function getCoinRate(coin) {
    let res = coins1.find((d) => d.symbol === coin.toUpperCase());
    //  console.log("inr price: ", res, coin);
    if (coin === "INR") {
      return 1;
    } else {
      return res?.current_price_inr ? res.current_price_inr : 0;
    }
  }
  function getWallets() {
    N_get_wallet(userid)
      .then((data) => {
        let d = data.data;
        if (d.status === 200) {
          let total = 0;
          let final_data = Object.keys(d.params.wallets).map((res, i) => {
            let wallet_type = d.params.wallets[res]?.symbol;
            let rate = getCoinRate(wallet_type, "INR");
            let inr_val = mul(rate, d.params.wallets[res]?.balance);
            total = add(total, inr_val);
            return {
              id: d.params.wallets[res]?.id,
              deposit_limit: d.params.wallets[res]?.deposit_limit
                ? d.params.wallets[res].deposit_limit
                : 0,
              icon: d.params.wallets[res]?.icon,
              symbol: wallet_type,
              name: d.params.wallets[res]?.name,
              status: d.params.wallets[res]?.status,
              withdral_fee: d.params.wallets[res]?.withdrawal_fee,
              locked: round(d.params.wallets[res]?.locked),
              address: d.params.wallets[res].wallet_address,
              balance: round(d.params.wallets[res]?.balance),
              avl_balance: sub(
                d.params.wallets[res]?.balance,
                d.params.wallets[res]?.locked
              ),
              inr_balance: inr_val
            };
          });
          setWalletDetails(final_data);
          setTotalInr(total);
          dispatch(set_Total_Inr_Price({ total_inr_price: total }));
          setTimeout(() => setLoading(false), 800);
        } else {
          console.log(d);
        }
      })
      .catch((e) => {
        console.log("er", e);
      });
  }
  const Ncard =
  wallet_details &&
  wallet_details.map((res) => {
    return <option value={res.symbol}>{res.symbol}</option>;
  });
  useEffect(() => {
    getWallets();
  }, [coins]);

  useEffect(() => {
    N_DepositUpdate(userid);
  }, []);
  return (
    <div className="accountbg account_tab">
      <Toaster position="top-right" reverseOrder={false} />
      <WalletTab />
      <div className="container text-repo" style={{ minHeight: "34em" }}>
        <div className="box-body">
        <div className="col-md-12 mb-4">
                    <div className="card">
                      <div className="card-body">
                      <div className="row align-items-center">
                      <div className="col-12 col-md-3">
                        <label htmlFor="coin" className="text-muted fs-12">Select Coin</label>
                        <select
                          class="buy-sell-form-bg buy-sell-theme form-control"
                          id="coin"
                          onChange={(e) => {
                            setTransferSymbol(e.target.value);
                          }}
                        >
                          <option selected>Please Select</option>
                          {Ncard}
                        </select>
                      </div>
                      <div className="col-12 col-md-3">
                        <label htmlFor="coin_transfer" className="text-muted fs-12">Select Transfer</label>
                        <select
                          class="buy-sell-form-bg buy-sell-theme form-control"
                          id="coin_transfer"
                          onChange={(e) => {
                            setTransferType(e.target.value);
                          }}
                        >
                          <option selected>Please Select</option>
                          <option value="add">Spot to Funding</option>
                        </select>
                      </div>
                      <div className="col-12 col-md-3">
                        <label htmlFor="amount"  className="text-muted fs-12">Enter Amount</label>
                        <input
                          type="text"
                          name="amount"
                          className="buy-sell-form-bg buy-sell-theme form-control"
                          id="amount"
                          value={transferAmount}
                          onChange={(e) => {
                            setTransferAmount(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-12 col-md-3">
                        <button
                          className="btn btn-danger"
                          style={{marginTop:"18px"}}
                          onClick={() => {
                            if (
                              transferAmount > 0 &&
                              transfersymbol &&
                              transferType
                            ) {
                              N_TransferP2P(
                                user_id,
                                transferAmount,
                                transfersymbol,
                                transferType
                              ).then((res) => {
                                let data = res.data;
                                if (data.status === 200) {
                                    toast.success(data.message);
                                  document.location.reload();
                                } else {
                                    toast.error(data.message);
                                }
                              });
                            } else {
                                toast.error("Fill all data");
                            }
                          }}
                        >
                          Transfer Balance
                        </button>
                      </div>
                    </div>
                      </div>
                    </div>
                  </div>


          <div className="table-responsive mt-5">
            <table
              className="tbl table"
              style={{
                overflow: "hidden"
              }}
            >
              <thead className="">
                <tr>
                  <th scope="col">ASSETS</th>
                  <th scope="col">AVAILABLE BALANCE</th>
                  <th scope="col">LOCKED</th>
                  <th scope="col">TOTAL</th>
                  <th scope="col">
                    <span className="ftcolr"> {user_fav_currency}</span> VALUE
                  </th>
                </tr>
              </thead>
              <th></th>
              <tbody>
                {loading ? (
                  <tr className="">
                    <td colSpan={8}>
                      <div class="d-flex text-info justify-content-center">
                        <div class="spinner-border">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : wallet_details ? (
                  wallet_details.map((item, index) => (
                    <>
                      {index === 0
                        ? wallet_details.map((item, index) => (
                            <>
                              {item.symbol === "INR" ? (
                                <>
                                  <tr>
                                    <td scope="row">
                                      <img
                                        src={item.icon}
                                        style={{ height: "20px" }}
                                        className="fund_icon"
                                      />
                                      <span>({item.symbol})</span>
                                    </td>
                                    <td>{item.avl_balance}</td>
                                    <td>{item.locked}</td>
                                    <td>{item.balance.toFixed(4)}</td>
                                    <td>
                                      {currency_prefix[user_fav_currency]}{" "}
                                      {item.inr_balance}
                                    </td>
                                  </tr>
                                  <th></th>                                 
                                </>
                              ) : null}
                            </>
                          ))
                        : null}
                      {item.symbol !== "INR" ? (
                        <>
                          <tr className="">                           
                            <td>
                              {item.icon ? (
                                <img
                                  src={item.icon}
                                  alt={item.symbol}
                                  // width="20"
                                  height="20"
                                  className="fund_icon"
                                />
                              ) : (
                                ""
                              )}{" "}
                              {item.name}
                              {item.symbol === "USDT"
                                ? "(" + item.symbol + ")" + " (TRC20)"
                                : item.symbol === "BUSD"
                                ? "(" + item.symbol + ")" + " (BEP20)"
                                : "(" + item.symbol + ")"}
                            </td>
                            <td>
                              {item.balance > 0
                                ? item.avl_balance.toFixed(4)
                                : item.balance}
                            </td>
                            <td>
                              {item.locked > 0
                                ? item.locked.toFixed(4)
                                : item.locked}
                            </td>
                            <td>
                              {item.avl_balance > 0
                                ? (
                                    Number(item.avl_balance) +
                                    Number(item.locked)
                                  ).toFixed(4)
                                : item.avl_balance}
                            </td>
                            <td>
                              {currency_prefix[user_fav_currency]}{" "}
                              {user_fav_currency === "INR"
                                ? item.inr_balance
                                : item.inr_balance > 0
                                ? (
                                    item.inr_balance /
                                    Number(
                                      active_paired_currency[user_fav_currency]
                                    )
                                  ).toFixed(8)
                                : 0}
                            </td>
                          </tr>
                          <th></th>                          
                        </>
                      ) : null}
                    </>
                  ))
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default WalletFund;
