import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Link,useNavigate } from "react-router-dom";
import {
  N_checkKYCStatus
} from "../../utils/api_functions";

const Kyc = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state?.user?.value);
  const userid = userInfo.user_id;
  const [kycstatus, setKycStatus] = useState();

  useEffect(() => {
    N_checkKYCStatus(userid)
    .then((d)=>{
      let res = d.data;
      setKycStatus(res.params.kyc_status);
    })
  }, []);
  return (
    <>
      <div
        class="modal fade"
        id="Kyc"
        aria-hidden="true"
        aria-labelledby="KycLabel"
        tabindex="-1"
        data-bs-backdrop="static" 
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="KycLabel">
              Please Complete Your KYC
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {kycstatus == -1? 
            <div class="modal-body">
              <div className="container">
                <div className="signupform-control" id="kyc_btn">
                  <h4>Your KYC is under Review. please for wait 72 hours.</h4>
                  <button
                    type="button"
                    className="btn btn-success"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>: kycstatus == 1?
             <div class="modal-body">
              <div className="container">
                <div className="signupform-control" id="kyc_btn">
                <h5>
                    Congratulations! Your account is approved.{" "}
                  </h5>
                  <button
                    type="button"
                    className="btn btn-success"
                    data-bs-dismiss="modal"
                    onClick={()=>{
                      navigate("../exchange/btc-inr", {replace:true});
                    }}
                  >
                    Start Trading
                  </button>
                </div>
              </div>
            </div>:<div class="modal-body">
              <div className="container">
                <div className="signupform-control" id="kyc_btn">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="feature_checklist_section_title">
                        WITHOUT KYC
                      </div>
                      <ul className="feature_checklist_section_list fs-14">
                        <li className="">
                          <svg
                            viewBox="0 0 48 48"
                            width="12"
                            height="12"
                            fill="#FFC800"
                          >
                            <path
                              d="m24 48c-13.254834 0-24-10.745166-24-24s10.745166-24 24-24 24 10.745166 24 24-10.745166 24-24 24zm12.5693021-33.5729524c-.5066705-.502545-1.3281286-.502545-1.8349005 0l-15.5444523 15.4164894-5.9750331-5.9257883c-.5066706-.502545-1.3281287-.5024947-1.8349006 0-.5067213.5024947-.5067213 1.3171825 0 1.8197275l6.8924834 6.8355767c.5065185.5024947 1.3285848.5021429 1.8349006 0l16.4619025-16.3262275c.5067213-.5024947.5066706-1.3172328 0-1.8197778z"
                              fill="#55bd6c"
                            ></path>
                          </svg>
                          <span className="ms-2">
                            Deposit
                          </span>
                        </li>
                        <li className="">
                          <svg
                            viewBox="0 0 48 48"
                            width="12"
                            height="12"
                            fill="#FFC800"
                          >
                            <path
                              d="m24 48c-13.254834 0-24-10.745166-24-24s10.745166-24 24-24 24 10.745166 24 24-10.745166 24-24 24zm12.5693021-33.5729524c-.5066705-.502545-1.3281286-.502545-1.8349005 0l-15.5444523 15.4164894-5.9750331-5.9257883c-.5066706-.502545-1.3281287-.5024947-1.8349006 0-.5067213.5024947-.5067213 1.3171825 0 1.8197275l6.8924834 6.8355767c.5065185.5024947 1.3285848.5021429 1.8349006 0l16.4619025-16.3262275c.5067213-.5024947.5066706-1.3172328 0-1.8197778z"
                              fill="#55bd6c"
                            ></path>
                          </svg>
                          <span className="ms-2">
                            Trade
                          </span>
                        </li>
                        <li className="">
                          <svg
                            viewBox="0 0 48 48"
                            width="12"
                            height="12"
                            fill="rgba(28, 27, 33, 0.24)"
                          >
                            <path d="m24 48c-13.254834 0-24-10.745166-24-24s10.745166-24 24-24 24 10.745166 24 24-10.745166 24-24 24zm2.5259167-23.5067083 9.039-9.039c.5615833-.5611042.5615833-1.4715209 0-2.032625-.5615834-.5615834-1.4710417-.5615834-2.032625 0l-9.039 9.039-9.0394792-9.039c-.5615833-.5615834-1.4710417-.5615834-2.032625 0-.5615833.5611041-.5615833 1.4715208 0 2.032625l9.0394792 9.039-9.0394792 9.039c-.5615833.5611041-.5615833 1.4715208 0 2.032625.2807917.2803125.6487917.4207083 1.0163125.4207083s.7355208-.1403958 1.0163125-.4211875l9.0394792-9.039 9.039 9.039c.2807916.2807917.6487916.4211875 1.0163125.4211875.3675208 0 .7355208-.1403958 1.0163125-.4211875.5615833-.5611042.5615833-1.4715208 0-2.032625z"></path>
                          </svg>
                          <span className="ms-2 disabled">
                            Withdrawal
                          </span>
                        </li>
                        <li className="">
                          <svg
                            viewBox="0 0 48 48"
                            width="12"
                            height="12"
                            fill="rgba(28, 27, 33, 0.24)"
                          >
                            <path d="m24 48c-13.254834 0-24-10.745166-24-24s10.745166-24 24-24 24 10.745166 24 24-10.745166 24-24 24zm2.5259167-23.5067083 9.039-9.039c.5615833-.5611042.5615833-1.4715209 0-2.032625-.5615834-.5615834-1.4710417-.5615834-2.032625 0l-9.039 9.039-9.0394792-9.039c-.5615833-.5615834-1.4710417-.5615834-2.032625 0-.5615833.5611041-.5615833 1.4715208 0 2.032625l9.0394792 9.039-9.0394792 9.039c-.5615833.5611041-.5615833 1.4715208 0 2.032625.2807917.2803125.6487917.4207083 1.0163125.4207083s.7355208-.1403958 1.0163125-.4211875l9.0394792-9.039 9.039 9.039c.2807916.2807917.6487916.4211875 1.0163125.4211875.3675208 0 .7355208-.1403958 1.0163125-.4211875.5615833-.5611042.5615833-1.4715208 0-2.032625z"></path>
                          </svg>
                          <span className="ms-2 disabled">
                            P2P
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <div className="feature_checklist_section_title">
                        WITH KYC
                      </div>
                      <ul className="feature_checklist_section_list fs-14">
                        <li className="">
                          <svg
                            viewBox="0 0 48 48"
                            width="12"
                            height="12"
                            fill="#FFC800"
                          >
                            <path
                              d="m24 48c-13.254834 0-24-10.745166-24-24s10.745166-24 24-24 24 10.745166 24 24-10.745166 24-24 24zm12.5693021-33.5729524c-.5066705-.502545-1.3281286-.502545-1.8349005 0l-15.5444523 15.4164894-5.9750331-5.9257883c-.5066706-.502545-1.3281287-.5024947-1.8349006 0-.5067213.5024947-.5067213 1.3171825 0 1.8197275l6.8924834 6.8355767c.5065185.5024947 1.3285848.5021429 1.8349006 0l16.4619025-16.3262275c.5067213-.5024947.5066706-1.3172328 0-1.8197778z"
                              fill="#55bd6c"
                            ></path>
                          </svg>
                          <span className="ms-2">
                            Deposit
                          </span>
                        </li>
                        <li className="">
                          <svg
                            viewBox="0 0 48 48"
                            width="12"
                            height="12"
                            fill="#FFC800"
                          >
                            <path
                              d="m24 48c-13.254834 0-24-10.745166-24-24s10.745166-24 24-24 24 10.745166 24 24-10.745166 24-24 24zm12.5693021-33.5729524c-.5066705-.502545-1.3281286-.502545-1.8349005 0l-15.5444523 15.4164894-5.9750331-5.9257883c-.5066706-.502545-1.3281287-.5024947-1.8349006 0-.5067213.5024947-.5067213 1.3171825 0 1.8197275l6.8924834 6.8355767c.5065185.5024947 1.3285848.5021429 1.8349006 0l16.4619025-16.3262275c.5067213-.5024947.5066706-1.3172328 0-1.8197778z"
                              fill="#55bd6c"
                            ></path>
                          </svg>
                          <span className="ms-2">
                            Trade
                          </span>
                        </li>
                        <li className="">
                          <svg
                            viewBox="0 0 48 48"
                            width="12"
                            height="12"
                            fill="#FFC800"
                          >
                            <path
                              d="m24 48c-13.254834 0-24-10.745166-24-24s10.745166-24 24-24 24 10.745166 24 24-10.745166 24-24 24zm12.5693021-33.5729524c-.5066705-.502545-1.3281286-.502545-1.8349005 0l-15.5444523 15.4164894-5.9750331-5.9257883c-.5066706-.502545-1.3281287-.5024947-1.8349006 0-.5067213.5024947-.5067213 1.3171825 0 1.8197275l6.8924834 6.8355767c.5065185.5024947 1.3285848.5021429 1.8349006 0l16.4619025-16.3262275c.5067213-.5024947.5066706-1.3172328 0-1.8197778z"
                              fill="#55bd6c"
                            ></path>
                          </svg>
                          <span className="ms-2">
                            Withdrawal
                          </span>
                        </li>
                        <li className="">
                          <svg
                            viewBox="0 0 48 48"
                            width="12"
                            height="12"
                            fill="#FFC800"
                          >
                            <path
                              d="m24 48c-13.254834 0-24-10.745166-24-24s10.745166-24 24-24 24 10.745166 24 24-10.745166 24-24 24zm12.5693021-33.5729524c-.5066705-.502545-1.3281286-.502545-1.8349005 0l-15.5444523 15.4164894-5.9750331-5.9257883c-.5066706-.502545-1.3281287-.5024947-1.8349006 0-.5067213.5024947-.5067213 1.3171825 0 1.8197275l6.8924834 6.8355767c.5065185.5024947 1.3285848.5021429 1.8349006 0l16.4619025-16.3262275c.5067213-.5024947.5066706-1.3172328 0-1.8197778z"
                              fill="#55bd6c"
                            ></path>
                          </svg>
                          <span className="ms-2">
                            P2P
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                 <div className="text-center">
                 <button
                    type="button"
                    className="btn btn-light btn-sm"
                    data-bs-dismiss="modal"
                  >
                    SKIP FOR NOW
                  </button>
                  <Link to="/Kyc">
                  <button
                    type="button"
                    id="proceed_btn"
                    className="btn btn-info btn-sm ms-2"
                    data-bs-target="#exampleModalToggle2"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                    onClick={(e) => {
                      const kyc_btn = e.target.parentElement;
                      kyc_btn.className = "signupform-control h";
                      document.getElementById("verify_pan").style.display =
                        "block";
                    }}
                  >
                    COMPLETE KYC
                  </button>
                  </Link>
                 </div>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
     
    </>
  );
};

export default Kyc;
