import React, { useState, useEffect } from "react";
import WalletTab from "../components/WalletTab";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "qrcode.react";
import toast, { Toaster } from "react-hot-toast";
import $ from "jquery";
import {
  N_sendOTPMobile,
  N_verifyOTPMobile,
  N_verifyOTPEmail,
  N_sendOTPEmail
} from "../utils/api_functions";

import {
  N_get_wallet,
  N_ScreenShot,
  N_inr_withdraw,
  N_transectionHistory,
  N_crypto_withdraw,
  N_crypto_withdraw_Otp,
  N_crypto_withdraw_Otp_Email
} from "../utils/api_functions";
import { Link, useNavigate } from "react-router-dom";
import { add, mul, round, sub } from "../utils/Math";
const Withdraw = () => {
  const { coins, currency_prefix, user_fav_currency, active_paired_currency } =
    useSelector((state) => state?.exchange?.value);
  const { user_id } = useSelector((state) => state?.user?.value.userInfo);
  const navigate = useNavigate();
  const userid = user_id;
  const [wallet_details, setWalletDetails] = useState([]);
  const [total_inr, setTotalInr] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dropCurrency, SetdropCurrency] = useState("INR");
  const [selected_coin, setSelectedCoin] = useState("");
  const [withdraw_history, set_deposit_history] = useState("");
  const [volume, setVolume] = useState();
  const [withLoading, setWithLoading] = useState(false);
  const [to_address, setToAddress] = useState();
  const [ctime, setctime] = useState("02:00");
  const [remark, setRemark] = useState();
  const [wallettype, setwallettype] = useState("");
  const [transection_id, setTransection_id] = useState("");
  const [otpType, setOtpType] = useState(3);
  const [msg, setMessage] = useState('');
  const [famount, setFinalAmount] = useState(45);
  const { switch_theme } = useSelector((state) => state?.exchange?.value);

  // mobile
  const [otp, setOTP] = useState();
  const [wotp, setwOTP] = useState();
  let coins1 = coins;
  function getCoinRate(coin) {
    let res = coins1.find((d) => d.symbol === coin.toUpperCase());
    if (coin === "INR") {
      return 1;
    } else {
      return res?.current_price_inr ? res.current_price_inr : 0;
    }
  }

  function getWallets() {
    N_get_wallet(userid).then((d) => {
      if (d.status == 200) {
        let total = 0;
        let final_data = Object.keys(d.data.params.wallets).map((res, i) => {
          let rate = getCoinRate(res, "INR");
          let inr_val = mul(rate, d.data.params.wallets[res]?.balance);
          total = add(total, inr_val);
          return {
            id: d.data.params.wallets[res]?.id,
            deposit_limit: d.data.params.wallets[res]?.deposit_limit
              ? d.data.params.wallets[res].deposit_limit
              : 0,
            icon: d.data.params.wallets[res]?.icon,
            symbol: d.data.params.wallets[res]?.symbol.toUpperCase(),
            name: d.data.params.wallets[res]?.name,
            status: d.data.params.wallets[res]?.status,
            withdral_fee: d.data.params.wallets[res]?.withdrawal_fee,
            locked: round(d.data.params.wallets[res]?.locked),
            address: d.data.params.wallets[res]?.wallet_address,
            balance: round(d.data.params.wallets[res]?.balance),
            avl_balance: sub(
              d.data.params.wallets[res]?.balance,
              d.data.params.wallets[res]?.locked
            ),
            inr_balance: inr_val
          };
        });
        setWalletDetails(final_data);
        setTotalInr(total);
        if (final_data) {
          final_data.filter((item) => {
            if (item.symbol === "INR") {
              setSelectedCoin(item);
            }
          });
        }
        setTimeout(() => setLoading(false), 800);
      } else {
        console.log(d);
      }
    });
  }

  useEffect(() => {
    getWallets();
  }, []);

  useEffect(() => {
    wallet_details.filter((item) => {
      if (item.symbol === dropCurrency) {
        setSelectedCoin(item);
      }
    });
  }, [dropCurrency]);
  useEffect(() => {
    N_transectionHistory(userid)
      .then((dt) => {
        console.log(dt);
        if (dt.status === 200) {
          const data = dt?.data?.params?.result.filter(
            (item) => item.type == "withdrawal"
          );
          set_deposit_history(data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const otpCountdown = () => {
    let duration = 60 * 5;
    let timer = duration,
      minutes,
      seconds;
    const tint = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setctime(minutes + ":" + seconds);

      if (--timer < 0) {
        clearInterval(tint);
      }
    }, 1000);
  };

  return (
    <div className="page_minheight">
      <WalletTab />
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
          
            <div class="tab-content wallet_tab" id="myTabContent">
              <div>
                <div class="row blockchain_area mb-3">
                  <div class="col-md-4 col-lg-4">
                  <h3 className="mb-3">Crypto</h3>
                    <div className="card">
                      <div className="card-body">
                        <select
                          className={`form-select ${
                            switch_theme == "dark" ? " form-select_dark" : ""
                          }`}
                          onChange={(e) => {
                            SetdropCurrency(e.target.value);
                          }}
                        >
                          <option value={dropCurrency} selected>{dropCurrency}</option>
                          {wallet_details.map((item) => (
                            <>
                              {/* {item.symbol !== "INR" ? ( */}
                              <option value={item.symbol}>
                                {item.name}
                                {item.symbol}
                              </option>
                              {/* ) : null} */}
                            </>
                          ))}
                        </select>

                        <div className="">
                          <div class="al_total">
                            {loading ? (
                              <tr>
                                <td colSpan={8}>
                                  <div class="d-flex text-info justify-content-center">
                                    <div class="spinner-border">
                                      <span class="visually-hidden">
                                        Loading...
                                      </span>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ) : selected_coin ? (
                              <>
                                <span>
                                  <h3>Available </h3>
                                  <label>{selected_coin.avl_balance}</label>
                                </span>
                                <span>
                                  <h3>Locked</h3>
                                  <label>{selected_coin.locked}</label>
                                </span>
                                <span>
                                  <h3>Total </h3>
                                  <label>
                                    {selected_coin.balance.toFixed(4)}
                                  </label>
                                </span>
                              </>
                            ) : null}
                          </div>
                        </div>
                        <div class="select_blockchain mt-0">
                          <h3 className="">
                            Transfer from your ELUX Wallet to Another
                          </h3>
                          <div className="fs-14 text-muted mt-2 mb-3">
                            Available {selected_coin.symbol}:{" "}
                            {selected_coin.balance}
                          </div>
                          <div class="four_input mb-4 withdrwal_address">
                            <div className="row Volume_row">
                              <div className="col col-with">
                                <label>Volume</label>
                                <input
                                  type="text"
                                  className="input_button form-control"
                                  value={selected_coin.volume}
                                  maxLength={15}
                                  onChange={(e) => {
                                    setVolume(
                                      e.target.value
                                        .replace(/[^0-9.]/g, "")
                                        .replace(/(\..*?)\..*/g, "$1")
                                    );
                                    setFinalAmount(
                                      e.target.value
                                        .replace(/[^0-9.]/g, "")
                                        .replace(/(\..*?)\..*/g, "$1") -
                                        selected_coin.withdral_fee
                                    );
                                  }}
                                />
                              </div>
                              {selected_coin.symbol!='INR'?
                              <div className="col col-with Desti_col">
                                <label>Destination Address</label>
                                <input
                                  type="text"
                                  className="input_button form-control"
                                  maxLength={60}
                                  onChange={(e) => setToAddress(e.target.value)}
                                />
                              </div>:null}
                              <div className="col col-with">
                                <label> Remark</label>
                                <input
                                  type="text"
                                  className="input_button form-control"
                                  value={remark}
                                  maxLength={60}
                                  onChange={(e) => setRemark(e.target.value)}
                                />
                              </div>
                              <div className="d-grid withdraw_btn--">
                                <button
                                  type="button"
                                  className="btn btn-warning mt-4"
                                  data-bs-toggle="modal"
                                  data-bs-target="#numbermodal"
                                  onClick={() => {
                                    setWithLoading(true);
                                    if(dropCurrency == 'INR') {
                                      N_inr_withdraw(
                                        userid,
                                        volume,
                                        selected_coin.symbol,
                                        remark
                                      ).then((data) => {
                                        let d = data.data;
                                        setWithLoading(false);
                                        if (d.status == 200) {
                                          otpCountdown();
                                          setwallettype(selected_coin.symbol);
                                          setTransection_id(
                                            d.params.transection_id
                                          );
  
                                          setOtpType(1)
                                          toast.success(d.message);
                                        } else {
                                          setMessage(d.message)
                                          setOtpType(2)
                                        }
                                      });
                                    } else {
                                      N_crypto_withdraw(
                                        userid,
                                        selected_coin.symbol,
                                        selected_coin.address,
                                        to_address,
                                        volume,
                                        remark
                                      ).then((data) => {
                                        let d = data.data;
                                        setWithLoading(false);
                                        if (d.status == 200) {
                                          otpCountdown();
                                          setwallettype(selected_coin.symbol);
                                          setTransection_id(
                                            d.params.transection_id
                                          );
                                          toast.success(d.message);
                                          setOtpType(1)
                                        } else {
                                          setMessage(d.message)
                                          setOtpType(2)
                                        }
                                      });
                                    }
                                   
                                  }}
                                >
                                  {withLoading ? (
                                    <span className="loading-icon fas fa-spinner fa-spin mr-2"></span>
                                  ) : (
                                    "WITHDRAWAL"
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                       <ul>
                        <li className="text-muted">
                         Note: After finishing your deposit transaction(s), you may
                          enter the "deposit history" page to track the progress
                          of your deposit(s). History Record(s)
                        </li>                      
                       </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8 col-lg-8">
                    <div class="row">
                      <div class="col-md-12">
                        <h3 class="mt-2">
                          Withdraw History{" "}
                          <Link to="/transationhistory" className="fr light_blue">
                            More
                          </Link>
                        </h3>
                        <table class="table tbl">
                          <thead class="">
                            <tr className="text-center">
                              <th>Status</th>
                              <th>symbol</th>
                              <th>Amount</th>
                              <th>Date</th>
                            </tr>
                          </thead>

                          <tbody>
                            {withdraw_history.length > 0
                              ? withdraw_history.map((item) => (
                                  <>
                                    <tr className="text-center">
                                     <td>{item.status == 1
                                        ? "Success"
                                        : item.status == -2
                                        ? "Cancel"
                                        : "Pending"}</td>
                                      <td>{item.symbol}</td>
                                      <td>{item.amount}</td>
                                      <td>
                                        {new Date(
                                          item.createdAt
                                        ).toLocaleString()}
                                      </td>
                                    </tr>
                                  </>
                                ))
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal mobile number --> */}

      <div
        class="modal fade"
        id="numbermodal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content " style={{ marginTop: "200px" }}>
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {otpType == 1
                  ? "Veryfication Your Email OTP":otpType==0?"Veryfication Your Mobile OTP":otpType==2?msg:"Wait For Minut"}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {otpType==1 || otpType ==0?
            <div class="modal-body">
              <div className="signupform-control g_color">
                {/* otp verication */}

                <div className="signupform-control mt-3">
                  <label htmlFor="user_otp " className="lableclass2">
                    OTP
                  </label>
                  {otpType == 0 ? (
                    <input
                      type="text"
                      id="otp"
                      className="form-control"
                      maxLength={6}
                      value={otp}
                      onChange={(e) =>
                        setOTP(
                          e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*?)\..*/g, "$1")
                        )
                      }
                      required="required"
                      placeholder="Enter 6 digit otp"
                    />
                  ) : (
                    <input
                      type="text"
                      id="wotp"
                      className="form-control"
                      maxLength={6}
                      value={wotp}
                      onChange={(e) =>
                        setwOTP(
                          e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*?)\..*/g, "$1")
                        )
                      }
                      required="required"
                      placeholder="Enter 6 digit otp"
                    />
                  )}
                </div>
                <button
                  type="submit"
                  className="btn btn-success "
                  onClick={(e) => {
                    e.preventDefault();
                    if (otpType === 0) {
                      N_crypto_withdraw_Otp(
                        user_id,
                        otp,
                        transection_id,
                        wallettype
                      )
                        .then((res) => {
                          if (res.data.status == 200) {
                            toast.success(res.data.message);
                            setOtpType(1);
                            setwOTP("");
                          } else {
                            toast.error(res.data.message);
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    } else if (otpType == 1) {
                      N_crypto_withdraw_Otp_Email(
                        user_id,
                        wotp,
                        transection_id,
                        wallettype
                      )
                        .then((res) => {
                          document.querySelector('.btn-close').click();
                          //  showpopup(false);
                          if (res.data.status == 200) {
                            toast.success("Successfully");
                            setTimeout(()=>{
                              window.location.reload();
                            }, 1000)
                          } else {
                            toast.error("Something Went Wrong");
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }
                  }}
                >
                  <span id="reg">Verify OTP</span>
                </button>
              </div>
            </div>:null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Withdraw;
