import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div style={{ paddingTop: "120px" }}>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title text-center">
                <h2> Privacy Policy </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="section section-pad">
        <div class="container">
          <div class="row">
            <div class="col-md-12 res-m-bttm">
              <div class="post post-single">
                <div class="post-entry mt-2">
                  <h3>1.INTRODUCTION</h3>
                  <p>
                    In this policy,<strong>we, us, our</strong> and{" "}
                    <strong>Elucks</strong> refer to Elucks. For more
                    information about us and how to contact us, see Section 11.
                    <br />
                    We respect your privacy and are committed to protecting it
                    through our compliance with this Policy.
                    <br />
                    This privacy policy (<strong>Policy</strong>) applies when
                    we are acting as a data controller with respect to the
                    personal data of our users. This Policy describes how we
                    collect, use and share personal data of consumer users
                    across our websites, including{" "}
                    <strong>
                      <Link to="/">www.elucks.exchange</Link>
                    </strong>{" "}
                    (the <strong>Website</strong>), Eluckss mobile and desktop
                    application (the
                    <strong> App</strong>) and services offered to users
                    (collectively with the Website and the App, the{" "}
                    <strong>Services</strong>), and from our partners and other
                    third parties. When using any of our Services you consent to
                    the collection, transfer, storage, disclosure, and use of
                    your personal data as described in this Policy. This Policy
                    does not apply to anonymised data, as it cannot be used to
                    identify you.
                    <br /> <br />
                    Please read this Policy carefully to understand our policies
                    and practices regarding your personal data and how we will
                    treat it. By accessing or using the Services, you agree to
                    this Policy. Our Services also incorporate privacy controls
                    which affect how we will process your personal data. Please
                    refer to Section 5 for a list of rights with regard to your
                    personal data and how to exercise them.
                    <br />
                    This Policy may change from time to time. Your continued use
                    of the Services after we make changes is deemed to be
                    acceptance of those changes, so please check the Policy
                    periodically for updates.{" "}
                  </p>
                  <h3>
                    2.PERSONAL DATA WE COLLECT ABOUT YOU AND HOW WE COLLECT IT
                  </h3>
                  <p>
                    There are three general categories of personal data we
                    collect.
                  </p>
                  <h4>
                    &nbsp; &nbsp;&nbsp;&nbsp;2.1INFORMATION YOU GIVE TO US.
                  </h4>
                  <p>
                    &nbsp; &nbsp;&nbsp;&nbsp;(a) We collect your account data,
                    which may include personal identification information such
                    as your name, date of birth, age, nationality, gender,
                    signature, utility bills, photographs, phone number, home
                    address, and/or email address, and formal identification
                    information such as Tax ID number, passport number, driver’s
                    license details, national identity card details, photograph
                    identification cards, and/or visa information (collectively,{" "}
                    <strong>Account Data</strong>). The Account Data may be
                    processed for the purposes of providing to you our Services,
                    satisfying anti-money laundering and know-your-customer
                    obligations, and ensuring the security of the Services,
                    maintaining back-ups of our databases and communicating with
                    you. This information is necessary for the adequate
                    performance of the contract between you and us and to allow
                    us to comply with our legal obligations. Without it, we may
                    not be able to provide you with all the requested Services.
                    <br />
                    <br />
                    (b) We process financial information such as bank account,
                    credit card or PayPal information when you order Services in
                    order to facilitate the processing of payments (
                    <strong>Payment Information</strong>). The legal basis for
                    this processing is the performance of a contract between you
                    and us and/or taking steps, at your request, to enter into
                    such a contract and our legitimate interests, namely our
                    interest in the proper administration of our website and
                    business.
                    <br />
                    <br />
                    (c) We may process information contained in or relating to
                    any communication that you send to us (
                    <strong>Correspondence Data</strong>). The Correspondence
                    Data may include the communication content and metadata
                    associated with the communication. The correspondence data
                    may be processed for the purposes of communicating with you
                    and record-keeping. The legal basis for this processing is
                    our legitimate interests, namely the proper administration
                    of our website and business and communications with users.
                    <br />
                    <br />
                    (d) We may process information included in your personal
                    profile, which may include your location, time zone and
                    website (<strong>Profile Data</strong>). The Profile Data
                    may be processed for the purposes of providing you a better
                    user experience when using the Services. The legal basis for
                    this processing is your consent.
                  </p>
                  <h4>
                    &nbsp;&nbsp;&nbsp;&nbsp;2.2INFORMATION WE AUTOMATICALLY
                    COLLECT FROM YOUR USE OF THE SERVICES.
                  </h4>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;When you use the Services, we may
                    automatically process information about your computer and
                    internet connection (including your IP address, operating
                    system and browser type), your mobile carrier, device
                    information (including device and application IDs), search
                    terms, cookie information, as well as information about the
                    timing, frequency and pattern of your service use, and
                    information about to the transactions you make on our
                    Services, such as the name of the recipient, your name, the
                    amount and type of cryptocurrency and timestamp (
                    <strong>Service Data</strong>
                    ). The Service Data is processed for the purpose of
                    providing our Services. The legal basis for this processing
                    is the adequate performance of the contract between you and
                    us, to enable us to comply with legal obligations and our
                    legitimate interest in being able to provide and improve the
                    functionalities of the Services.
                  </p>
                  <h4>
                    &nbsp;&nbsp;&nbsp;&nbsp;2.3 INFORMATION WE COLLECT FROM THIRD
                    PARTIES.
                  </h4>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;From time to time, we may obtain
                    information about you from third party sources as required
                    or permitted by applicable law, such as public databases,
                    credit bureaus, ID verification partners, resellers and
                    channel partners, joint marketing partners, and social media
                    platforms. ID verification partners use a combination of
                    government records and publicly available information about
                    you to verify your identity. Such information includes your
                    name, address, job role, public employment profile, credit
                    history, status on any sanctions lists maintained by public
                    authorities, and other relevant data. We obtain such
                    information to comply with our legal obligations, such as
                    anti-money laundering laws. The legal basis for the
                    processing such data is compliance with legal obligations.
                  </p>
                  <h3>3.DISCLOSURE OF PERSONAL DATA</h3>
                  <p>
                    &nbsp;&nbsp;3.1 We may disclose your personal data to any
                    member of our group of companies (this means our
                    subsidiaries, our ultimate holding company and all its
                    subsidiaries) insofar as reasonably necessary for the
                    purposes, and on the legal bases, set out in this policy.{" "}
                    <br />
                    <br />
                    &nbsp;&nbsp;3.2 We may disclose Service Data to a variety of
                    third party service providers insofar as reasonably
                    necessary to improve the functionalities of the Services.
                    For example, we may disclose Service Data to obtain useful
                    analytics, provide in-app support to mobile app users,
                    determine location data and provide search engine
                    functionality to our users.
                    <br />
                    <br />
                    &nbsp;&nbsp;3.3 We may share aggregated data (information
                    about our users that we combine together so that it no
                    longer identifies or references an individual user) and
                    other anonymized information for regulatory compliance,
                    industry and market analysis, demographic profiling,
                    marketing and advertising, and other business purposes.
                    <br />
                    <br />
                    &nbsp;&nbsp;3.4 In the event of a dispute in P2P transaction,
                    we may disclose some of your Personal Data with the
                    counterparty to such transaction to the extent we deem
                    required, in our discretion, to resolve the dispute.
                    <br />
                    <br />
                    &nbsp;&nbsp;3.5 In addition to the specific disclosures of
                    personal data set out in this Section 3, we may disclose
                    your personal data if we believe that it is reasonably
                    necessary to comply with a law, regulation, legal process,
                    or governmental request; or in order to protect your vital
                    interests or the vital interests of another natural person;
                    to protect the safety or integrity of the Services, or to
                    explain why we have removed content or accounts from the
                    Services; or to address fraud, security, or technical
                    issues; or to protect our rights or property or the rights
                    or property of those who use the Services. 3.6We offer
                    individuals the opportunity to choose (opt out) whether
                    their personal information is (i) to be disclosed to a third
                    party or (ii) to be used for a purpose that is materially
                    different from the purpose(s) for which it was originally
                    collected or subsequently authorized by the individuals. I n
                    order to opt out from disclosure of your personal
                    information, please write to us at{" "}
                    <Link to="">
                      https://support.elucks.exchange/hc/en-us/requests/new.
                    </Link>
                  </p>
                  <h3>4.INTERNATIONAL TRANSFERS OF YOUR PERSONAL DATA</h3>
                  <p>
                    In this Section 4, we provide information about the
                    circumstances in which your personal data may be transferred
                    to countries outside the European Economic Area (EEA). We
                    and our other group companies have offices in India, USA and
                    Singapore. To facilitate our operations we may transfer,
                    store, and process your information within those countries
                    or with service providers based in Europe, India, Asia
                    Pacific and North America. Laws in these countries may
                    differ from the laws applicable to your Country of
                    Residence. For example, information collected within the EEA
                    may be transferred, stored, and processed outside of the EEA
                    for the purposes described in this Privacy Policy. Where we
                    transfer store, and process your personal information
                    outside of the EEA we have ensured that appropriate
                    safeguards are in place to ensure an adequate level of data
                    protection.
                    <br />
                    <br />
                    Transfers to our affiliated entities, to our service
                    providers and other third parties will be protected by
                    appropriate safeguards, namely the use of standard data
                    protection clauses adopted or approved by the European
                    Commission or applicable certification schemes.
                  </p>
                  <h3>5.YOUR RIGHTS WITH REGARD TO PERSONAL DATA</h3>
                  <p>
                    In this Section 5, we have summarized the rights that you
                    have under data protection law based on whether you are a
                    EEAresident (a <strong>EEA Resident</strong>) or you are not
                    a resident of the EEA (a <strong>Non- EEA Resident</strong>
                    ). Some of the rights are complex, and not all of the
                    details have been included in our summaries. Accordingly,
                    you should read the relevant laws and guidance from the
                    regulatory authorities for a full explanation of these
                    rights.
                    <br />
                    <br />
                    &nbsp;&nbsp;5.1 Non-EEA Residents. If you are a Non- EEA
                    Resident, you may access and verify your Personal
                    Information held by Elucks by submitting a written request
                    to us at{" "}
                    <Link to="">
                      https://support.elucks.exchange/hc/en-us/requests/new.
                    </Link>
                    <br />
                    <br />
                    &nbsp;&nbsp;5.2 EEA Residents. If you are a EEA Resident,
                    your principal rights under data protection law are:
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(a) the right to access;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(b) the right to rectification;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(c) the right to erasure;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(d) the right to restrict processing;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(e) the right to object to
                    processing;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(f) the right to data portability;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(g) the right to complain to a
                    supervisory authority; and
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(h) the right to withdraw consent.
                    <br />
                    &nbsp;&nbsp;5.3 As a EEA Resident, you have the right to
                    confirmation as to whether or not we process your personal
                    data and, where we do, access to the personal data, together
                    with certain additional information. That additional
                    information includes details of the purposes of the
                    processing, the categories of personal data concerned and
                    the recipients of the personal data. Providing the rights
                    and freedoms of others are not affected, we will supply to
                    you a copy of your personal data. The first copy will be
                    provided free of charge, but additional copies may be
                    subject to a reasonable fee. You can access your personal
                    data by writing to us at :{" "}
                    <Link to="">
                      https://support.elucks.exchange/hc/en-us/requests/new.
                    </Link>
                    <br />
                    <br />
                    &nbsp;&nbsp;5.4 As a EEA Resident, you have the right to have
                    any inaccurate personal data about you rectified and, taking
                    into account the purposes of the processing, to have any
                    incomplete personal data about you completed. You can
                    request correction or modification of your personal data by
                    writing to us at :{" "}
                    <Link to="">
                      https://support.elucks.exchange/hc/en-us/requests/new.
                    </Link>
                    <br />
                    <br />
                    &nbsp;&nbsp;5.5 In some circumstances, as a EEA Resident, you
                    have the right to the erasure of your personal data without
                    undue delay. Those circumstances include: the personal data
                    are no longer necessary in relation to the purposes for
                    which they were collected or otherwise processed; you
                    withdraw consent to consent-based processing; you object to
                    the processing under certain rules of applicable data
                    protection law; the processing is for direct marketing
                    purposes; and the personal data have been unlawfully
                    processed. However, there are exclusions of the right to
                    erasure. The general exclusions include where processing is
                    necessary: for exercising the right of freedom of expression
                    and information; for compliance with a legal obligation; or
                    for the establishment, exercise or defense of legal claims.
                    You can request the deletion of your account by writing to
                    us at :
                    <Link to="">
                      https://support.elucks.exchange/hc/en-us/requests/new.
                    </Link>
                    <br />
                    <br />
                    &nbsp;&nbsp;5.6 In some circumstances, as a EEA Resident, you
                    have the right to restrict the processing of your personal
                    data. Those circumstances are: you contest the accuracy of
                    the personal data; processing is unlawful but you oppose
                    erasure; we no longer need the personal data for the
                    purposes of our processing, but you require personal data
                    for the establishment, exercise or defense of legal claims;
                    and you have objected to processing, pending the
                    verification of that objection. Where processing has been
                    restricted on this basis, we may continue to store your
                    personal data. However, we will only otherwise process it:
                    with your consent; for the establishment, exercise or
                    defence of legal claims; for the protection of the rights of
                    another natural or legal person; or for reasons of important
                    public interest..
                    <br />
                    <br />
                    &nbsp;&nbsp;5.7 As a EEA Resident, you have the right to
                    object to our processing of your personal data on grounds
                    relating to your particular situation, but only to the
                    extent that the legal basis for the processing is that the
                    processing is necessary for: the performance of a task
                    carried out in the public interest or in the exercise of any
                    official authority vested in us; or the purposes of the
                    legitimate interests pursued by us or by a third party. If
                    you make such an objection, we will cease to process the
                    personal data unless we can demonstrate compelling
                    legitimate grounds for the processing which override your
                    interests, rights and freedoms, or the processing is for the
                    establishment, exercise or defence of legal claims.
                    <br />
                    <br />
                    &nbsp;&nbsp;5.8 As a EEA Resident, you have the right to
                    object to our processing of your personal data for direct
                    marketing purposes (including profiling for direct marketing
                    purposes). If you make such an objection, we will cease to
                    process your personal data for this purpose.
                    <br />
                    <br />
                    &nbsp;&nbsp;5.9 As a EEA Resident, you have the right to
                    object to our processing of your personal data for
                    scientific or historical research purposes or statistical
                    purposes on grounds relating to your particular situation,
                    unless the processing is necessary for the performance of a
                    task carried out for reasons of public interest.
                    <br />
                    <br />
                    &nbsp;&nbsp;5.10 To the extent that the legal basis for our
                    processing of your personal data is:
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(a) consent; or
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(b) that the processing is necessary
                    for the performance of a contract to which you are party or
                    in order to take steps at your request prior to entering
                    into a contract, and such processing is carried out by
                    automated means, and you are a EEA Resident, you have the
                    right to receive your personal data from us in a structured,
                    commonly used and machine-readable format. However, this
                    right does not apply where it would adversely affect the
                    rights and freedoms of others. You can request to download
                    your account information, by writing to us at :{" "}
                    <Link to="">
                      https://support.elucks.exchange/hc/en-us/requests/new.
                    </Link>
                    <br />
                    <br />
                    &nbsp;&nbsp;5.11 To the extent that the legal basis for our
                    processing of your personal data is consent and you are a
                    EEA Resident, you have the right to withdraw that consent at
                    any time. Withdrawal will not affect the lawfulness of
                    processing before the withdrawal.
                    <br />
                    <br />
                    &nbsp;&nbsp;5.12 You may exercise any of your rights in
                    relation to your personal data by written notice to us, in
                    addition to the other methods specified in this Section 5.
                  </p>
                  <h3>6.RETAINING AND DELETING PERSONAL DATA</h3>
                  <p>
                    &nbsp;&nbsp;6.1 This Section 6 sets out our data retention
                    policies and procedure, which are designed to help ensure
                    that we comply with our legal obligations in relation to the
                    retention and deletion of personal data.
                    <br />
                    &nbsp;&nbsp;6.2 Personal data that we process for any
                    purpose or purposes shall not be kept for longer than is
                    necessary for that purpose or those purposes.
                    <br />
                    &nbsp;&nbsp;6.3 We generally retain your personal
                    information for as long as is necessary for the performance
                    of the contract between you and us and to comply with our
                    legal obligations. If you no longer want us to use your
                    information to provide the Services to you, you can request
                    that we erase your personal information and close your
                    account.
                    <br />
                    &nbsp;&nbsp;6.4 Notwithstanding the other provisions of this
                    Section 6, we may retain your personal data where such
                    retention is necessary for compliance with a legal
                    obligation to which we are subject, or in order to protect
                    your vital interests or the vital interests of another
                    natural person.
                  </p>
                  <h3>7.CHILDREN</h3>
                  <p>
                    Our Services are not intended for children. You must also be
                    old enough to consent to the processing of your personal
                    data in your country without parental consent. No one under
                    age 18 may provide any personal data through the Services.
                    If you are under 18, do not use or provide any information
                    through the Services or on or through any of their features
                    or register an account, make any purchases through the
                    Services, use any of the interactive features of the
                    Services or provide any information about yourself to us,
                    including your name, address, telephone number, e-mail
                    address or any screen name or user name you may use.
                  </p>
                  <h3>8.COOKIES</h3>
                  <p>
                    &nbsp;&nbsp;8.1 About cookies
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(a) A cookie is a file containing an
                    identifier (a string of letters and numbers) that is sent by
                    a web server to a web browser and is stored by the browser.
                    The identifier is then sent back to the server each time the
                    browser requests a page from the server.
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(b) Cookies may be either persistent
                    cookies or session cookies: a persistent cookie will be
                    stored by a web browser and will remain valid until its set
                    expiry date, unless deleted by the user before the expiry
                    date; a session cookie, on the other hand, will expire at
                    the end of the user session, when the web browser is closed.
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(c) Cookies do not typically contain
                    any information that personally identifies a user, but
                    personal information that we store about you may be linked
                    to the information stored in and obtained from cookies.
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(d) We also use other technologies
                    with similar functionality to cookies, such as web beacons,
                    web storage, and unique advertising identifiers, to collect
                    information about your activity, browser, and device.
                    <br />
                    &nbsp;&nbsp;8.2 We use these technologies for the following
                    purposes:
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(a) to identify you and log you into
                    the Services;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(b) to store information about your
                    preferences and to personalise the Services for you;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(c) as an element of the security
                    measures used to protect user accounts, including preventing
                    fraudulent use of login credentials, and to protect our
                    website and services generally;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(d) to help us display content that
                    will be relevant to you;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(e) to help us analyse the use and
                    performance of the Services ; and
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(f) to store your preferences in
                    relation to the use of cookies more generally.
                    <br />
                    &nbsp;&nbsp;8.3 Cookies used by our service providers
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(a) We use the following service
                    providers to analyse the use of the Services. Each service
                    provider gathers information about the Services by means of
                    cookies and this information is used to create reports about
                    with usage information. You can find information about the
                    service providers privacy policies and practices at the URLs
                    set forth below:
                    <br />
                    &nbsp;&nbsp;8.4 Managing cookies
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(a) Most browsers allow you to
                    refuse to accept cookies and to delete cookies. The methods
                    for doing so vary from browser to browser, and from version
                    to version.
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(b) Your mobile device may allow you
                    to control cookies through its settings function. Refer to
                    your device manufacturers instructions for more information.
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(c) If you choose to decline
                    cookies, some parts of the Services may not work as intended
                    or may not work at all.
                  </p>
                  <h3>9.DATA SECURITY</h3>
                  <p>
                    &nbsp;&nbsp;9.1 We have implemented measures designed to
                    secure your personal data from accidental loss and from
                    unauthorized access, use, alteration and disclosure. The
                    safety and security of your information also depends on you.
                    Where we have given you (or where you have chosen) a
                    password for access to certain parts of the Services, you
                    are responsible for keeping this password confidential. We
                    ask you not to share your password with anyone.
                    <br />
                    <br />
                    &nbsp;&nbsp;9.2 Steps taken to ensure data security:
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(a) All the user information can
                    only be accessed by authorized users;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(b) Users need to authenticate
                    themselves with a username-password combination; and
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;(c) All data is hosted on Amazon AWS
                    servers.
                    <br />
                    &nbsp;&nbsp;9.3 Unfortunately, the transmission of
                    information via public networks such as the internet is not
                    completely secure. Although we do our best to protect your
                    personal data, we cannot guarantee the security of your
                    personal data transmitted through the Services. Any
                    transmission of personal data is at your own risk. We are
                    not responsible for the circumvention of any privacy
                    settings or security measures contained on the Services.
                  </p>
                  <h3>10.CHANGES TO OUR PRIVACY POLICY</h3>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;(a)It is our policy to post any
                    changes we make to our Policy on this page. If we make
                    material changes to how we treat our users' personal data,
                    we will notify you by e-mail to the primary e-mail address
                    specified in your account. The date the Policy was last
                    revised is identified at the top of the page. You are
                    responsible for ensuring we have an up-to-date active and
                    deliverable e-mail address for you, and for periodically
                    visiting our Website and this Policy to check for any
                    changes.
                  </p>
                  <h3>11.CONTACT INFORMATION</h3>
                  <p>
                    The data controller responsible for your personal data is
                    Elucks. Please write to us at{" "}
                    <Link to="">
                      https://support.elucks.exchange/hc/en-us/requests/new.
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
