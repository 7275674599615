// import { t } from "i18next";
import { createRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
// import { useTranslation } from "react-i18next";
import { Link,useParams} from "react-router-dom";
//import toast from "react-hot-toast";
import {api} from "../../utils/api";
export default function MobileRegistration(props) {
  const [eye, seteye] = useState(false);
  const [ceye, setceye] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const password = createRef(); //useRef({});
  password.current = watch("password", "");
  const [isAgreed, setIsAgreed] = useState(false);
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState();
  const [isRegistering, setIsRegistering] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [userOTP, setUserOTP] = useState("");
  const { id} = useParams();
  // const { t } = useTranslation();


  const resetForm = () => {
    document.forms["mobileRegistrationForm"].reset();
    setIsAgreed(false);
    setIsSendingOTP(false);
    setIsOTPSent(false);
    setIsOTPVerified();
    setIsRegistering(false);
    setMobileNumber("");
    setUserOTP("");
  };
  const hsPassword = (_id) => {
    var _idv = document.querySelector("#" + _id);
    if (_idv.getAttribute("type") == "text") {
      _idv.setAttribute("type", "password");
      if(_id == "password") {
        seteye(false);
      } else {
        setceye(false);
      }
      
    } else {
      _idv.setAttribute("type", "text");
      if(_id == "password") {
        seteye(true);
      } else {
        setceye(true);
      }
    }
  };

  const registerUser = async (data) => {
    if (isOTPVerified) {
      setIsRegistering(true);
      let registrationToast = toast.loading("Registration in progress...");
      api
        .post("/register_new_user", data)
        .then((res) => {
          toast.success(res?.data?.message, {
            duration: 5000,
            id: registrationToast,
          });
          resetForm();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message ?? error.message, {
            duration: 5000,
            id: registrationToast,
          });
        })
        .then(() => {
          setIsRegistering(false);
        });
    } else {
      toast.error("Please verify your mobile number first.", {
        duration: 5000,
        id: "VerifyMobileFirst",
      });
    }
  };

  const sendMobileVerificationCode = async () => {
    if (mobileNumber.length > 0) {
      setIsSendingOTP(true);
      let emailVerificationToast = toast.loading(
        "Sending mobile verification code."
      );
      api
        .post("send_mobile_verificaton_code", { mobile: mobileNumber })
        .then((res) => {
          setIsOTPSent(true);
          toast.success(res?.data?.message, {
            duration: 5000,
            id: emailVerificationToast,
          });
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.message ??
              error?.message ??
              "Sometjing went wrong.",
            { duration: 5000, id: emailVerificationToast }
          );
        })
        .then(() => {
          setIsSendingOTP(false);
        });
    } else {
      toast.error("Please enter mobile number.", { duration: 5000 });
    }
  };

  const verifyUserOTP = async () => {
    if (userOTP.length > 0) {
      setIsSendingOTP(true);
      let otpVerificationToast = toast.loading("Verifying verification code.");
      api
        .post("/verify_otp", { mobile: mobileNumber, otp: userOTP })
        .then((res) => {
          setIsOTPVerified(true);
          toast.success(res?.data?.message, {
            duration: 5000,
            id: otpVerificationToast,
          });
        })
        .catch((error) => {
          setIsOTPVerified(false);
          toast.error(error?.response?.data?.message ?? error.message, {
            duration: 5000,
            id: otpVerificationToast,
          });
        })
        .then(() => {
          setIsSendingOTP(false);
        });
    } else {
      toast.error("Please enter verification code.", {
        duration: 5000,
        id: "verificationFailed",
      });
    }
  };

  return (
    <form name="mobileRegistrationForm" onSubmit={handleSubmit(registerUser)} autoComplete="off">
      <div className="mb-2">
        <div className="">
          <div className="">
            <input
              type="text"
              className="form-control form-control-sm"
              id="mobile_number"
              autoComplete="off"
              placeholder="Mobile Number"
              {...register("mobile_number", {
                required: "Mobile number is required.",
              })}
              onChange={(e) => {
                setMobileNumber(e.target.value);
              }}
            />
            {/* <label for="mobile_number">Mobile Number</label> */}
          </div>
        </div>

        {errors?.mobile_number && (
          <div className="app-text-danger">{errors?.mobile_number?.message}</div>
        )}
      </div>
      <div className="mb-2">
        <div>
          <div className="mb-2">
            <input
              type="text"
              className={
                isOTPVerified === true
                  ? "form-control is-valid"
                  : isOTPVerified === false
                  ? "form-control is-invalid"
                  : "form-control"
              }
              id="mobile_verification_code"
              placeholder="Verification Code"
              {...register("mobileVerificationCode", {
                required: "Verification code is required.",
              })}
              onChange={(e) => {
                setUserOTP(e.target.value);
              }}
            />
            {/* <label for="mobile_verification_code">
              Mobile Verification Code
            </label> */}
          </div>
          
        </div>
        {!isOTPVerified && (
          <div className="">
            <div>
              {errors?.mobileVerificationCode && (
                <div className="app-text-danger">
                  {errors?.mobileVerificationCode?.message}
                </div>
              )}
            </div>
            <div className="d-grid">
              <Link
                to="#"
                className="btn btn-light"
                onClick={(e) => {
                  e.preventDefault();
                  sendMobileVerificationCode();
                }}
              >
                {isOTPSent ? "Resend OTP" : "Send OTP"}
              </Link>
              {isOTPSent && (
                <Link
                  to="#"
                  className="btn btn-success"
                  onClick={(e) => {
                    e.preventDefault();
                    verifyUserOTP();
                  }}
                >
                  Verify OTP
                </Link>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="mb-2">
        <div className="">
          <input
            type="text"
            className="form-control"
            id="name"
            autoComplete="off"
            readOnly
            onFocus={(e)=>{e.target.removeAttribute("readOnly")}}
            placeholder="Enter Your Name"
            {...register("name", {
              required: "You must specify a name",
              minLength: {
                value: 3,
                message: "name must have at least 3 characters",
              },
            })}
          />
          {/* <label for="name">Name</label> */}
        </div>
        {errors?.name && (
          <div className="app-text-danger">{errors?.name?.message}</div>
        )}
      </div>

      <div className="mb-2">
        {/* <label for="password">Password</label> */}
        <div className={`form-group`}>
          <div className="input-group">
        <input
          type="password"
          className="form-control"
          id="pass"
          autoComplete="off"
          readOnly
          onFocus={(e)=>{e.target.removeAttribute("readOnly")}}
          placeholder="Password"
          {...register("pass", {
            required: "You must specify a password",
            minLength: {
              value: 8,
              message: "Password must have at least 8 characters"
            }
          })}
        />
          <span className="input-group-text">
            <a
              href="#view_qr"
              className=""
              onClick={(e) => {
                hsPassword("pass");
              }}
            >
              {eye ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#666"
                  className="bi bi-eye-slash-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                  <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#666"
                  className="bi bi-eye-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                </svg>
              )}
            </a>
        </span>
        </div>
      </div>

        {errors?.password && (
          <div className="app-text-danger">{errors?.password?.message}</div>
        )}
      </div>

      <div className="mb-2">
        {/* <label for="confirm_password">Confirm Password</label> */}
        <div className={`form-group`}>
          <div className="input-group">
        <input
          type="password"
          className="form-control"
          id="c_pass"
          placeholder="Confirm Password"
          {...register("c_pass", {
            required: "Confirm password",
            validate: (value) =>
              value === password.current || "The passwords do not match"
          })}
        />
         <span className="input-group-text">
            <a
              href="#view_qr"
              className=""
              onClick={(e) => {
                hsPassword("c_pass");
              }}
            >
              {ceye ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#666"
                  className="bi bi-eye-slash-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                  <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#666"
                  className="bi bi-eye-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                </svg>
              )}
            </a>
        </span>
        </div>
      </div>

        {errors?.confirm_password && (
          <div className="app-text-danger">
            {errors?.confirm_password?.message}
          </div>
        )}
      </div>

      <div className="mb-2">
        <div className="">
          <input
          value={id}
            type="text"
            className="form-control"
            id="parent_ref_code"
            placeholder="Refferal Code"
            {...register("parent_ref_code")}
          />
          {/* <label for="parent_ref_code">Reffer</label> */}
        </div>
      </div>

      <div className="form-group form-check terms_and_policy-">
        <input
          type="checkbox"
          className="form-check-input"
          id="exampleCheck1"
          onChange={(e) => {
            setIsAgreed(e.target.checked);
          }}
          checked={isAgreed}
        />
        <label className="form-check-label" for="exampleCheck1">
         <span> I Agree The </span><a href="">Terms and policy</a>
         
         
        </label>
      </div>

      <div className="d-grid">
      <button
        type="submit"
        className={isAgreed ? "agree-- btn btn-warning btn-lg" : "btn btn-warning btn-lg"}
        disabled={!isAgreed}
      >
        {isRegistering ? (
          <>
            <span
             className="spinner-grow spinner-grow-sm me-1"
              role="status"
              aria-hidden="true"
            ></span>
            Registering...
          </>
        ) : (
          <>Register</>
        )}
      </button>
      </div>
      <label for="exampleCheck1" className="mt-2 regi_acc" >
                    <span>
                      Allready have an account{" "}
                      <Link to="/login"  className="register-txt">Sign In?</Link>
                    </span>
                    <br />
                  </label>
    </form>
  );
}
