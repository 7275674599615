// import { t } from "i18next";
import { createRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
// import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
//import toast from "react-hot-toast";
import { api } from "../../utils/api";
export default function EmailRegistration(props) {
  const [eye, seteye] = useState(false);
  const [ceye, setceye] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();
  const password = createRef(); //useRef({});
  password.current = watch("password", "");
  const [isAgreed, setIsAgreed] = useState(false);
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState();
  const [isRegistering, setIsRegistering] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [userOTP, setUserOTP] = useState("");
  const { id } = useParams();
  // const { t } = useTranslation();

  const resetForm = () => {
    document.forms["registrationForm"].reset();
    setIsAgreed(false);
    setIsSendingOTP(false);
    setIsOTPSent(false);
    setIsOTPVerified();
    setIsRegistering(false);
    setEmailAddress("");
    setUserOTP("");
  };

  const registerUser = async (data) => {
    if (isOTPVerified) {
      setIsRegistering(true);
      let registrationToast = toast.loading("Registration in progress...");
      api
        .post("/register_new_user", data)
        .then((res) => {
          toast.success(res?.data?.message, {
            duration: 5000,
            id: registrationToast
          });
          resetForm();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message ?? error.message, {
            duration: 5000,
            id: registrationToast
          });
        })
        .then(() => {
          setIsRegistering(false);
        });
    } else {
      toast.error("Please verify your email first.", {
        duration: 5000,
        id: "VerifyEmailFirst"
      });
    }
  };

  const hsPassword = (_id) => {
    var _idv = document.querySelector("#" + _id);
    if (_idv.getAttribute("type") == "text") {
      _idv.setAttribute("type", "password");
      if(_id == "password") {
        seteye(false);
      } else {
        setceye(false);
      }
      
    } else {
      _idv.setAttribute("type", "text");
      if(_id == "password") {
        seteye(true);
      } else {
        setceye(true);
      }
    }
  };

  const sendEmailVerificationCode = async () => {
    if (emailAddress.length > 0) {
      setIsSendingOTP(true);
      let emailVerificationToast = toast.loading(
        "Sending email verification code."
      );
      api
        .post("send_email_verification_code", {
          email: emailAddress
        })
        .then((res) => {
          setIsOTPSent(true);
          toast.success(res?.data?.message, {
            duration: 5000,
            id: emailVerificationToast
          });
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.message ??
              error?.message ??
              "Sometjing went wrong.",
            { duration: 5000, id: emailVerificationToast }
          );
        })
        .then(() => {
          setIsSendingOTP(false);
        });
    } else {
      toast.error("Please enter email address.", { duration: 5000 });
    }
  };

  const verifyUserOTP = async () => {
    if (userOTP.length > 0) {
      setIsSendingOTP(true);
      let otpVerificationToast = toast.loading("Verifying verification code.");
      api
        .post("/verify_otp", { email: emailAddress, otp: userOTP })
        .then((res) => {
          setIsOTPVerified(true);
          toast.success(res?.data?.message, {
            duration: 5000,
            id: otpVerificationToast
          });
        })
        .catch((error) => {
          setIsOTPVerified(false);
          toast.error(error?.response?.data?.message ?? error.message, {
            duration: 5000,
            id: otpVerificationToast
          });
        })
        .then(() => {
          setIsSendingOTP(false);
        });
    } else {
      toast.error("Please enter verification code.", {
        duration: 5000,
        id: "verificationFailed"
      });
    }
  };

  return (
    <form name="registrationForm" onSubmit={handleSubmit(registerUser)}>
      <div className="mb-2">
        {/* <label for="email">Email address</label> */}
        <input
          type="email"
          className="form-control form-control-sm"
          id="email"
          autoComplete="off"
          readOnly
          onFocus={(e)=>{e.target.removeAttribute("readOnly")}}
          placeholder="name@example.com"
          {...register("email", {
            required: "Email  is required.",
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          })}
          onChange={(e) => {
            setEmailAddress(e.target.value);
          }}
        />
      </div>

      {errors?.email && (
        <div className="app-text-danger">{errors?.email?.message}</div>
      )}
      {errors?.email?.type === "pattern" && (
        <div className="app-text-danger">Enter valid email address.</div>
      )}

      <div className="mb-2">
        <div className="mb-2">
          {/* <label for="email_verification_code">Email Verification Code</label> */}
          <input
            type="text"
            className={
              isOTPVerified === true
                ? "form-control is-valid"
                : isOTPVerified === false
                ? "form-control is-invalid"
                : "form-control"
            }
            id="email_verification_code"
            placeholder="OTP"
            {...register("emailVerificationCode", {
              required: "Verification code is required."
            })}
            onChange={(e) => {
              setUserOTP(e.target.value);
            }}
          />
        </div>
        {/* {!isOTPVerified && (
            <div className="align-self-center ms-1">
              <button
                className="btn btn-primary"
                type="button"
                onClick={(e) => {
                  isOTPSent ? verifyUserOTP() : sendEmailVerificationCode();
                }}
              >
                {isSendingOTP ? (
                  <>
                    <span
                      class="spinner-grow spinner-grow-sm me-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </>
                ) : isOTPSent ? (
                  "Verify"
                ) : (
                  <><i class="fi fi-rr-paper-plane"></i> Send</>
                )}
              </button>
            </div>
          )} */}

        {!isOTPVerified && (
          <div className="">
            <div>
              {errors?.emailVerificationCode && (
                <div className="app-text-danger">
                  {errors?.emailVerificationCode?.message}
                </div>
              )}
            </div>
            <div className="d-grid">
              <Link
                to="#"
                className="btn btn-light"                
                onClick={(e) => {
                  e.preventDefault();
                  sendEmailVerificationCode();
                }}
                disabled={isSendingOTP}
              >
                {isOTPSent ? "Resend OTP" : "Send OTP"}
              </Link>
              {isOTPSent && (
                <Link
                  to="#"
                  className=" fw-bold py-1 ms-2"
                  
                  onClick={(e) => {
                    e.preventDefault();
                    verifyUserOTP();
                  }}
                >
                  Verify OTP
                </Link>
              )}
            </div>
          </div>
        )}

        {/* {errors?.emailVerificationCode && (
          <div className="app-text-danger">
            {errors?.emailVerificationCode?.message}
          </div>
        )} */}
      </div>

      <div className="mb-2">
        {/* <label for="name">Name</label> */}
        <input
          type="text"
          className="form-control"
          id="name"
          placeholder="Enter Your Name"
          {...register("name", {
            required: "You must specify a name",
            minLength: {
              value: 3,
              message: "name must have at least 3 characters"
            }
          })}
        />
      </div>
      {errors?.name && (
        <div className="app-text-danger">{errors?.name?.message}</div>
      )}

      <div className="mb-2">
        {/* <label for="password">Password</label> */}
        <div className={`form-group`}>
          <div className="input-group">
        <input
          type="password"
          className="form-control"
          id="password"
          autoComplete="off"
          readOnly
          onFocus={(e)=>{e.target.removeAttribute("readOnly")}}
          placeholder="Password"
          {...register("password", {
            required: "You must specify a password",
            minLength: {
              value: 8,
              message: "Password must have at least 8 characters"
            }
          })}
        />
          <span className="input-group-text">
            <a
              href="#view_qr"
              className=""
              onClick={(e) => {
                hsPassword("password");
              }}
            >
              {eye ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#666"
                  className="bi bi-eye-slash-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                  <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#666"
                  className="bi bi-eye-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                </svg>
              )}
            </a>
        </span>
        </div>
      </div>

        {errors?.password && (
          <div className="app-text-danger">{errors?.password?.message}</div>
        )}
      </div>

      <div className="mb-2">
        {/* <label for="confirm_password">Confirm Password</label> */}
        <div className={`form-group`}>
          <div className="input-group">
        <input
          type="password"
          className="form-control"
          id="confirm_password"
          placeholder="Confirm Password"
          {...register("confirm_password", {
            required: "Confirm password",
            validate: (value) =>
              value === password.current || "The passwords do not match"
          })}
        />
         <span className="input-group-text">
            <a
              href="#view_qr"
              className=""
              onClick={(e) => {
                hsPassword("confirm_password");
              }}
            >
              {ceye ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#666"
                  className="bi bi-eye-slash-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                  <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#666"
                  className="bi bi-eye-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                </svg>
              )}
            </a>
        </span>
        </div>
      </div>

        {errors?.confirm_password && (
          <div className="app-text-danger">
            {errors?.confirm_password?.message}
          </div>
        )}
      </div>

      <div className="mb-2">
        {/* <label for="parent_ref_code">Reffer</label> */}
        <input
          value={id}
          type="text"
          className="form-control"
          id="parent_ref_code"
          placeholder="Referral Code"
          {...register("parent_ref_code")}
        />
      </div>

      <div className="form-group form-check terms_and_policy--">
        <input
          type="checkbox"
          className="form-check-input"
          id="exampleCheck1"
          onChange={(e) => {
            setIsAgreed(e.target.checked);
          }}
          checked={isAgreed}
        />
        <label className="form-check-label" for="exampleCheck1">
          <span> I Agree The </span>{" "}
          <a href="#">Terms and policy</a>        
         
        </label>
      </div>

     <div className="d-grid">
     <button
        type="submit"
        className={isAgreed ? "btn btn-warning btn-lg" : "btn btn-warning btn-lg"}
        disabled={!isAgreed}
      >
        {isRegistering ? (
          <>
            <span
              className="spinner-grow spinner-grow-sm me-1"
              role="status"
              aria-hidden="true"
            ></span>
            Registering...
          </>
        ) : (
          <>Register</>
        )}
      </button>
     </div>
      <label for="exampleCheck1" className="mt-2 regi_acc">
        <span>
          Already have an account{" "}
          <Link to="/login" className="info-text">
            Sign In?
          </Link>
        </span>
        <br />
      </label>
    </form>
  );
}
