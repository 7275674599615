import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const WalletTab = () => {
  const { total_inr_price } = useSelector((state) => state?.exchange?.value);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activetab, setActiveTab] = useState(pathname);
  return (
    <>
      <div className="">
        <div className="page_title">
          <div className="container">
            <div className="page_title_inner">
              <div className="row align-items-center">
                <div className="col-lg-9 col-12">
                  {" "}
                  <h1>Wallet</h1>
                </div>
                <div className="col-lg-3 text-end">
                  <div className="col-lg-12 funds"></div>
                  <span className="fs-14 fw-normal">Estimated Balance : <span className="badge bg-info fs-14 fw-normal">{total_inr_price}</span></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-4 page_section">
          <div className="col-lg-12 col-12 m-auto">
            <ul
              className="nav nav-pills mb-4 fs-14"
              id="myTab"
              role="tablist"
              style={{ background: "0px" }}
            >
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activetab === "/fund" ? "active" : ""
                  }`}
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  // background="#1C2574"
                  // style={{backgroundColor:"#1C2574",color:"grey"}}
                  onClick={() => {
                    navigate("../fund");
                  }}
                >
                 Fiat and Spot
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activetab === "/funding" ? "active" : ""
                  }`}
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  // background="#1C2574"
                  // style={{backgroundColor:"#1C2574",color:"grey"}}
                  onClick={() => {
                    navigate("../funding");
                  }}
                >
                  Funding
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activetab === "/deposit" ? "active" : ""
                  }`}
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  defaultChecked=""
                  // style={{backgroundColor:"#1C2574"}}
                  onClick={() => {
                    navigate("../deposit");
                  }}
                >
                  Deposit
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activetab === "/withdraw" ? "active" : ""
                  }`}
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#contact"
                  type="button"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                  // style={{backgroundColor:"#1C2574"}}
                  onClick={() => {
                    navigate("../withdraw");
                  }}
                >
                  Withdrawal
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    activetab === "/transationhistory" ? "active" : ""
                  }`}
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#transaction"
                  type="button"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                  // style={{backgroundColor:"#1C2574"}}
                  onClick={() => {
                    navigate("../transationhistory");
                  }}
                >
                  Transaction History
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletTab;
