import React from "react";

const privacyControl = () => {
  return (
    <div
      class="modal fade"
      id="privacy"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="privacyLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="privacydropLabel">
              Privacy
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div
              className="col-12"             
            >
              <div className="">              
                <article>
                  <div className="col-md-12">
                    <div className="sanfont ">
                      <p className="fs-18">
                        You control your data, and we respect that.
                      </p>
                      <div className="mb-2">
                      <h5> For requests regarding</h5>
                      </div>
                      <ul className="mb-2 d-flex justify-content-between fs-14"  >
                        <li className="ml-2 mr-2 ">
                          <i
                            className="fa fa-circle  mr-2"
                            aria-hidden="true"
                          ></i>{" "}
                          Copy of your data
                        </li>
                        <li className="ml-2 mr-2 ">
                          <i
                            className="fa fa-circle  mr-2"
                            aria-hidden="true"
                          ></i>{" "}
                          Data export
                        </li>
                        <li className="ml-2 mr-2 ">
                          <i
                            className="fa fa-circle  mr-2"
                            aria-hidden="true"
                          ></i>{" "}
                          Data correction
                        </li>
                      </ul>
                      <div className="fs-14">
                        
                        Please reach out to us. Our team will be happy to help
                        you out.
                      </div>
                      <a
                        href="#support"
                        className="btn btn-warning mt-2 mb-2 bold"
                      >
                        {" "}
                        Contact Us
                      </a>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default privacyControl;
