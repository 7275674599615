import { t } from "i18next";
// import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
import EmailRegistration from "./auth/EmailRegistration";
import MobileRegistration from "./auth/MobileRegistration";
import {useNavigate } from "react-router-dom";
import { useSelector} from "react-redux";
import { useEffect} from "react";


export default function Register(props) {
  const navigate = useNavigate();

  const { isLoggedIn } = useSelector((state) => state?.user?.value);

  useEffect(() => {
    if (isLoggedIn) {
      console.log("cmae in loguse::");
      navigate("../fund", { replace: true });
    }
  }, []);
 
  return (
    <div className="container">
      {/* {showSuccess && (
        <div class="row justify-content-center">
          <div className="col-md-6">
            <div className="card card-body border-0 text-center shadow-sm my-5">
              <h1 className="font-dongle fs-1 my-0 py-0">Congratulations</h1>
              <p className="font-comforta py-0 mb-3">
                Registration successful, enjoy your journey and keep trading.{" "}
                <Link to="/login" className="text-muted">
                  Login
                </Link>
              </p>
            </div>
          </div>
        </div>
      )}

      */}

      {
        <div className="login_area page_minheight">
          <div className="row">
            <div className="col-lg-5 m-auto">  
             <div className="mb-3 m-auto col-md-6 col-12">
              <ul className="nav nav-pills" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                      style={{height:"100%"}}
                    >
                      <i className="fa fa-envelope me-2"></i>Via Email
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      <i className="fa fa-phone me-2"></i> Via Phone
                    </button>
                  </li>
                </ul>  
              </div>        
              <div className="login_area_block-- card">              

                <div className="tab-content login_form" id="myTabContent">
                <h1 className="font-dongle fs-1 my-0 py-0 mb-3">{t("register")}</h1>
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <EmailRegistration />
                  </div>
                 
                  <div
                    class="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <MobileRegistration />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
