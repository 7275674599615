import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { N_getUserProfile } from "../../utils/api_functions";
import {
  N_sendOTPMobile,
  N_verifyOTPMobile,
  N_verifyOTPEmail,
  N_sendOTPEmail
} from "../../utils/api_functions";
import toast from "react-hot-toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
const AccountTab = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activetab, setActiveTab] = useState(pathname);
  const { userInfo } = useSelector((state) => state?.user?.value);
  const [profile, setProfile] = React.useState({});
  // mobile
  const [otploader, setOtpLoader] = useState(false);
  const [isOtpSend, setOtpSend] = useState(false);
  const [mobile, setMobile] = useState();
  const [isVerified, setIsVerified] = useState(0);
  const [disable, setDisable] = useState(true);
  const [ctime, setctime] = useState("01:00");
  const [otp, setOTP] = useState();
  const [loading, setLoading] = useState(false);
  const [disableSend, setDisableSend] = React.useState(false);
  const [email, setEmail] = useState();
  const [copied, setCopied] = useState(false);
  const { switch_theme } = useSelector((state) => state?.exchange?.value);

  console.log(isOtpSend, "isOtpSend isOtpSend");
  const otpCountdown = () => {
    let duration = 60;
    let timer = duration,
      minutes,
      seconds;
    const tint = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setctime(minutes + ":" + seconds);

      if (--timer < 0) {
        clearInterval(tint);
        setOtpSend(false);
      }
    }, 1000);
  };

  const userid = userInfo.user_id;
  useEffect(() => {
    N_getUserProfile(userid).then((res) => {
      setProfile(res.data.params.profile_info);
      console.log(res.data.params.profile_info, "res.data.params.profile_info");
    });
  }, [userid]);

  return (
    <div className="">
      <div className="page_title">
        <div className="container">
          <div className="page_title_inner">
            <div className="row">
              <div className="col-lg-9 col-12">
                {" "}
                <h1>Account</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-3">
        <div class="row align-items-center">
          <div class="col-lg-3 col-12 mb-2">
            <div class="card bg-soft-info">
              <div class="card-body py-2">
                <div class="d-flex justify-content-between align-items-end">
                  <div class="adv_serch">
                    <div class="dropdown">
                      <div class="text-muted">Name</div>
                    </div>

                    <span className=""> {profile.name}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-12 mb-2">
            <div class="card bg-soft-info">
              <div class="card-body py-2">
                <div class="d-flex justify-content-between align-items-end">
                  <div class="adv_serch">
                    <div class="dropdown">
                      <div class="text-muted">Email</div>
                    </div>
                    <span>
                      {profile.email == false ? (
                        <button
                          type="button"
                          className="UpdateButton"
                          data-bs-toggle="modal"
                          data-bs-target="#emilModal"
                          style={{
                            background:
                              switch_theme == "dark"
                                ? "rgba(27,205,180,.33"
                                : "#1bcd77",
                            color: "white",
                            border: "none"
                          }}
                          onClick={() => {
                            console.log("Update Mobile number");
                          }}
                        >
                          Update
                        </button>
                      ) : (
                        profile.email
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-12 mb-2">
            <div class="card bg-soft-info">
              <div class="card-body py-2">
                <div class="d-flex justify-content-between align-items-end">
                  <div class="adv_serch">
                    <div class="dropdown">
                      <div class="text-muted">Mobile No </div>
                    </div>
                    <span>
                      {profile.mobile_number == false ? (
                        <button
                          type="button"
                          className="UpdateButton"
                          data-bs-toggle="modal"
                          data-bs-target="#numbermodal"
                          style={{
                            background:
                              switch_theme == "dark"
                                ? "rgba(27,205,180,.33"
                                : "#1bcd77",
                            color: "white",
                            border: "none",
                            padding: "0 4px",
                            borderRadius: "4px"
                          }}
                          onClick={() => {}}
                        >
                          Update
                        </button>
                      ) : (
                        profile.mobile_number
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-12 mb-2">
            <div
              className=""
              data-clipboard-target="#refUrl"
              data-success="Copied"
              data-text="Copy Link"
            >
              <CopyToClipboard
                text={`https://elucks.exchange/register/${profile.self_ref_code}`}
                onCopy={() => {
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 1000);
                }}
              >
                <div>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control text-dark"
                      id="refUrl"
                      readOnly
                      value={`https://elucks.exchange/register/${
                        profile.self_ref_code ? profile.self_ref_code : ""
                      }`}
                    />
                    <span className="input-group-text bg-info text-white">
                      {copied ? (
                        <span className="fs-10 small">Copied</span>
                      ) : (
                        <i className="fa fa-copy"></i>
                      )}
                    </span>
                  </div>
                </div>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
      <div className="col-lg-12 col-12 m-auto spacer">
        
        <ul class="nav nav-pills" id="myTab" role="tablist">
            <li className="nav-item me-1" role="presentation">
              <button
                className={`nav-link ${
                  activetab === "/Security_Settings" ? "active" : ""
                }`}
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                onClick={() => {
                  navigate("../Security_Settings");
                }}
              >
                Security Settings
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  activetab === "/Referral_Bonus" ? "active" : ""
                }`}
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                onClick={() => {
                  navigate("../Referral_Bonus");
                }}
              >
                Referral Bonus
              </button>
            </li>
          </ul>
        </div>
      </div>

      {/* <!-- Modal mobile number --> */}
      <div
        class="modal fade"
        id="numbermodal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Veryfication
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="signupform-control g_color">
                <label htmlFor="user_mobile" className="lableclass2">
                  Mobile Number:
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="user_mobile"
                    value={mobile}
                    maxLength={10}
                    onChange={(e) =>
                      setMobile(
                        e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1")
                      )
                    }
                    placeholder="Enter your 10 digit mobile number"
                  />
                  <div className="input-group-append">
                    {!isOtpSend ? (
                      <button
                        className="btn bg-transparent btn-outline-light text-info"
                        type="button"
                        // disabled={disableSend}
                        onClick={() => {
                          if (mobile?.length === 10) {
                            setOtpLoader(true);
                            N_sendOTPMobile(userid, mobile).then((d) => {
                              if (d.data.status == 200) {
                                toast.success(d.data.message);
                                setDisable(false);
                                setOtpSend(true);
                                otpCountdown();
                              } else {
                                toast.error(d.data.message);
                              }
                              setOtpLoader(false);
                            });
                          } else {
                            toast.error("Please Enter valid Mobile number");
                          }
                        }}
                        id="button-addon2"
                      >
                        {otploader ? (
                          <span className="loading-icon fas fa-spinner fa-spin mr-2"></span>
                        ) : (
                          "Send"
                        )}
                      </button>
                    ) : (
                      <button
                        type="button"
                        id="button-addon2"
                        className="btn btn-outline-info btn-outline-light text-info"
                      >
                        {isOtpSend ? ctime : null}
                      </button>
                    )}
                  </div>
                </div>

                {/* otp verication */}

                <div className="signupform-control mt-3">
                  <label htmlFor="user_otp " className="lableclass2">
                    OTP
                  </label>
                  <input
                    type="text"
                    id="otp"
                    className="form-control"
                    maxLength={6}
                    disabled={disable}
                    value={otp}
                    onChange={(e) =>
                      setOTP(
                        e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1")
                      )
                    }
                    required="required"
                    placeholder="Enter 6 digit otp"
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={isVerified ? "disabled" : ""}
                  onClick={() => {
                    if (!isNaN(otp) && otp?.length === 6) {
                      setLoading(true);
                      N_verifyOTPMobile(userid, otp).then((d) => {
                        setLoading(false);
                        const closeBtn =
                          document.getElementsByClassName("btn-close")[0];
                        closeBtn.click();
                        if (d?.data?.status == 200) {
                          setIsVerified(1);
                          window.location.reload(true);
                          toast.success(d.data.message);
                        } else {
                          toast.error(d.data.message);
                        }
                      });
                    } else {
                      toast.error("invalid Otp !");
                    }
                  }}
                >
                  {loading ? (
                    <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                  ) : null}
                  <span id="reg">{isVerified ? "Verified" : "Verify OTP"}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* model Email Id  */}
      <div
        class="modal fade"
        id="emilModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Veryfication
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="signupform-control g_color">
                <label htmlFor="user_mobile" className="lableclass2">
                  Email Id :
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="user_mobile"
                    value={email}
                    maxLength={25}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter Your Email Id"
                  />
                  <div className="input-group-append">
                    {!isOtpSend ? (
                      <button
                        className="btn bg-transparent btn-outline-light text-info"
                        type="button"
                        // disabled={disableSend}
                        onClick={() => {
                          if (email) {
                            setOtpLoader(true);
                            N_sendOTPEmail(userid, email).then((d) => {
                              if (d.data.status == 200) {
                                toast.success(d.data.message);
                                setDisable(false);
                                setOtpSend(true);
                                otpCountdown();
                                setTimeout(() => {});
                              } else {
                                toast.error(d.data.message);
                              }
                              setOtpLoader(false);
                            });
                          } else {
                            toast.error("Please Enter Valid Email Id");
                          }
                        }}
                        id="button-addon2"
                      >
                        {otploader ? (
                          <span className="loading-icon fas fa-spinner fa-spin mr-2"></span>
                        ) : (
                          "Send"
                        )}
                      </button>
                    ) : (
                      <button
                        type="button"
                        id="button-addon2"
                        className="btn btn-outline-info btn-outline-light text-info"
                      >
                        {isOtpSend ? ctime : null}
                      </button>
                    )}
                  </div>
                </div>

                {/* otp verication */}

                <div className="signupform-control mt-3">
                  <label htmlFor="user_otp " className="lableclass2">
                    OTP
                  </label>
                  <input
                    type="text"
                    id="otp"
                    className="form-control"
                    maxLength={6}
                    disabled={disable}
                    value={otp}
                    onChange={(e) =>
                      setOTP(
                        e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1")
                      )
                    }
                    required="required"
                    placeholder="Enter 6 digit otp"
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={isVerified ? "disabled" : ""}
                  onClick={() => {
                    if (!isNaN(otp) && otp?.length === 6) {
                      setLoading(true);
                      N_verifyOTPEmail(userid, otp).then((d) => {
                        setLoading(false);
                        const closeBtne =
                          document.getElementsByClassName("btn-close")[0];
                        closeBtne.click();
                        if (d?.data?.status == 200) {
                          setIsVerified(1);
                          window.location.reload(true);
                          toast.success(d.data.message);
                        } else {
                          toast.error(d.data.message);
                        }
                      });
                    } else {
                      toast.error("invalid Otp !");
                    }
                  }}
                >
                  {loading ? (
                    <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                  ) : null}
                  <span id="reg">{isVerified ? "Verified" : "Verify OTP"}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountTab;
