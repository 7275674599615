import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  N_panVerify,
  N_checkKYCStatus,
  N_sendAADHAROTPMobile,
  N_verifyAADHAROTPMobile
} from "../../utils/api_functions";
const Kyform = () => {
  const navigate = useNavigate();
  const [type_of_kyc, setTypeofKyc] = useState("Individual");
  const [ispanverify, setPanVerify] = useState(false);
  const [isverifypan, isVerifyPan] = useState(false);
  const [disableSend, setDisableSend] = useState(false);
  const [otploader, setOtpLoader] = useState(false);
  const [client_id, setClientId] = useState();
  const [aadhar_no, setAadharNo] = useState();
  const [disable, setDisable] = useState(true);
  const [isOtpSend, setOtpSend] = useState(false);
  const [ctime, setctime] = useState("10:00");
  const [mobile, setMobile] = useState();
  const [pan_no, setPanNo] = useState();
  const [otp, setOTP] = useState();
  const [visibl, setVisiblity] = useState("h");
  const [display, setdisplay] = useState(false);
  const [personalData, setPersnalData] = useState();
  const [loading, setLoading] = useState(false);
  const [aadharVerify, setAadharVerify] = useState(false);
  const { userInfo } = useSelector((state) => state?.user?.value);
  const userid = userInfo.user_id;

  useEffect(() => {
    N_checkKYCStatus(userid).then((d) => {
      let res = d.data;
      if (res.params.kyc_status == 1 || res.params.kyc_status == -1) {
        navigate("../exchange/btc-inr", { replace: true });
      }
    });
  }, []);

  const otpCountdown = () => {
    let duration = 600;
    // const display = document.getElementById("#timer");
    let timer = duration,
      minutes,
      seconds;
    const tint = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setctime(minutes + ":" + seconds);

      if (--timer < 0) {
        // timer = duration;
        clearInterval(tint);
        setOtpSend(false);
      }
    }, 1000);
  };

  return (
    <>
      {/* <Kychead/> */}
      <div class="page_title">
        <div class="container">
          <div class="page_title_inner">
            <div class="row">
              <div class="col-lg-9 col-12">
                {" "}
                <h1>KYC Verification</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container page_minheight spacer mb-4">
        <div className="row">
          <div className="col-lg-5 m-auto">
            <div class="card">
              <div class="card-body">
                <div className="">
                  <h4>Identify Document</h4>

                  <div className="mb-3 mt-3">
                    <label className="fs-13 mb-1">Type of KYC</label>
                    <select
                      id="type_of_kyc"
                      name="type_of_kyc"
                      className="form-control selectclas"
                      data-role="select-dropdown"
                      onChange={(e) => {
                        setTypeofKyc(e.target.value);
                      }}
                    >
                      <option value="Individual">Individual</option>
                      <option value="Corporate">Corporate</option>
                    </select>
                  </div>
                  <div id="verify_pan">
                    <div className="g_color">
                      <label htmlFor="user_mobile" className="fs-13 mb-1">
                        PAN Number
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="pan_no"
                          name="pan_no"
                          value={pan_no}
                          maxLength={10}
                          minLength={10}
                          onChange={(e) => setPanNo(e.target.value)}
                          placeholder="Enter your PAN number"
                        />
                        <div className="input-group-text bg-info text-white">
                          {!ispanverify ? (
                            <button
                              className="bg-transparent text-white"
                              type="button"
                              style={{ border: "none" }}
                              disabled={disableSend}
                              onClick={() => {
                                if (pan_no?.length === 10) {
                                  isVerifyPan(true);
                                  N_panVerify(pan_no, userid).then((data) => {
                                    let d = data.data;
                                    isVerifyPan(false);
                                    console.log("ddd", d);
                                    if (d.status == 200) {
                                      toast.success(d.message);
                                      setPanVerify(true);
                                    } else {
                                      toast.error(d.message);
                                      setPanNo("");
                                      document.getElementById(
                                        "pan_id"
                                      ).style.display = "block";
                                    }
                                  });
                                } else {
                                  toast.error("Please Enter valid PAN number");
                                }
                              }}
                              id="button-addon2"
                            >
                              {isverifypan ? (
                                <span className="loading-icon fas fa-spinner fa-spin mr-2"></span>
                              ) : (
                                "Verify"
                              )}
                            </button>
                          ) : (
                            <button
                              type="button"
                              id="button-addon2"
                              disabled="disabled"
                              className="bg-transparent border-0 text-white"
                            >
                              Verified
                            </button>
                          )}
                        </div>
                      </div>
                      <div
                        id="pan_id"
                        className="mt-2"
                        style={{ display: "none" }}
                      >
                        If Your Pan is Not Valid then{" "}
                        <Link to="/manual-Kyc">Click here</Link> to fill Manual
                        Record
                        <p>
                          <b
                            style={{ fontSize: "15px" }}
                            className=" text-danger"
                          >
                            Note:
                          </b>
                          <strong
                            style={{ fontSize: "12px", fontWeight: "500" }}
                          >
                            &nbsp;&nbsp;&nbsp;If you fill kyc manually, then you
                            have to wait for 72 hours for KYC verification.
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* aadhar verification */}

                  <div
                    id="verify_aadhar"
                    className={ispanverify ? "" : "d-none"}
                  >
                    <div className="mb-3 mt-3 g_color">
                      <label htmlFor="user_aadhar" className="text-capitalised">
                      Aadhar Number:
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="user_aadhar"
                          name="user_aadhar"
                          value={aadhar_no}
                          maxLength={12}
                          minLength={12}
                          onChange={(e) =>
                            setAadharNo(
                              e.target.value
                                .replace(/[^0-9.]/g, "")
                                .replace(/(\..*?)\..*/g, "$1")
                            )
                          }
                          
                          placeholder="Enter your Aadhar number"
                        />
                        <span className="input-group-text">
                          {!isOtpSend ? (
                            <button
                              className="bg-transparent border-0"
                              type="button"
                              disabled={disableSend}
                              onClick={() => {
                                if (aadhar_no?.length === 12) {
                                  setOtpLoader(true);
                                  N_sendAADHAROTPMobile(aadhar_no, userid).then(
                                    (d) => {
                                      console.log(d);
                                      if (d.data.status == 200) {
                                        toast.success(d.data.message);
                                        setClientId(d.data.result);
                                        setDisable(false);
                                        setOtpSend(true);
                                        otpCountdown();
                                      } else {
                                        toast.error(d.data.message);
                                        setAadharNo("");
                                        document.getElementById(
                                          "pan_id"
                                        ).style.display = "block";
                                      }
                                      setOtpLoader(false);
                                    }
                                  );
                                } else {
                                  toast.error(
                                    "Please Enter valid AADHAR number"
                                  );
                                }
                              }}
                              id="button-addon2"
                            >
                              {otploader ? (
                                <span className="loading-icon fas fa-spinner fa-spin mr-2"></span>
                              ) : (
                                "Send"
                              )}
                            </button>
                          ) : (
                            <button
                              type="button"
                              id="button-addon2"
                              disabled="disabled"
                              className="border-0 bg-transparent text-danger"
                            >
                              {isOtpSend ? ctime : null}
                            </button>
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="my-3">
                      <label
                        htmlFor="user_mobile"
                        for="validationCustom05"
                        class="text-capitalised"
                      >
                        Mobile Number
                      </label>
                      <input
                        type="text"
                        name="user_mobile"
                        id="user_mobile validationCustom05"
                        className="form-control bg-white"
                        maxLength={10}
                        disabled={disable}
                        value={mobile}
                        onChange={(e) =>
                          setMobile(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1")
                          )
                        }
                        required="required"
                        placeholder="Enter your mobile number"
                       
                      />
                      <small></small>
                    </div>
                    <div
                      className={`mb-3 g_color ${
                        aadharVerify ? "h" : "vis"
                      }`}
                    >
                      <label
                        htmlFor="user_otp"
                        for="validationCustom05"
                        class="form-label text-capitalised"
                      >
                        OTP
                      </label>
                      <input
                        type="text"
                        name="user_otp"
                        id="user_otp"
                        className="form-control bg-white"
                        maxLength={6}
                        disabled={disable}
                        value={otp}
                        onChange={(e) =>
                          setOTP(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1")
                          )
                        }
                       
                        required="required"
                        placeholder="Enter 6 digit otp"
                      />

                      <small></small>
                    </div>
                    <button
                      type="button "
                      id="aadhar_otp"
                      className="reg_btn btn btn-warning mb-3 w-100"
                      onClick={() => {
                        if (
                          !isNaN(otp) &&
                          !isNaN(mobile) &&
                          otp?.length === 6 &&
                          mobile?.length === 10
                        ) {
                          setLoading(true);
                          N_verifyAADHAROTPMobile(
                            client_id,
                            mobile,
                            otp,
                            userid
                          ).then((d) => {
                            console.log(d);
                            setLoading(false);
                            if (d.data.status == 200) {
                              setdisplay(true);
                              console.log(d.data.result);
                              setPersnalData(d.data.result);
                              setAadharVerify(true);
                              document.getElementById(
                                "aadhar_otp"
                              ).style.display = "none";
                              toast.success(d.data.message);
                            } else {
                              toast.error(d.data.message);
                            }
                          });
                        } else {
                          toast.error("invalid input !");
                        }
                      }}
                    >
                      {loading ? (
                        <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                      ) : null}
                      <span id="reg">Verify OTP</span>
                    </button>
                  </div>
                  <div id="personal_info" className={display ? "" : "d-none"}>
                    <div>
                      <label htmlFor="" className="mt-1 mb-3 text-primary fs-6">
                        PERSONAL INFO
                      </label>
                    </div>
                    <div className="signupform-control">
                      <div className="row">
                       
                        <div className="col-lg-12 text-center mb-3">
                          <img
                            src={`data:image/png;base64,${personalData?.profile_image}`}
                            height="120px"
                            width="120px"
                            id="aadhar_selfie"
                            name="aadhar_selfie"
                            alt="adhar pic"
                            className="rounded p-1 border bg-light"
                          />
                        </div>
                        <div className="col-lg-12">
                          <label
                            htmlFor="user_fname"
                            className="text-capitalize"
                          >
                            NAME 
                          </label>
                          <input
                            type="text"
                            name="user_name"
                            readOnly
                            className="form-control "
                            id="user_name"
                            value={personalData?.full_name}
                          />
                        </div>
                        <div className="col-lg-6">
                          <div className="signupform-control">
                            <label
                              htmlFor="gender"
                              className="text-capitalised"
                            >
                              GENDER *
                            </label>
                            <input
                              type="text"
                              name="gender"
                              className="form-control"
                              id="gender"
                              readOnly
                              value={
                                personalData?.gender == "M" ? "MALE" : "FEMALE"
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="signupform-control">
                            <label
                              htmlFor="user_dob"
                              className="text-capitalised"
                            >
                              DATE OF BIRTH *
                            </label>
                            <input
                              type="date"
                              name="user_dob"
                              id="user_dob"
                              readOnly
                              className="form-control"
                              value={personalData?.dob}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="signupform-control">
                            <label
                              htmlFor="user_address"
                              className="text-capitalised"
                            >
                              ADDRESS *
                            </label>
                            <textarea
                              className="form-control"
                            
                              id="user_address"
                              name="user_address"
                              readOnly
                              rows="2"
                              value={
                                personalData?.address.house +
                                " " +
                                personalData?.address.vtc +
                                " " +
                                personalData?.address.po +
                                " " +
                                personalData?.address.landmark +
                                " " +
                                personalData?.address.loc
                              }
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="signupform-control">
                            <label
                              htmlFor="country"
                              className="text-capitalised"
                            >
                              COUNTRY *
                            </label>
                            <input
                              type="text"
                              name="country"
                              className="form-control"
                              id="country"
                              readOnly
                              value={personalData?.address.country}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          <div className="signupform-control">
                            <label htmlFor="state" className="text-capitalised">
                              STATE *
                            </label>
                            <input
                              type="text"
                              name="state"
                              className="form-control"
                              id="state"
                              readOnly
                              value={personalData?.address.state}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="signupform-control">
                            <label
                              htmlFor="country"
                              className="text-capitalised"
                            >
                              CITY *
                            </label>
                            <input
                              type="text"
                              name="city"
                              className="form-control"
                              id="city"
                              readOnly
                              value={personalData?.address.dist}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          {" "}
                          <div className="signupform-control">
                            <label
                              htmlFor="user_pincode"
                              className="text-capitalize"
                            >
                              PINCODE *
                            </label>
                            <input
                              type="text"
                              name="user_pincode"
                              className="form-control"
                              id="user_pincode"
                              readOnly
                              value={personalData?.zip}
                            />
                          </div>
                        </div>
                       <div className="d-grid">
                        <button
                          type="button"
                          id="btn_submit"
                          className="btn btn-warning"
                          onClick={() => {
                            navigate("../exchange/btc-inr", { replace: true });
                          }}
                        >
                          {loading ? (
                            <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                          ) : null}
                          <span id="reg">Submit</span>
                        </button>
                       </div>
                      </div>
                    </div>
                  </div>
                  {/* aadhar verification */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Kyform;
