import React, { useEffect } from "react";
import { useState } from "react";
import { api } from "../utils/api";

const Fees = () => {
  const [data, setData] = useState();

  useEffect(()=>{
    api.get('get-fees')
    .then((d)=>{
      let data = d.data;
      if(data.status == 200){
        setData(data.sCurrency)
      }
    })
  }, [])
  return (
    <div style={{ paddingTop: "100px" }}>
      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="text-center">
                <h2> TRADING, DEPOSIT &amp; WITHDRAWAL FEES </h2>
                <p>
                  <div class="alert alert-warning" role="alert">
                    <p style={{ float: "left" }}>Important</p>
                    <br /> <br />
                    From 1 July 2022, TDS (Tax Deducted at Source) of 1% (5% in
                    exceptional cases) of the trade value will be deducted on
                    each trade as per the Government’s guidelines issued on
                    Virtual Digital Asset (VDA) transfers. This TDS can be
                    claimed while filing your ITR.
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="wallet_blue">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mt-5">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Deposit &amp; Withdrawal Fees
                  </button>
                </li>
                {/* <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Trading Fees
                  </button>
                </li> */}
              </ul>
              <div class="fees-table fees-info-table table-responsive mt-4">
                <table class="table" style={{ color: "#fff" }}>
                  <thead>
                    <tr>
                      <th className="text-bold text-center">Asset</th>
                      <th class="text-center">Network</th>
                      <th>Withdraw Fee</th>
                      <th>Min. Withdraw Amount</th>
                      <th>Max. Withdraw Amount</th>
                      <th>Deposit</th>
                      <th>Withdraw</th>
                    </tr>
                  </thead>
                  <tbody>
                  {data && data.map((item)=>{
                    return(
                      <tr>
                      <td class="text-bold text-center" rowspan="1">
                        {item.name} ({item.symbol})
                      </td>
                      <td class="text-center">{item.blockchain?(item.blockchain).toUpperCase()+" ("+item.contract_type+")":'N/A'}</td>
                      <td>{item.withdrawal_fee}{" "}{item.symbol}</td>
                      <td>{item.min_withdraw_limit}{" "}{item.symbol}</td>
                      <td>{item.max_withdraw_limit}{" "}{item.symbol}</td>
                      <td>
                        <span class="text-bold tag-green">{item.is_deposite?"Enabled":"disabled"}</span>
                      </td>
                      <td>
                        <span class="text-bold tag-green">{item.is_withdrawal?"Enabled":"disabled"}</span>
                      </td>
                    </tr>
                    );
                  })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fees;
