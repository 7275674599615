export function checkEmail(email) {
    const emailToValidate = email ? email : "";
    const emailRegexp =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    const res = emailRegexp.test(emailToValidate);
    return res;
  }
  
  
  export function checkPhone(phone) {
    const phoneToValidate = phone ? phone : "";
    const phoneRegexp = /^[0-9]{10}$/;
    const res = phoneRegexp.test(phoneToValidate);
    return res;
  }
  
  
  
  export function checkName(name) {
    const nameToValidate = name ? name : "";
    const nameRegexp = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
    const res = nameRegexp.test(nameToValidate);
    return res;
  }
  
  export function checkDob(dob) {
    const dobToValidate = dob ? dob : "";
    const dobRegexp =
      /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
    const res = dobRegexp.test(dobToValidate);
    return res;
  }
  
  export function checkUPI(upi) {
    return  upi ? true : false;
  }
  
  export function checkPassword(password) {
    const passwordToValidate = password ? password : "";
    const passwordRegexp =
      /(?=^.{8,15}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[0-9])(?=.*[@_#*&.])(?=.*[a-z]).*$/;
    const res = passwordRegexp.test(passwordToValidate);
    return res;
  }
  
  export function checkOtp(otp) {
    const otpToValidate = otp ? otp : "";
    const otpRegexp = /^[0-9]*$/;
    const res = otpRegexp.test(otpToValidate);
    return res;
  }
  
  export function sortByPair(data, asc = true, setData) {
    let result;
    if (asc)
      result = data.sort((a, b) => {
        let x = a.symbol.toLowerCase();
        let y = b.symbol.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
    else
      result = data.sort((a, b) => {
        let x = a.symbol.toLowerCase();
        let y = b.symbol.toLowerCase();
        if (x < y) {
          return 1;
        }
        if (x > y) {
          return -1;
        }
        return 0;
      });
    setData(result);
  }
  export function sortByVol(data, asc = true, setData) {
    let result;
    if (asc)
      result = data.sort((a, b) => {
        let x = a.volume_24h;
        let y = b.volume_24h;
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
    else
      result = data.sort((a, b) => {
        let x = a.volume_24h;
        let y = b.volume_24h;
        if (x < y) {
          return 1;
        }
        if (x > y) {
          return -1;
        }
        return 0;
      });
    setData(result);
  }
  export function sortByPrice(data, asc = true, setData) {
    let result;
    if (asc)
      result = data.sort((a, b) => {
        let x = a.current_price;
        let y = b.current_price;
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
    else
      result = data.sort((a, b) => {
        let x = a.current_price;
        let y = b.current_price;
        if (x < y) {
          return 1;
        }
        if (x > y) {
          return -1;
        }
        return 0;
      });
    setData(result);
  }
  export function sortByChange(data, asc = true, setData) {
    let result;
    if (asc)
      result = data.sort((a, b) => {
        let x = a.direction;
        let y = b.direction;
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
    else
      result = data.sort((a, b) => {
        let x = a.direction;
        let y = b.direction;
        if (x < y) {
          return 1;
        }
        if (x > y) {
          return -1;
        }
        return 0;
      });
    setData(result);
  }
  
  export function getLengthSpread(number) {
    const stringRepresentation = number.toString();
  const decimalIndex = stringRepresentation.indexOf('.');
  if(decimalIndex==-1) 
  return 0;
  const lengthAfterDecimal = stringRepresentation.substring(decimalIndex + 1).length;
  return lengthAfterDecimal;
  }
  
  export function getCoinRate(coins, coin) {
    let coins1 = Object.values(coins);
    let res = coins1.find((d) => d.symbol == coin);
    // console.log('current_price',coin,res?.current_price)
    const result =
      Math.round(res?.current_price * 10000) / 10000 != 0
        ? Math.round(res?.current_price * 10000) / 10000
        : Math.round(res?.current_price * 100000000) / 100000000;
    return result;
  }
  
  export function getRound(balance) {
    return Math.round(balance * 10000) / 10000 != 0
      ? Math.round(balance * 10000) / 10000
      : Math.round(balance * 100000000) / 100000000;
  }
  
  export function capitalizeFirstLetter(str) {
    // Convert the first character to uppercase
    const capitalizedFirstLetter = str[0].toUpperCase();
  
    // Get the remaining part of the string
    const remainingString = str.slice(1);
  
    // Combine the capitalized first letter and the remaining string
    const capitalizedString = capitalizedFirstLetter + remainingString;
  
    // Return the capitalized string
    return capitalizedString;
  }
  
  export function getElementsExistInAnotherArrayOnly(array1, array2) {
    return array1.filter(item1 => array2.some(item2 => item2.currency_type.toUpperCase() === item1.symbol.toUpperCase()));
  }
  
  
  export function getSecond(timestampInMillis) {
  const thirtyMinutesInMillis = 30 * 60 * 1000; // 30 minutes in milliseconds
  
  // Calculate expiration time by adding 30 minutes to the timestamp
  const expirationTime = timestampInMillis + thirtyMinutesInMillis;
  
  // Current time in milliseconds
  const currentTime = new Date().getTime();
  
  // Calculate remaining time in milliseconds
  const remainingTimeInMillis = expirationTime - currentTime;
  const remainingSeconds = Math.floor(remainingTimeInMillis / 1000);
  return remainingSeconds;
  }
  
  
  export function getMInutAgo(time) {
   // Create a Date object using the given timestamp
  const date = new Date(time).getTime();
    let now = Date.now();
    let diff = now-date;
  const minutesAgo = Math.floor(diff / (1000 * 60));
  return minutesAgo
  }
  
  
export function getOrderTypeFromOrderId(order_id) {
    if (order_id) {
      let order_id_array = order_id.split("/");
      if (order_id_array.length == 2) {
        let order_direction = order_id_array[order_id_array.length - 1];
        if (order_direction == "s") return "Sell";
        else if (order_direction == "b") return "Buy";
        else return undefined;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }