import axios from "axios";

const api = axios.create({
  baseURL: "https://api.elucks.exchange/api/",
  headers: {},
});
 
const order = axios.create({
  baseURL: "https://order.elucks.exchange/api/",
  headers: {},
});
 

const api_test = axios.create({
  baseURL:"https://api.elucks.exchange/api/",
  headers: {},
});
const logourl = "https://adminapi.elucks.exchange/"

export {api,api_test,order, logourl};
