import React from "react";
import { Link } from "react-router-dom";

const TermUse = () => {
  return (
    <div style={{ paddingTop: "120px" }}>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title text-center">
                <h2>USER AGREEMENT </h2>
                <p>VERSION 1.03</p>
                <p>LAST REVISED ON: 1st August,</p>
                <p>2022</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="section section-pad">
        <div class="container">
          <div class="row">
            <div class="col-md-12 res-m-bttm">
              <div class="post post-single">
                <div class="post-entry mt-2">
                  <p>
                    This user agreement (this <strong>“Agreement”</strong>) sets
                    forth the legally binding terms and conditions your access
                    to and use of any websites, mobile sites, mobile
                    applications, desktop applications, products or services
                    (the <strong>“Services”</strong>) offered by Elucks
                    Exchange (<strong>“Elucks”</strong>) and the business
                    known as <strong>“Elucks”</strong> (further described on
                    <Link to="/">www.elucks.exchange</Link>). Elucks to the
                    extent further described under Sections 5 and 6,
                    respectively, below.
                    <br />
                    <br />
                    Any reference to <strong>“Elucks”, “us”, “our”</strong>,
                    and <strong>“we”</strong> in this Agreement shall be
                    construed to be a reference to either Elucks or Elucks
                    depending on the relevant part of the Service in question.
                    For instance, Clauses 3 (Prohibited Activities) and 7 (Fees
                    and Taxes) applies vis-à-vis Elucks for its portion of the
                    Services as described under Clause 5 below, and Clause 3
                    applies vis-à-vis Elucks for its portion of the Services
                    as described under Clause 6 below.
                    <br />
                    <br />
                    The “User”, “you”, “your” shall refer to any natural person
                    or entity and its authorized users that subscribes or uses
                    the Services. Certain features of the Site may be subject to
                    additional guidelines, terms, or rules, which will be posted
                    on the Site in connection with such features. All such
                    additional terms, guidelines, and rules are incorporated by
                    reference into this Agreement.
                    <br />
                    <br />
                    BY ACCESSING OR USING THE SERVICES, YOU ARE ACCEPTING THIS
                    AGREEMENT (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU
                    REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE
                    RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THIS AGREEMENT
                    (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT).
                    YOU MAY NOT ACCESS OR USE THE SERVICES OR ACCEPT THIS
                    AGREEMENT IF YOU ARE NOT AT LEAST 18 YEARS OLD. IF YOU DO
                    NOT AGREE WITH ALL OF THE PROVISIONS OF THIS AGREEMENT, DO
                    NOT ACCESS AND/OR USE THE SITE. THESE TERMS REQUIRE THE USE
                    OF ARBITRATION (SECTION 13.1) ON AN INDIVIDUAL BASIS TO
                    RESOLVE DISPUTES AND ALSO LIMIT THE REMEDIES AVAILABLE TO
                    YOU IN THE EVENT OF A DISPUTE.
                    <br />
                    <br />
                    We may amend this Agreement related to the Services from
                    time to time. Amendments will be effective upon our posting
                    of such updated Agreement at this location or the amended
                    policies or supplemental terms on the applicable Service.
                    Your continued access or use of the Services after such
                    posting constitutes your consent to be bound by the
                    Agreement, as amended.
                    <br /> <br />
                    YOU ACKNOWLEDGE THAT TRADING IN CRYPTOCURRENCIES (ALSO
                    REFERRED TO AS VIRTUAL DIGITAL ASSETS, CRYPTO-ASSETS, ETC.)
                    INVOLVES A HIGH DEGREE OF RISK. CRYPTOCURRENCIES ARE SUBJECT
                    TO CONSTANT AND FREQUENT FLUCTUATIONS IN VALUE AND EXCHANGE
                    RATES, AND THE VALUE OF YOUR CRYPTOCURRENCY ASSETS MAY
                    INCREASE OR DECREASE AT ANY TIME. ACCORDINGLY, YOU MAY
                    SUFFER A COMPLETE LOSS OF THE FUNDS HELD IN YOUR ACCOUNT.
                    YOU ACKNOWLEDGE THAT YOU ARE SOLELY RESPONSIBLE FOR
                    DETERMINING THE NATURE, SUITABILITY, AND APPROPRIATENESS OF
                    THESE TRADING RISKS FOR YOU. YOU ACKNOWLEDGE AND AGREE THAT
                    ELUCKS BEARS NO RESPONSIBILITY OR LIABILITY TO YOU OR ANY
                    OTHER PERSON WHATSOEVER FOR ANY LOSSES OR GAINS INCURRED IN
                    CONNECTION WITH YOUR USE OF THE SERVICES. YOU ALSO
                    ACKNOWLEDGE AND AGREE THAT ELUCKS DOES NOT GIVE ADVICE OR
                    RECOMMENDATIONS REGARDING THE TRADING OF CRYPTOCURRENCIES,
                    INCLUDING THE SUITABILITY AND APPROPRIATENESS OF, AND
                    INVESTMENT STRATEGIES FOR, CRYPTOCURRENCIES. YOU ALSO
                    DECLARE THAT YOU ARE NOT IN THE BUSINESS OR PROFESSION OF
                    REFERRING NEW USERS TO US. THE REFERRALS MADE BY YOU TO US
                    ARE IN YOUR INDIVIDUAL CAPACITY. IN THE EVENT YOU ARE
                    UNDERTAKING SUCH REFERRALS IN FURTHERANCE TO A BUSINESS OR
                    PROFESSION, YOU ARE UNDER THE OBLIGATION TO BRING IT TO OUR
                    NOTICE. IN SUCH A CASE, WE WILL BE ENTITLED TO DEDUCT /
                    COLLECT ADDITIONAL WITHHOLDING TAXES AS MAY BE REQUIRED BY
                    APPLICABLE LAW.
                    <br />
                    <br />
                    YOU ALSO ACKNOWLEDGE AND AGREE THAT ELUCKS AND YOUR
                    ABILITY TO USE THE SERVICES MAY BE DETRIMENTALLY IMPACTED BY
                    REGULATORY ACTION OR CHANGES IN REGULATIONS APPLICABLE TO
                    CRYPTOCURRENCY. YOU AGREE THAT WE MAY DISCLOSE YOUR PERSONAL
                    AND ACCOUNT INFORMATION IF WE BELIEVE THAT IT IS REASONABLY
                    NECESSARY TO COMPLY WITH A LAW, REGULATION, LEGAL PROCESS,
                    OR GOVERNMENTAL REQUEST.
                    <br />
                    <br />
                    YOU HEREBY ACKNOWLEDGE AND AGREE THAT ELUCKS IS NOT A
                    FINANCIAL INSTITUTION, BANK, CREDIT UNION, TRUST, HEDGE
                    FUND, BROKER OR INVESTMENT OR FINANCIAL ADVISOR, AND IS NOT
                    SUBJECT TO THE SAME LAWS, REGULATIONS, DIRECTIVES OR
                    REQUIREMENTS APPLICABLE TO SUCH PERSONS. YOU ACKNOWLEDGE AND
                    AGREE THAT NO ORAL OR WRITTEN INFORMATION OR ADVICE PROVIDED
                    BY ELUCKS, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS,
                    NOR ANY INFORMATION OBTAINED THROUGH THE SERVICES, WILL OR
                    SHALL CONSTITUTE INVESTMENT, OR FINANCIAL ADVICE. YOU ARE
                    SOLELY AND EXCLUSIVELY RESPONSIBLE FOR ALL TRADING DECISIONS
                    MADE BY YOU WHILE USING THE SERVICES.
                    <br />
                    <br />
                    ELUCKS IS MERELY A TECHNOLOGY PLATFORM AND YOU ARE SOLELY
                    AND ENTIRELY RESPONSIBLE FOR THE LEGAL, REGULATORY AND TAX
                    COMPLIANCE OF ALL TRANSACTIONS CARRIED OUT BY YOU USING THE
                    SERVICES, SUBJECT TO THE TERMS OF THIS AGREEMENT.
                  </p>
                  <h3>1. ACCOUNTS</h3>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>1.1 Account Creation.</strong> In order to use
                    certain features of the Site, you must register for an
                    account (“Account”) and provide certain information about
                    yourself as prompted by the account registration form. You
                    represent and warrant that: (a) all required registration
                    information you submit is truthful and accurate; (b) you
                    will maintain the accuracy of such information; (c) your use
                    of the Services is and shall be in accordance with
                    applicable law. You may temporarily suspend your Account at
                    any time, for any reason, by following the instructions on
                    the Site. Elucks may suspend or terminate your Account in
                    accordance with Section 9.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>1.2 Identity Verification.</strong> You agree to
                    provide us with the information we request for the purposes
                    of identity verification and the detection of money
                    laundering, terrorist financing, fraud, or any other
                    financial crime and permit us to keep a record of such
                    information. You will need to complete certain verification
                    procedures before you are permitted to use the Services and
                    your access to the Services may be altered as a result of
                    information collected on an ongoing basis. The information
                    we request may include certain personal information,
                    including, but not limited to, your name, address, telephone
                    number, e-mail address, date of birth, taxpayer
                    identification number, government identification number, and
                    information regarding your bank account (such as the name of
                    the bank, the account type, routing number, and account
                    number). In providing us with this or any other information
                    that may be required, you confirm that the information is
                    accurate and authentic. You agree to update this information
                    and keep it current at all times. You authorize us to make
                    the inquiries, whether directly or through third parties,
                    that we consider appropriate, in our sole discretion, to
                    verify your identity or protect you and/or us against fraud
                    or other financial crimes, and to take action we reasonably
                    deem necessary based on the results of such inquiries.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>1.3 Certain Restrictions.</strong> By using the
                    Services, you represent and warrant that: (i) neither you
                    nor any of your directors, officers, employees, agents,
                    affiliates or representatives is an individual or an entity
                    that is, or is owned or controlled by an individual or
                    entity that is (a) currently the subject of any Sanctions,
                    or (b) located, organized or resident in a Designated
                    Jurisdiction; (ii) you have complied in all material
                    respects with all applicable laws relating to Sanctions,
                    anti-terrorism, anti-corruption and anti-money laundering;
                    and (iii) you have instituted and maintained policies and
                    procedures designed to promote and achieve compliance with
                    such laws. For the purpose of this Agreement, “Designated
                    Jurisdiction” means any country or territory to the extent
                    that such country or territory itself is the subject of any
                    Sanction; “Sanction(s)” means any international economic
                    sanction administered or enforced by the United States
                    Government (including OFAC), the United Nations Security
                    Council, the European Union or other relevant sanctions
                    authority.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>1.4 Account Responsibilities.</strong> You are
                    responsible for maintaining the confidentiality of your
                    Account login information and are fully responsible for all
                    activities that occur under your Account including all
                    consequences under applicable laws. You agree to immediately
                    notify Elucks of any unauthorized use, or suspected
                    unauthorized use of your Account or any other breach of
                    security. Elucks cannot and will not be liable for any
                    loss or damage arising from your failure to comply with the
                    above requirements. Elucks will not be liable or
                    responsible for compromise, unauthorized use or suspected
                    unauthorized use of your Account or any claims or actions in
                    any manner related thereto.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<strong>1.5 Compliance.</strong> You
                    are solely responsible for ensuring that your use of the
                    Services is in compliance with all laws, rules and
                    regulations applicable to you and the right to access the
                    Services is automatically revoked where use of the Services
                    is prohibited or to the extent that the offering, sale or
                    provision of the Services conflicts with any law, rule or
                    regulation applicable to you. <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>
                      1.6 Available Only Where Permitted by Law.
                    </strong>{" "}
                    The Services are only available in jurisdictions where they
                    may be legally offered for sale. The Services are not
                    available to “U.S. Persons”, as such term is defined under
                    Regulation S promulgated under the Securities Act of 1933.
                    By accessing or using the Site, you represent and warrant
                    that you are not a U.S. Person.
                  </p>
                  <h3>2. ACCESS TO THE SERVICES</h3>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;<strong>2.1 License.</strong>{" "}
                    Subject to this Agreement, Elucks grants you a
                    non-transferable, non-exclusive, revocable, limited license
                    to use and access the Services solely for your own personal
                    or internal business purposes, in accordance with the
                    Account type you are currently subscribing to.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<strong>
                      2.2 Modification.
                    </strong>{" "}
                    Elucks reserves the right, at any time, to modify,
                    suspend, or discontinue the Services (in whole or in part)
                    with or without notice to you. You agree that Elucks will
                    not be liable to you or to any third party for any
                    modification, suspension, or discontinuation of the Services
                    or any part thereof.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>2.3 No Support or Maintenance.</strong> You
                    acknowledge and agree that Elucks will have no obligation
                    to provide you with any support or maintenance in connection
                    with the Services.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<strong>2.4 Ownership.</strong> You
                    acknowledge that all the intellectual property rights,
                    including copyrights, patents, trademarks, and trade
                    secrets, in the Services and its content are owned by
                    Elucks or its suppliers. Neither this Agreement (nor your
                    access to the Services) transfers to you or any third party
                    any rights, title or interest in or to such intellectual
                    property rights, except for the limited access rights
                    expressly set forth in Section 2.1. Elucks and its
                    suppliers reserve all rights not granted in this Agreement.
                    There are no implied licenses granted under this Agreement.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<strong>2.5 Feedback.</strong> If
                    you provide Elucks with any feedback or suggestions
                    regarding the Site (<strong>“Feedback”</strong>), you hereby{" "}
                    <strong>assign</strong> to Elucks all rights in such
                    Feedback and agree that Elucks shall have the right to use
                    and fully exploit such Feedback and related information in
                    any manner it deems appropriate. Elucks will treat any
                    Feedback you provide to Elucks as non-confidential and
                    non-proprietary. You agree that you will not submit to
                    Elucks any information or ideas that you consider to be
                    confidential or proprietary.
                  </p>
                  <h3>3. PROHIBITED ACTIVITIES</h3>
                  <p>
                    {" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>3.1 Prohibited Activities.</strong> In connection
                    with your use of Services, you hereby agree that you will
                    not:
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a) violate (or assist
                    any other party in violating) any applicable law, statute,
                    ordinance, or regulation;
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b) intentionally try to
                    defraud (or assist in the defrauding of) Elucks or any
                    other User;
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(c) provide false,
                    inaccurate, or misleading information;
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(d) trade taking
                    advantage of any technical glitch, malfunction, failure,
                    delay, default, or security breach;
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(e) take any action that
                    interferes with, intercepts, or expropriates any system,
                    data, or information;
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(f) partake in any
                    transaction involving the proceeds of illegal activity;
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(g) attempt to gain
                    unauthorized access to other Elucks Accounts, the
                    Services, or any related networks or systems;
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(h) use the Services on
                    behalf of any third party or otherwise act as an
                    intermediary between Elucks and any third parties, unless
                    Elucks and the User have specifically entered into a
                    written agreement enabling such use or action;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (i) use the
                    Services to engage in conduct that is detrimental to
                    Elucks or to any other User or any other third party;
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(j) collect any user
                    information from other Users, including, without limitation,
                    email addresses;
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(k) defame, harass, or
                    violate the privacy or intellectual property rights of
                    Elucks or any other User; or
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(l) upload, display or
                    transmit any messages, photos, videos or other media that
                    contain illegal goods, pornographic, violent, obscene or
                    copyrighted images or materials for use as an avatar, in
                    connection with a payment or payment request, or otherwise.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(m) In addition, you
                    agree not to: <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (i) upload,
                    transmit, or distribute to or through the Services any
                    computer viruses, worms, or any software intended to damage
                    or alter a computer system or data;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (ii) interfere
                    with, disrupt, or create an undue burden on servers or
                    networks connected to the Services, or violate the
                    regulations, policies or procedures of such networks; <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (iii) attempt to
                    reverse engineer, de-compile, disable, interfere with,
                    disassemble, copy, or disrupt the integrity or the
                    performance of the Services, any third-party use of the
                    Services, or any third-party data contained therein (except
                    to the extent such restrictions are prohibited by applicable
                    law; or <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (iv) access the
                    Services in order to build a competitive product or service
                    or copy any ideas, features, functions, or graphics of the
                    Services.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<strong>3.2 Enforcement.</strong> We
                    reserve the right (but have no obligation) to investigate
                    and/or take appropriate action against you in our sole
                    discretion if you engage in Prohibited Activities or violate
                    any other provision of this Agreement or otherwise create
                    liability for us or any other person. Such action may
                    include, in our sole and absolute discretion, terminating
                    your Account in accordance with Section 9, reporting you to
                    law enforcement authorities without providing any notice of
                    you about any such report and confiscating any balance
                    remaining in an Account which has been terminated.
                  </p>
                  <h3>4. THIRD-PARTY PRODUCTS AND SERVICES; OTHER USERS</h3>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>4.1 Third-Party Services.</strong> You acknowledge
                    that the Service will enable or assist you to access,
                    interact with, and/or purchase services from several
                    supported platforms and other third parties via third-party
                    websites or applications (collectively,{" "}
                    <strong>“Third-Party Services”</strong>). Such Third-Party
                    Services are not under the control of Elucks, Elucks
                    does not review, approve, monitor, endorse, warrant, or make
                    any representations with respect to Third-Party Services and
                    is not responsible for any ThirdParty Services. You use all
                    Third-Party Services at your own risk, and should apply a
                    suitable level of caution and discretion in doing so. Any
                    use of Third-Party Services is governed solely by the terms
                    and conditions of such Third-Party Services and any contract
                    entered into, or any transaction completed via any Third-
                    Party Services, is between you and the relevant third party,
                    and not with Elucks. You shall comply in all respects with
                    all applicable terms of the Third-Party Services that you
                    access or subscribe to in connection with the Services. If
                    at any time any Third-Party Services cease to make their
                    programs available to us on reasonable terms, we may cease
                    to provide such features to you without entitling you to
                    refund, credit, or other compensation.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<strong>4.2 Other Users. </strong>We
                    do not guarantee the identity of any User or other party or
                    ensure that a buyer or seller is authorized to complete a
                    transaction or will complete a transaction. Your
                    interactions with other Users are solely between you and
                    such Users. You agree that Elucks will not be responsible
                    for any loss or damage incurred as the result of any such
                    interactions. If there is a dispute between you and any
                    User, we are under no obligation to become involved.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<strong>4.3 Release. </strong>You
                    hereby release and forever discharge Elucks (and our
                    officers, employees, agents, successors, and assigns) from,
                    and hereby waive and relinquish, each and every past,
                    present and future dispute, claim, controversy, demand,
                    right, obligation, liability, action and cause of action of
                    every kind and nature (including personal injuries, death,
                    and property damage), that has arisen or arises directly or
                    indirectly out of, or that relates directly or indirectly
                    to, the Services (including any interactions with, or act or
                    omission of, other Users or any Third-Party Products and
                    Services).
                  </p>
                  <h3>5. SERVICES OFFERED AND OPERATED BY ELUCKS</h3>
                  <p>
                    Only the following Services are offered and operated by
                    Elucks.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>5.1 Supported Cryptocurrencies.</strong> The
                    Elucks wallet allows you to send, receive and store
                    cryptocurrency (together, “Wallet Transactions”). The
                    Elucks wallet is only available in relation to the
                    cryptocurrencies that Elucks, in its sole discretion,
                    decides to support (“Supported Cryptocurrency” or “Supported
                    Cryptocurrencies”). Information about the Supported
                    Cryptocurrencies can be found on the FAQ page on the
                    Elucks website. The Supported Cryptocurrencies may change
                    from time to time. Under no circumstances should you attempt
                    to carry out a Elucks Wallet Transaction in relation to a
                    cryptocurrency other than a Supported Cryptocurrency.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>5.2 Transaction instructions.</strong> Elucks will
                    process Wallet Transactions according to your instructions.
                    You accept and agree that:
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a) Elucks
                    does not guarantee the identity of any user, receiver, or
                    other party to a Elucks Wallet Transaction. You are solely
                    responsible for ensuring all transaction details are
                    correct, and you should carefully verify all transaction
                    information prior to submitting transaction instructions to
                    Elucks,
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b) Elucks
                    does not have any control over, or any liability in relation
                    to, the delivery, quality or any other aspect of any goods
                    or services that you may buy from or sell to any third
                    party. Elucks shall not be responsible for, and will take
                    no action in relation to, ensuring that any buyer or seller
                    you transact with using your Elucks wallet completes the
                    relevant transaction or has the requisite authority to do
                    so;
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(c) (i) if
                    you send Supported Cryptocurrency using the Elucks wallet,
                    you shall send such Supported Cryptocurrency only to another
                    blockchain wallet owned by you; or (ii) if you send
                    Supported Cryptocurrency using the Elucks wallet to a
                    third party, you shall not receive any consideration for
                    such transfer; and,
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(d) without
                    prejudice to the generality of this Agreement, you will
                    strictly comply with the Foreign Exchange Management Act,
                    1999 and Income Tax Act, 1961 in connection with all Wallet
                    Transactions.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>5.3 Receiving cryptocurrency.</strong> You may
                    receive Supported Cryptocurrency into your Elucks wallet
                    by providing the sender with a receive address generated in
                    your Elucks wallet. Your Elucks wallet will only be
                    credited with Supported Cryptocurrency sent to a receive
                    address generated through your Elucks wallet and
                    associated with that Supported Cryptocurrency. For example,
                    your Elucks wallet will be credited with ETH when it is
                    sent to an ETH receive address generated through your
                    Elucks wallet. Without prejudice to the generality of this
                    Agreement, you will strictly comply with the Foreign
                    Exchange Management Act, 1999 and Income Tax Act, 1961 in
                    connection with all Wallet Transactions.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>
                      5.4 Fiat Currency P2P (peer-to-peer) transactions.{" "}
                    </strong>
                    Elucks allows you to use fiat currency funds solely in
                    exchange for certain Supported Cryptocurrencies and with
                    other Elucks Users in so-called “P2P transactions.” In a
                    P2P transaction, Elucks holds the cryptocurrency in escrow
                    between the two counterparties until payment in fiat
                    currency funds between the buyer and seller Users has been
                    confirmed. Such Supported Cryptocurrency is released as soon
                    as payment is confirmed. Elucks does not take custody or
                    facilitate transfer of the fiat currency funds in this
                    model, and the transfer of the fiat currency funds is solely
                    between Users without any responsibility or liability of
                    Elucks in this Connection. Without prejudice to the
                    foregoing, you hereby grant Elucks the right to resolve
                    issues and disputes among Users in its sole discretion.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>
                      5.5 Trading between Supported Cryptocurrencies.
                    </strong>{" "}
                    Elucks allows you to trade with other Users between
                    various Supported Cryptocurrencies (e.g., BTC-ETH, BTC-LTC
                    etc.).
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>5.6 Sharing User Information.</strong> In the course
                    of processing a Wallet Transactions, Elucks may be
                    required to share your user information with other
                    contractual third parties, or as required under applicable
                    laws or demanded upon a lawful request by any governmental
                    authority. You hereby irrevocably grant full permission and
                    authority for Elucks to share this information with such
                    contractual third parties, or as required under applicable
                    laws or demanded upon a lawful request by any governmental
                    authority, and release Elucks from any liability, error,
                    mistake, or negligence related thereto.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>5.7 Trade Corrections.</strong>You acknowledge and
                    agree that Elucks may correct, reverse or cancel, in its
                    sole discretion, any order, trade, transfer, or other
                    transaction or activity with respect to which Elucks has
                    discovered that there was an error or abnormality, whether
                    such error or abnormality was caused by you, Elucks or a
                    third party or due to technical issues, system issues,
                    software failure or otherwise. Elucks provides no
                    guarantee or warranty that any such attempt will be
                    successful and will have no responsibility or liability for
                    such error(s) or any correction attempt(s).
                  </p>
                  <h3>6. SERVICES OFFERED AND OPERATED BY ELUCKS</h3>
                  <p>
                    Only the following Services are offered and operated by
                    Elucks.
                    <br />
                    <br />
                    Elucks enables Users to trade with each other in trading
                    pairs involving Indian Rupees (INR) on the one hand, and
                    Supported Cryptocurrency on the other hand e.g., INR-BTC,
                    INR-ETH. Such enabling of trades is on a non-P2P basisonly (
                    as P2P transactions are offered and operated by Elucks (as
                    described under Clause 5.4 above).
                    <br />
                    <br />
                    <u>Terms applicable to Indian Resident Users</u>
                    <br />
                    <br />
                    In non-P2P transactions, Users resident in India (as defined
                    under the Foreign Exchange Management Act, 1999) add to
                    their INR balance by depositing Indian Rupees from their
                    registered bank account / payment instrument to Elucks’s
                    or its partners’ bank account using regulated banking and
                    payment channels, and can redeem their INR balance to their
                    bank accounts / payment instrument by placing a withdrawal
                    request to Elucks, upon which request Elucks will
                    transfer the INR balance amount to the Users’ registered
                    bank account / payment instrument, using regulated banking
                    and payment channels, subject to Know Your Customer and
                    other safeguards carried out in Elucks’s sole discretion,
                    and subject to withdrawal limits published on the website /
                    mobile application from time to time. In this model,
                    Elucks merely acts as a duly appointed agent of the User
                    to whom the payment is due, and does not operate a payment
                    system.You hereby duly appoint Elucks as your agent for
                    this purpose.
                    <br />
                    <br />
                    <u>Terms applicable to Non-Indian Resident Users</u>
                    <br />
                    <br />
                    In all circumstances, Users not resident in India (as
                    defined under the Foreign Exchange Management Act, 1999)
                    shall not use Elucks’s Services to trade between Indian
                    Rupee (INR) and Supported Cryptocurrencies.
                  </p>
                  <h3>7. FEES AND TAXES</h3>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>7.1 General.</strong> You agree to pay Elucks the
                    fees set forth at{" "}
                    <a href="https://elucks.exchange/fees">
                      https://elucks.exchange/fees
                    </a>{" "}
                    which may be updated from time to time in our sole
                    discretion. Any such updated fees will apply prospectively
                    to any trades or other transactions that take place
                    following the effective date of such updated fees. You
                    authorize Elucks to remove any amounts from your Account
                    for any applicable fees owed by you under this Agreement{" "}
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>7.2 Withdrawal / Send fees.</strong> You may be
                    charged a fee to send or withdraw a Supported Cryptocurrency
                    from your Elucks wallet. We currently do not charge fees
                    to deposit or receive Supported Cryptocurrency into your
                    Elucks wallet. <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>7.3 Service fees.</strong> Elucks applies a Maker
                    / Taker fee structure for customers who trade Supported
                    Cryptocurrency using the Services. Please consult the page
                    set forth at{" "}
                    <a href="https://elucks.exchange/fees">
                      https://elucks.exchange/fees
                    </a>{" "}
                    for further information on applicable Maker and Taker fees.
                    Elucks will, at the time of any transaction on the
                    Elucks Exchange, notify you of any fees that will apply to
                    the transaction. By proceeding with any transaction, you
                    accept and agree to the applicable fees. Such fees will also
                    be displayed in your transaction history upon completion of
                    the transaction. <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>7.4 Taxes. </strong>All fees paid by you shall be
                    made free and clear of, and without reduction for, any and
                    all taxes, except any required withholding of taxes pursuant
                    to applicable law or on account of any Goods and Services
                    Tax to the extent applicable. In case where you withhold
                    taxes pursuant to applicable law, you shall furnish to us a
                    statement detailing such withholding and deposit of withheld
                    taxes. <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>7.5 Withholding taxes.</strong> You hereby authorize
                    Elucks to withhold tax and deposit on your behalf in
                    accordance with this Clause with effect from July 1, 2022.
                    Unless otherwise specified in this Agreement, Elucks will
                    withhold tax at rate of 1% (or at such rate(s) as specified
                    under law) of the total order value as per transactions
                    undertaken by you in Supported Cryptocurrencies. For the
                    purpose of this Clause, you will be presumed to be an Indian
                    resident if you have provided an Indian address at time of
                    Account creation, unless such information is updated
                    subsequently in accordance with Clause 1.2. In case where
                    Elucks withholds tax, Elucks shall provide you with
                    evidence of such withholding as per the statutory
                    requirements. You would be solely responsible for
                    ascertaining the total volume of relevant transactions
                    carried out by you and claim exemption(s), if any, from tax
                    authorities. Elucks shall not be responsible in any manner
                    whatsoever for the same.
                  </p>
                  <h3>8. CONTESTS</h3>
                  <p>
                    We may from time to time offer you the ability to
                    participate in contests of skill (<strong>“Contest”</strong>{" "}
                    or
                    <strong>“Contests”</strong>). If you choose to participate
                    in any Contests, you accept and agree to be bound and abide
                    by the applicable Contest terms and conditions which will be
                    published on the Elucks website from time to time and
                    incorporated herein by reference (the{" "}
                    <strong>“Contest Terms”</strong>). Elucks reserves the
                    right to modify the Contest Terms or discontinue any
                    contests at any time for any reason, including but not
                    limited to product availability, pricing issues, reduced
                    demand. Prizes, discounts and special pricing may be changed
                    or substituted at any time without prior notice. Taxes on
                    any prize or award are the sole responsibility of the
                    recipient. We have the right to withhold tax as may be
                    applicable at applicable rates on the winnings in Contest.
                    In such a case, we will provide you with evidence of such
                    withholding as per prescribed timelines.
                  </p>
                  <h3>9. OTHER REWARDS</h3>
                  <p>
                    . You may receive or be entitled to receive crypto-currency
                    as airdrops or on redemption of coupons or campaign rewards
                    or referral rewards. You hereby declare that you have not
                    paid any consideration, either in kind or otherwise, for
                    receipt of these rewards. You hereby also agree that you are
                    solely responsible for paying any taxes and / or complying
                    with tax obligations which may be applicable pursuant to
                    receipt of such rewards by you.
                  </p>
                  <h3>10. SUSPENSION, TERMINATION, AND CANCELLATION</h3>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>10.1 General.</strong> This Agreement will continue
                    to apply to you until terminated by either you or Elucks.
                    Any provision of this Agreement which, either by its terms
                    or to give effect to its meaning, must survive, and such
                    other provisions which expressly, or by their nature, are
                    intended to survive termination shall survive the expiration
                    or termination of this Agreement.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>10.2 Suspension, Termination.</strong> We may
                    terminate your Account or suspend your access to the
                    Services at any time and with immediate effect for any
                    reason or no reason, in our sole and absolute discretion. We
                    may decline to process any deposit or withdrawal without
                    prior notice and may limit or suspend your use of one or
                    more Services at any time, in our sole discretion. For
                    example, we may, in our sole discretion, not process,
                    withhold, suspend, pause, or hold the deposits or
                    withdrawals if we believe the transaction is suspicious, may
                    involve fraud or misconduct, violates applicable laws, or
                    violates the terms of this Agreement. If you have a balance
                    remaining in an Account which has been suspended, we may
                    freeze such balance for so long as the Account is suspended.
                    If the Account is terminated due to fraud, violation of law,
                    or violation of this Agreement, Elucks may, in its
                    discretion, confiscate any balance remaining in the Account
                    and deliver it to any applicable government, law
                    enforcement, or other authorities where circumstances
                    warrant.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>10.3 Cancellation.</strong> You may cancel your
                    Account at any time by withdrawing all balances and sending
                    us a message through the contact page requesting the
                    cancellation of your Account. Upon termination of this
                    Agreement and your Account, you remain liable for all
                    transactions made while the Account was open.
                  </p>
                  <h3>11. DISCLAIMERS</h3>
                  <p>
                    THE SERVICES ARE PROVIDED ON AN “AS-IS” AND “AS AVAILABLE”
                    BASIS, AND ELUCKS EXPRESSLY DISCLAIMS ANY AND ALL
                    WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS,
                    IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR
                    CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR
                    NON-INFRINGEMENT. WE (AND OUR SUPPLIERS) MAKE NO WARRANTY
                    THAT THE SERVICES WILL MEET YOUR REQUIREMENTS, WILL BE
                    AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE
                    BASIS, OR WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR
                    OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE. IF APPLICABLE
                    LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE SITE, ALL
                    SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS
                    FROM THE DATE OF FIRST USE. ELUCKS DISCLAIMS ANY AND ALL
                    RESPONSIBILITY OR LIABILITY IN RELATION TO THE CONTENT MADE
                    AVAILABLE THROUGH THE SERVICES, INCLUDING THE CUSTOMER
                    CONTENT, OR ANY CONTENT OR SERVICES PROVIDED BY THIRD
                    PARTIES. ELUCKS DOES NOT CONTROL OR VET CUSTOMER CONTENT
                    AND IS NOT RESPONSIBLE FOR WHAT USERS POST, TRANSMIT, OR
                    SHARE ON OR THROUGH THE SERVICES. ELUCKS IS NOT
                    RESPONSIBLE OR LIABLE IN ANY MANNER FOR ANY THIRD-PARTY
                    SERVICES.
                    <br />
                    <br />
                    ASSOCIATED WITH OR UTILIZED IN CONNECTION WITH THE SERVICES,
                    INCLUDING THE FAILURE OF ANY SUCH THIRD-PARTY SERVICES OR
                    SUPPORTED PLATFORMS.
                    <br />
                    <br />
                    SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
                    WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
                    SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN
                    IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT
                    APPLY TO YOU.
                  </p>
                  <h3>12. INDEMNIFICATION.</h3>
                  <p>
                    You agree to indemnify and hold Elucks (and its officers,
                    employees, and agents) harmless, including costs and
                    attorneys’ fees, from any claim or demand made by any third
                    party due to or arising out of (a) your use of the Services,
                    (b) your violation of this Agreement, (c) your violation of
                    applicable laws or regulations, or (d) Third-Party Services.
                    Elucks reserves the right, at your expense, to assume the
                    exclusive defense and control of any matter for which you
                    are required to indemnify us, and you agree to cooperate
                    with our defense of these claims. You agree not to settle
                    any matter without the prior written consent of Elucks.
                    Elucks will use reasonable efforts to notify you of any
                    such claim, action or proceeding upon becoming aware of it.
                  </p>
                  <h3>13. LIMITATION ON LIABILITY</h3>
                  <p>
                    TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
                    EVENT WILL ELUCKS, ITS AFFILIATES AND THEIR RESPECTIVE
                    SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES,
                    ATTORNEYS, AGENTS, REPRESENTATIVES, SUPPLIERS OR CONTRACTORS
                    BE LIABLE FOR ANY INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE,
                    CONSEQUENTIAL OR SIMILAR DAMAGES OR LIABILITIES WHATSOEVER
                    (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF DATA,
                    INFORMATION, REVENUE, PROFITS OR OTHER BUSINESS OR FINANCIAL
                    BENEFIT) ARISING OUT OF OR IN CONNECTION WITH THE SERVICES,
                    ANY PERFORMANCE OR NON-PERFORMANCE OF THE SERVICES, OR ANY
                    OTHER PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON
                    BEHALF OF ELUCKS AND ITS AFFILIATES, WHETHER UNDER
                    CONTRACT, STATUTE, STRICT LIABILITY OR OTHER THEORY EVEN IF
                    ELUCKS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES
                    EXCEPT TO THE EXTENT OF A FINAL JUDICIAL DETERMINATION THAT
                    SUCH DAMAGES WERE A RESULT OF ELUCKS’S GROSS NEGLIGENCE,
                    FRAUD, WILLFUL MISCONDUCT OR INTENTIONAL VIOLATION OF LAW.
                    SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION
                    OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
                    LIMITATION MAY NOT APPLY TO YOU. NOTWITHSTANDING THE
                    FOREGOING, IN NO EVENT WILL THE LIABILITY OF ELUCKS, ITS
                    AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS, MEMBERS,
                    DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS,
                    REPRESENTATIVES, SUPPLIERS OR CONTRACTORS ARISING OUT OF OR
                    IN CONNECTION THE SERVICES, ANY PERFORMANCE OR
                    NONPERFORMANCE OF THE SERVICES, OR ANY OTHER PRODUCT,
                    SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF ELUCKS
                    OR ITS AFFILIATES WHETHER UNDER CONTRACT, STATUTE, STRICT
                    LIABILITY OR OTHER THEORY, EXCEED THE AMOUNT OF THE FEES
                    PAID BY YOU TO ELUCKS UNDER THIS AGREEMENT IN THE
                    TWELVE-MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING
                    RISE TO THE CLAIM FOR LIABILITY.
                  </p>
                  <h3>14. GENERAL</h3>
                  <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>14.1 Dispute Resolution.</strong> Please read this
                    Arbitration Agreement carefully. It is part of your contract
                    with Elucks and affects your rights. It contains
                    procedures for MANDATORY BINDING ARBITRATION AND A CLASS
                    ACTION WAIVER.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a) Any
                    dispute, controversy or claim arising out of or relating to
                    this contract, or the breach, termination or invalidity
                    thereof, shall be settled by arbitration as follows:
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(i)
                    In the case of disputes, controversies or claims involving
                    Elucks: You and Elucks agree to resolve any claims
                    relating to this Agreement (including any question
                    regardingtheir existence, validity, termination, or any
                    services or products provided and any representations made
                    by us) through final and binding arbitration, except as set
                    forth under Exceptions to Agreement to Arbitrate below. You
                    agree to first give us an opportunity to resolve any claims
                    by contacting us on our website / mobile / desktop
                    applications. If we are not able to resolve your claims
                    within 60 days of receiving the notice, you may seek relief
                    through arbitration or in the Small Claims Tribunals of
                    Singapore (“SCT”), as set forth below. <br />
                    <br />
                    Either you or Elucks may submit a dispute (after having
                    made good faith efforts to resolve such dispute) for final
                    and binding resolution by arbitration under the arbitration
                    rules of the Singapore International Arbitration Centre
                    (“SIAC”), which are deemed to be incorporated into these
                    Terms by reference. The arbitration tribunal shall consist
                    of a sole arbitrator to be appointed by the President of
                    SIAC. The language of the arbitration hearings shall be
                    English and the seat, or legal place, of arbitration shall
                    be Singapore. JUDGMENT ON ANY ARBITRAL AWARD MAY BE GIVEN IN
                    ANY COURT HAVING JURISDICTION OVER THE PARTY (OR OVER THE
                    ASSETS OF THE PARTY) AGAINST WHOM SUCH AN AWARD IS RENDERED.
                    <br />
                    <br />
                    Exceptions. Either party may instead choose to assert the
                    claims in the SCT if the claims fall within the jurisdiction
                    of the SCT, and either party may seek injunctive relief or
                    other urgent equitable relief in a court of competent
                    jurisdiction. However, for the avoidance of doubt, where the
                    claims fall outside of the jurisdiction of the SCT, the
                    claims will be referred to and finally resolved by SIAC
                    arbitration.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(ii)
                    In the case of disputes, controversies or claims involving
                    Elucks, you and Elucks agreeto resolve any claims
                    relating to this Agreement (including any question regarding
                    their existence, validity, termination, or any services or
                    products provided and any representations made by us)
                    through final and binding arbitration. You agree to first
                    give us an opportunity to resolve any claims by contacting
                    us on our website / mobile / desktop applications. If we are
                    not able to resolve your claims within 60 days of receiving
                    the notice, you may seek relief through arbitration as set
                    forth below.
                    <br />
                    <br />
                    Either you or Elucks may submit a dispute (after having
                    made good faith efforts to resolve such dispute) for final
                    and binding resolution by arbitration under the arbitration
                    rules of the Singapore International Arbitration Centre
                    (“SIAC”), which are deemed to be incorporated into these
                    Terms by reference, read with the Indian Arbitration and
                    Conciliation Act, 1996. The arbitration tribunal shall
                    consist of a sole arbitrator to be appointed by the
                    President of SIAC. The language of the arbitration hearings
                    shall be English and the seat of arbitration shall be
                    Singapore.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(b) Waiver
                    of Jury Trial. THE PARTIES HEREBY WAIVE THEIR RIGHTS TO GO
                    TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, as
                    applicable, instead electing that all claims and disputes
                    shall be resolved by arbitration under this Arbitration
                    Agreement. Arbitration procedures are typically more
                    limited, more efficient and less costly than rules
                    applicable in a court and are subject to very limited review
                    by a court. In the event any litigation should arise between
                    you and Elucks in any state or federal court in a suit to
                    vacate or enforce an arbitration award or otherwise, YOU AND
                    ELUCKS WAIVE ALL RIGHTS TO A JURY TRIAL, instead electing
                    that the dispute be resolved by a judge.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(c) Waiver
                    of Class or Consolidated Actions. ALL CLAIMS AND DISPUTES
                    WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE
                    ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A
                    CLASS BASIS, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER
                    CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED
                    WITH THOSE OF ANY OTHER CUSTOMER OR USER.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(d)
                    Confidentiality. All aspects of the arbitration proceeding,
                    including but not limited to the award of the arbitrator and
                    compliance therewith, shall be strictly confidential. The
                    parties agree to maintain confidentiality unless otherwise
                    required by law. This paragraph shall not prevent a party
                    from submitting to a court of law any information necessary
                    to enforce this Agreement, to enforce an arbitration award,
                    or to seek injunctive or equitable relief.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(e)
                    Severability. If any part or parts of this Arbitration
                    Agreement are found under the law to be invalid or
                    unenforceable by a court of competent jurisdiction, then
                    such specific part or parts shall be of no force and effect
                    and shall be severed and the remainder of the Agreement
                    shall continue in full force and effect.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(f) Right to
                    Waive. Any or all of the rights and limitations set forth in
                    this Arbitration Agreement may be waived by the party
                    against whom the claim is asserted. Such waiver shall not
                    waive or affect any other portion of this Arbitration
                    Agreement.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(g) Survival
                    of Agreement. This Arbitration Agreement will survive the
                    termination of your relationship with Elucks.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(h)
                    Emergency Equitable Relief. Notwithstanding the foregoing,
                    either party may seek emergency equitable relief before a
                    court of competent jurisdiction in order to maintain the
                    status quo pending arbitration. A request for interim
                    measures shall not be deemed a waiver of any other rights or
                    obligations under this Arbitration Agreement.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(i) Claims
                    Not Subject to Arbitration. Notwithstanding the foregoing,
                    claims of defamation and infringement or misappropriation of
                    the other party’s patent, copyright, trademark or trade
                    secrets shall not be subject to this Arbitration Agreement.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>14.2 Applicable Law. </strong>The laws of India
                    excluding its choice of law provisions, will govern these
                    Terms and any dispute that arises between you and Elucks.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>14.3 Force Majeure.</strong> Elucks is not
                    responsible for damages caused by delay or failure to
                    perform undertakings under this Agreement when the delay or
                    failure is due to fires; strikes; floods; power outages or
                    failures; acts of God or the state’s enemies; lawful acts of
                    public authorities; any and all market movements, shifts, or
                    volatility; computer, server, or Internet malfunctions;
                    security breaches or cyberattacks; criminal acts; delays or
                    defaults caused by common carriers; acts or omissions of
                    third parties; or, any other delays, defaults, failures or
                    interruptions that cannot reasonably be foreseen or provided
                    against. In the event of force majeure, Elucks is excused
                    from any and all performance obligations and this Agreement
                    shall be fully and conclusively at an end.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>14.4 Electronic Communications.</strong> The
                    communications between you and Elucks use electronic
                    means, whether you use the Site or send us emails, or
                    whether Elucks posts notices on the Site or communicates
                    with you via email. For contractual purposes, you (a)
                    consent to receive communications from Elucks in an
                    electronic form; and (b) agree that all terms and
                    conditions, agreements, notices, disclosures, and other
                    communications that Elucks provides to you electronically
                    satisfy any legal requirement that such communications would
                    satisfy if it were be in a hardcopy writing. The foregoing
                    does not affect your non-waivable rights.
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>14.5 Entire Agreement.</strong> This Agreement,
                    together with Elucks’s Privacy Policy, constitutes the
                    entire agreement between you and us regarding the use of the
                    Services. Our failure to exercise or enforce any right or
                    provision of this Agreement shall not operate as a waiver of
                    such right or provision. The section titles in this
                    Agreement are for convenience only and have no legal or
                    contractual effect. The word “including” means “including
                    without limitation”. If any provision of this Agreement is,
                    for any reason, held to be invalid or unenforceable, the
                    other provisions of this Agreement will be unimpaired and
                    the invalid or unenforceable provision will be deemed
                    modified so that it is valid and enforceable to the maximum
                    extent permitted by law. Your relationship to Elucks is
                    that of an independent contractor, and neither party is an
                    agent or partner of the other. This Agreement, and your
                    rights and obligations herein, may not be assigned,
                    subcontracted, delegated, or otherwise transferred by you
                    without Elucks’s prior written consent, and any attempted
                    assignment, subcontract, delegation, or transfer in
                    violation of the foregoing will be null and void. Elucks
                    may freely assign this Agreement. The terms and conditions
                    set forth in this Agreement shall be binding upon assignees.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermUse;
