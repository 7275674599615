import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import WalletTab from "../components/WalletTab";
import { N_TransferP2P } from "../utils/api_functions";

import toast from "react-hot-toast";
import { add, mul, round, sub } from "../utils/Math";
import { set_Total_Inr_Price } from "../../src/features/Exchange";
import { N_get_walletp2p } from "../utils/api_functions_new";

const Funding = () => {
  const dispatch = useDispatch();
  const { coins } =
    useSelector((state) => state?.exchange?.value);
  const { user_id } = useSelector((state) => state?.user?.value.userInfo);
  const [wallet_details, setWalletDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total_inr, setTotalInr] = useState(0);
  const [transfersymbol, setTransferSymbol] = useState();
  const [transferType, setTransferType] = useState();
  const [transferAmount, setTransferAmount] = useState(0);
  let coins1 = coins;
  function getCoinRate(coin) {
    let res = coins1.find((d) => d.symbol === coin.toUpperCase());
    //  console.log("inr price: ", res, coin);
    if (coin === "INR") {
      return 1;
    } else {
      return res?.current_price_inr ? res.current_price_inr : 0;
    }
  }
  function getWallets() {
    N_get_walletp2p(user_id)
      .then((d) => {
        if (d.status === 200) {
          let total = 0;
          let final_data = Object.keys(d.params.wallets).map((res, i) => {
            let wallet_type = d.params.wallets[res]?.symbol;
            let rate = getCoinRate(wallet_type, "INR");
            let inr_val = mul(rate, d.params.wallets[res]?.balance);
            total = add(total, inr_val);
            return {
              id: d.params.wallets[res]?.id,
              deposit_limit: d.params.wallets[res]?.deposit_limit
                ? d.params.wallets[res].deposit_limit
                : 0,
              icon: d.params.wallets[res]?.icon,
              symbol: wallet_type,
              name: d.params.wallets[res]?.name,
              status: d.params.wallets[res]?.status,
              withdral_fee: d.params.wallets[res]?.withdrawal_fee,
              address: d.params.wallets[res].wallet_address,
              balance:round(d.params.wallets[res]?.balance),
              locked: round(d.params.wallets[res]?.locked),
              avl_balance: sub(
                d.params.wallets[res]?.balance,
                d.params.wallets[res]?.locked
              ),
              inr_balance: inr_val
            };
          });
          setWalletDetails(final_data);
          setTotalInr(total);
          dispatch(set_Total_Inr_Price({ total_inr_price: total }));
          setTimeout(() => setLoading(false), 800);
        } else {
          console.log(d);
        }
      })
      .catch((e) => {
        console.log("er", e);
      });
  }

  const Ncard =
  wallet_details &&
  wallet_details.map((res) => {
    return <option value={res.symbol}>{res.symbol}</option>;
  });
  useEffect(() => {
    getWallets();
  }, [coins]);
  return (
    <div className="accountbg account_tab">
      {/* <Toaster position="top-right" reverseOrder={false} /> */}
      <WalletTab />
      <div className="container text-repo" style={{ minHeight: "34em" }}>
        <div className="box-body">
        <div className="col-md-12 mb-4">
                    <div className="card">
                      <div className="card-body">
                      <div className="row align-items-center">
                      <div className="col-12 col-md-3">
                        <label htmlFor="coin" className="text-muted fs-12">Select Coin</label>
                        <select
                          class="buy-sell-form-bg buy-sell-theme form-control"
                          id="coin"
                          onChange={(e) => {
                            setTransferSymbol(e.target.value);
                          }}
                        >
                          <option selected>Please Select</option>
                          {Ncard}
                        </select>
                      </div>
                      <div className="col-12 col-md-3">
                        <label htmlFor="coin_transfer" className="text-muted fs-12">Select Transfer</label>
                        <select
                          class="buy-sell-form-bg buy-sell-theme form-control"
                          id="coin_transfer"
                          onChange={(e) => {
                            setTransferType(e.target.value);
                          }}
                        >
                          <option selected>Please Select</option>
                          <option value="minus">Funding to Spot</option>
                        </select>
                      </div>
                      <div className="col-12 col-md-3">
                        <label htmlFor="amount"  className="text-muted fs-12">Enter Amount</label>
                        <input
                          type="text"
                          name="amount"
                          className="buy-sell-form-bg buy-sell-theme form-control"
                          id="amount"
                          value={transferAmount}
                          onChange={(e) => {
                            setTransferAmount(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-12 col-md-3">
                        <button
                          className="btn btn-danger"
                          style={{marginTop:"18px"}}
                          onClick={() => {
                            if (
                              transferAmount > 0 &&
                              transfersymbol &&
                              transferType
                            ) {
                              N_TransferP2P(
                                user_id,
                                transferAmount,
                                transfersymbol,
                                transferType
                              ).then((res) => {
                                let data = res.data;
                                if (data.status === 200) {
                                    toast.success(data.message);
                                  document.location.reload();
                                } else {
                                    toast.error(data.message);
                                }
                              });
                            } else {
                                toast.error("Fill all data");
                            }
                          }}
                        >
                          Transfer Balance
                        </button>
                      </div>
                    </div>
                      </div>
                    </div>
                  </div>
          <div className="table-responsive mt-5">
            <table
              className="tbl table"
              style={{
                overflow: "hidden"
              }}
            >
              <thead className="">
                <tr>
                  <th scope="col">ASSETS</th>
                  <th scope="col">AVAILABLE BALANCE</th>
                  <th scope="col">LOCKED</th>
                  <th scope="col">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr className="">
                    <td colSpan={8}>
                      <div class="d-flex text-info justify-content-center">
                        <div class="spinner-border">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : wallet_details ? (
                  wallet_details.map((item, index) => (
                    <>
                      {item?.symbol !== "INR" && item?.balance>0 ? (
                        <>
                          <tr className="">                           
                            <td>
                              {item?.symbol}
                            </td>
                            <td>{item?.avl_balance}</td>
                            <td>{item?.locked}</td>
                            <td>
                              {item?.balance}
                            </td>
                          </tr>
                          <th></th>                          
                        </>
                      ) : null}
                    </>
                  ))
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Funding;
