import toast from "react-hot-toast";

import {
  api,
  api_test,
  order
}
from "../utils/api";

export function N_get_wallet(userid) {

  return api.post("get-wallets-new",

      {
        user_id: userid
      }
    )
    // .then((res) => res.JSON())
    .catch((e) => e);
}

export function N_DepositUpdate(userid) {

  return api.post("update-wallet",

      {
        user_id: userid
      }
    )
    // .then((res) => res.JSON())
    .catch((e) => e);
}

export function N_createAddress(userid, symbol) {

  return api.post("create-wallets-user",

      {
        user_id: userid,
        symbol:symbol
      }
    )
    // .then((res) => res.JSON())
    .catch((e) => e);
}

export function N_crypto_withdraw_Otp_Email(userid, otp,transection_id, wallettype) {
  return api.post(
      "varifie/email-Withdraw", {
        user_id: userid,
        otp: otp,
        transection_id: transection_id,
        symbol:wallettype
      }
    )

    .catch((e) => e);
}
export function N_ScreenShot(event, file, price, userid, req_no) {
  event.preventDefault();
  const formControl = event.target.parentElement;
  const formData = new FormData();

  // The third parameter is required for server
  if (file && price && userid) {
    formControl.querySelector(".spinner-border").style.display = "block";
    formData.append("file", file, file.name);
    formData.append("price", price);
    formData.append("req_no", req_no);
    formData.append("user_id", userid);

    api
      .post("upload-screenshot", formData)
      .then((res) => {
        const data = res.data;
        if (res.status === 200 && data.status === 200) {
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
        formControl.querySelector(".spinner-border").style.display = "none";
      })
      .catch((e) => {
        toast.error("Something went Wrong!!");
      });
  } else {
    toast.error("Provide all Data");
  }
}

export function N_Screenp2pShot(event, file, transection_id, order_id, user_id, amt) {
  event.preventDefault();
  // const formControl = event.target.parentElement;
  const formData = new FormData();
  // The third parameter is required for server
  if (file && transection_id && order_id && user_id && amt>0) {
    document.getElementById("snper").style.display = "block";
    formData.append("file", file, file.name);
    formData.append("transection_id", transection_id);
    formData.append("order_token", order_id);
    formData.append("user_id", user_id);
    formData.append("volume", amt);

    api
      .post("upload-payment-screenshot", formData)
      .then((res) => {
        const data = res.data;
        console.log("data", data);
        if (res.status === 200 && data.status === 200) {
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
        document.getElementById("snper").style.display = "none";
      })
      .catch((e) => {
        toast.error("Something went Wrong!!");
      });
  } else {
    toast.error("Provide all Data");
  }
}

export function N_inr_withdraw(userid, volume, symbol, remark) {
  return api.post("banking/inr_withdrawal", {
      user_id: userid,
      volume: volume,
      symbol: symbol,
      remark: remark,

    })
  }

export function N_crypto_withdraw(
  userid,
  symbol,
  address,
  to_address,
  volume,
  remark
) {

  return api.post("get-withdraw", {


      user_id: userid,
      fromAddress: address,
      symbol: symbol,
      toAddress: to_address,
      volume: volume,
      remark: remark,

    })
  
    .catch((e) => e);
}
export function N_transectionHistory(userid) {
  return api.post("transection_history", {

      user_id: userid,

    })

    .catch((e) => e);
}
export function N_createBuyOffer(
  raw_price,
  volume,
  currency_type,
  compare_currency,
  user_id,
  type = "buy"
) {
  return order.post("buy-order1", {
      user_id: user_id,
      currency_type: currency_type,
      volume: volume,
      compare_currency: compare_currency,
      raw_price: raw_price,
      type: type,
    })

    .catch((e) => e);
}

export function N_createSellOffer(
  raw_price,
  volume,
  currency_type,
  compare_currency,
  user_id,
  type = "sell"
) {
  return order.post("sell-order1", {
    user_id: user_id,
    currency_type: currency_type,
    volume: volume,
    compare_currency: compare_currency,
    raw_price: raw_price,
    type: type,
  })
}

export function N_createp2pSellOrder(
  raw_price,
  volume,
  currency_type,
  compare_currency,
  user_id,
  type="sell"
) {
  return api.post("sell-p2p-order", {
    user_id: user_id,
    currency_type: currency_type,
    volume: volume,
    compare_currency: compare_currency,
    raw_price: raw_price,
    type
  })
}

export function N_p2pSellOrder(
  user_id,
  type,
  coin
) {
  return api.post("p2p-order-list", {
    user_id: user_id,
    type:type,
    coin:coin
  })
}

export function N_p2pActionOrder(
  user_id,
  type,
  coin
) {
  return api.post("p2p-action-list", {
    user_id: user_id,
    type:type,
    coin:coin
  })
}

export function N_p2pInitiateOrder(
  user_id,
  order_token
) {
  return api.post("initiate-order", {
    user_id: user_id,
    order_token:order_token
  })
}

export function N_p2pOrderAction(
  user_id,
  order_token,
  type
) {
  return api.post("p2p-order", {
    user_id: user_id,
    order_token:order_token,
    type:type
  })
}

export function N_p2pCancelOrder(
  user_id,
  order_token
) {
  return api.post("p2p-cancel-order", {
    user_id: user_id,
      order_token:order_token
  })
}

export function N_p2pCheckStatus(
) {
  return api.get("p2p-check-status")
}

export function N_TransferP2P(
  user_id, volume, wallet_type, type
) {
  return api.post("transfer-fund", {
    user_id,
    volume,
    wallet_type,
    type
  })
}


export function N_OrdersHistory(user_id) {
  return api.post("order-history", {
    user_id: user_id,
  })
}
export function N_panVerify(pan_no, user_id) {
  return api.post("kyc/verify-pan", {
    pan_no: pan_no,
    user_id: user_id
  });
}
export function N_checkKYCStatus(user_id) {
 return api.post("kyc/check-status", {
    user_id: user_id,
  })
}
export function N_getCity(country, state, user_id) {
  return api.post("kyc/get-country", {
    action: "city",
    country_name: country,
    state_name: state,
    user_id: user_id,
   })
 }
 export function N_getCountry(user_id) {
  return api.post("kyc/get-country", {
    action: "country",
    user_id: user_id,
   })
 }
 export function N_getState(country, user_id) {
  return api.post("kyc/get-country", {
    action: "state",
    country_name: country,
    user_id: user_id,
   })
 }
 export function N_userInfoSubmit(body) {
  return api.post("kyc/set-personal-info", {
      kyc_type: body.type_of_kyc,
      first_name: body.fname,
      middle_name: body.mname,
      last_name: body.lname,
      date_of_birth: new Date(body.dob).getTime(),
      address: body.address,
      country: body.country,
      state: body.state,
      city: body.city,
      pincode: body.pincode,
      user_id: body.user_id
   })
 }
 

 export function N_p2pWallets(user_id) {
  return api.post("get-p2p-wallet", {
    user_id: user_id
   })
 }

 export function N_ischeckKycSubmited(user_id, data) {
  return api.post("kyc/update-documents", {
    user_id: user_id,
    title: "kycstatus",
    data: data,
   })
 }

 export function N_setKyc(body) {
  return api.post("kyc/update-documents", body)
 }

 export function N_test(data) {
  return api.post("kyc/update-documents", data)
 }

export function N_sendAADHAROTPMobile(aadhar_no, user_id) {
  return api.post("kyc/validate-aadhar", {
    method: "POST",
      aadhar_no: aadhar_no,
      user_id:user_id
  })
}
export function N_verifyAADHAROTPMobile(client_id, mobile_no, otp, user_id) {
  return api.post("kyc/verify-adhar", {
   
      otp: otp,
      mobile_no:mobile_no,
      client_id:client_id,
      user_id:user_id
  })
}

export function N_sendOTPMobile(user_id, mobile_number) {
  return api.post("send-mobile-varification-otp", {
   
      user_id: user_id,
      mobile_no: mobile_number,
  })
}
export function N_verifyOTPMobile(user_id, otp) {
  return api.post("varifie/mobile", {
      user_id: user_id,
      otp: otp,
  })
}
export function N_setGoogleAuth(user_id) {
  return api.post("set-auth-google", {
      user_id: user_id
    
  })
}
export function N_setGoogleAuthOTP(user_id, state, otp, action) {
  return api.post("set-auth-google-otp", {
      user_id: user_id,
      status:state,
      otp:otp,
      action:action
    
  })
}
export function N_get_settings(user_id) {
  return api.post("get-auth-google-setting", {
      user_id: user_id
    
  })
}
export function N_checkMobileStatus(user_id) {
  return api.post("check_user_status", {
      user_id: user_id
    
  })
}
export function N_getUserBankDetails(user_id) {
  return api.post("banking/get-banking-status", {
     user_id: user_id,
  })
 
}
export function N_getSupportedCurrency(user_id) {
  return api.post("getpairedCurrency", {
    user_id: user_id 
  })
}
export function N_getBank(user_id) {
  return api.post("banking/get-bank", {
      user_id: user_id,
  })
}

export function N_addBinficiary(
  account_number,
  confirm_account_number,
  bank_name,
  name,
  ifsc_code,
  account_type,
  userid
) {
  return api.post("banking/set-banking-info", {
   
      bank_name: bank_name,
      name: name,
      account_type: account_type,
      account_number: account_number,
      confirm_account_number: confirm_account_number,
      ifsc_code: ifsc_code,
      user_id: userid,
  })
}
export function N_getUserProfile(userid) {
  return api.post("user/get-profile-info", {
      user_id:userid,
  })
}

export function N_getWebsiteData() {
  return api.get("get-website-data")
}

export function N_getReferals(user_id) {
  return api.post("user/get-referals", {
    user_id: user_id 
  })
}

export function N_executeOrder( order_id,user_id,type){
   return order.post("execute-orders",{
    order_id:order_id,
    user_id: user_id,
    type: type,
   })
}

export function N_get_user_Orders_History(userid, page, per_page) {
  return api.post("get-user-order-history", {
    user_id: userid,
    page, 
    per_page
  })
}

export function N_cancleOrderById(user_id,order_id) {
  return api.post("cancle-order", {
      user_id: user_id,
      order_id: order_id,
  })
}

export function N_sendOTPEmail(user_id,email) {
  return api.post("send-mail", {
      user_id:user_id,
      email:email,
  })
}
export function N_verifyOTPEmail(user_id,otp) {
  return api.post("varifie/email", {
      user_id:user_id,
      otp:otp,
  })
}

export function N_crypto_withdraw_Otp(user_id, otp, transection_id, wallettype) {
  return api.post("varifie/mobile-Withdraw", {
    user_id: user_id,
    otp: otp,
    transection_id: transection_id,
    symbol:wallettype
    })
    .catch((e) => e);
}

export function N_direct_buy(user_id, raw_price, volume, currency_type,compare_currency) {
  return order.post("direct-buy", {
    user_id: user_id,
    raw_price:raw_price,
    volume:volume,
    currency_type:currency_type,
    compare_currency:compare_currency,
    type:"direct-buy",
    })
    .catch((e) => e);
}


