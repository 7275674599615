const url = "https://api.elucks.exchange/api";


export function N_createp2pSellOrder(
  user_id,
  raw_price,
  volume,
  currency_type,
  compare_currency,
  type,
  payment_method
) {
  return fetch(`${url}/sell-p2p-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*"
    },
    body: JSON.stringify({
      user_id,
      raw_price,
      volume,
      currency_type,
      compare_currency,
      type,
      payment_method
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}
export function N_p2pSellOrder(user_id, type, coin, amount, payment_method) {
  return fetch(`${url}/p2p-order-list`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*"
     
    },
    body: JSON.stringify({
        user_id:user_id,
      type: type,
      coin: coin,
      amount:amount,
      payment_method
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_setChat(user_id, order_id, message) {
  return fetch(`${url}/set-chat`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*"
     
    },
    body: JSON.stringify({
      user_id,order_id, message
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_getChat(user_id, order_id) {
  return fetch(`${url}/get-chat`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*"
     
    },
    body: JSON.stringify({
      user_id,order_id
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}


export function N_p2pMyOrder(user_id, type, coin, status, start_date, end_date) {
  return fetch(`${url}/p2p-myorder-list`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*"
     
    },
    body: JSON.stringify({
        user_id,
      type,
      coin,
      status, start_date, end_date
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_p2pInitiateOrder(user_id, order_id, amount) {
  return fetch(`${url}/initiate-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*"
     
    },
    body: JSON.stringify({
      user_id, order_id, amount
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_p2pConfirmOrder(user_id, order_id, type, order_idd) {
  return fetch(`${url}/confirm-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*"
     
    },
    body: JSON.stringify({
      user_id, order_id, type, order_idd
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_p2pOrderAction(user_id, order_id, type) {
  return fetch(`${url}/p2p-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*"
     
    },
    body: JSON.stringify({
        user_id,
      order_id,
      type: type,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_p2pOrderAppeal(user_id, order_id) {
  return fetch(`${url}/p2p-appeal`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*"
     
    },
    body: JSON.stringify({
        user_id,
      order_id
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_getp2pOrder(user_id, order_id, type) {
  return fetch(`${url}/get-p2p-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*"
     
    },
    body: JSON.stringify({
        user_id,
      order_id,
      type
      
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_p2pCancelOrder(user_id, order_id) {
  return fetch(`${url}/p2p-cancel-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*"
     
    },
    body: JSON.stringify({
        user_id,
      order_id,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}

export function N_p2pCancelIntiateOrder(user_id, order_id) {
  return fetch(`${url}/p2p-cancel-init-order`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      "cache-control": "no-cache",
      "Access-Control-Allow-Origin": "*"
     
    },
    body: JSON.stringify({
        user_id,
      order_id,
    }),
  })
    .then((res) => res.json())
    .catch((e) => e);
}


export function N_paymentMethod(user_id) {
    return fetch(`${url}/banking/payment-method`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*"
       
      },
      body:JSON.stringify({user_id}),
    })
      .then((d) => d.json())
      .catch((e) => e);
  }
  
  export function N_getPaymentMethodUser(user_id, order_id, payment_method) {
    return fetch(`${url}/banking/get-payment-method`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*"
       
      },
      body:JSON.stringify({user_id, order_id, payment_method})
    })
      .then((d) => d.json())
      .catch((e) => e);
  }

  export function N_get_walletp2p(user_id) {
    return fetch(`${url}/get-wallets-p2p`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "cache-control": "no-cache",
        "Access-Control-Allow-Origin": "*"
       
      },
      body:JSON.stringify({user_id}),
    })
      .then((res) => res.json())
      .catch((e) => e);
  }