import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import {
  N_checkKYCStatus,
  N_ischeckKycSubmited,
  N_setKyc,
  N_test
} from "../../utils/api_functions";
const AuthKyc = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state?.user?.value);
  const userid = userInfo.user_id;
  const [visibl, setVisiblity] = useState("h");
  const [doc_title, setTitle] = useState("Adhar");
  const [filedata, setFileData] = useState();

  useEffect(() => {
    N_checkKYCStatus(userid).then((res) => {
      let d = res.data;
      if (d.status === 200) {
        if (d.params.kyc_status === 1 || d.params.kyc_status === -1)
          navigate("../exchange/btc-inr", { replace: true });
      }
    });
  }, []);

  const comp_btn = (event) => {
    const kyc_btn = event.target.parentElement;
    kyc_btn.className = " h";
    setVisiblity("vis");
  };

  // function getCard(event) {
  //   if (document.getElementById("doc_titile").className === "h") {
  //     document.getElementById("next_doc_btn").style.display = "block";
  //   }
  //   console.log("id: ",event.target.id);
  //   if (event.target.id === "adhar_card") {
  //     document.getElementById("adhar").className = "vis";
  //     document.getElementById("dl").className = "h";
  //     document.getElementById("passport").className = "h";
  //     setTitle("Adhar");
  //   }
  //   if (event.target.id === "dl_card") {
  //     document.getElementById("dl").className = "vis";
  //     document.getElementById("adhar").className = "h";
  //     document.getElementById("passport").className = "h";
  //     setTitle("Driver’s License");
  //   }
  //   if (event.target.id === "passport_card") {
  //     document.getElementById("adhar").className = "h";
  //     document.getElementById("dl").className = "h";
  //     document.getElementById("passport").className = "vis";
  //     setTitle("Passport");
  //   }
  // }

  function readURL(input) {
    const current_img = input.target.parentElement;
    console.log("current_img", current_img);
    const img = "#" + current_img.querySelector("img").id;
    const btn = "#" + current_img.querySelector("Button").id;
    const file = input.target.files[0];
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      setFileData(file);
      reader.onload = function (e) {
        $(img).attr("src", e.target.result);
        $(btn).removeClass("h").addClass("vis");
      };
    }
  }
  function nextCard(event) {
    if (event.target.id === "next_doc_btn") {
      document.getElementById("next_doc_btn").style.display = "none";
      $("#doc_titile").removeClass("h").addClass("vis");
      document.getElementById("next_selfie_btn").style.display = "block";
    }
    if (event.target.id === "next_selfie_btn") {
      document.getElementById("next_selfie_btn").style.display = "none";
      $("#pan_detail").removeClass("h").addClass("vis");
    }
  }
  // function updateKycData(event, user_id, ) {
  //   const formControl = event.target.parentElement;
  //   let body = {};
  //   try {
  //     formControl.querySelector(".spinner-border").style.display = "block";
  //     const typeInput = formControl.querySelector("input");
  //     console.log("formControl", formControl, typeInput);
  //     body.user_id = user_id;
  //     if (typeInput.type === "text") {
  //       const val = formControl.querySelector("input").value;
  //       console.log("val", val)
  //       if (typeInput.id === "user_adhar") {
  //         body.data = {
  //           value: val,
  //           type: "Adhar"
  //         };
  //         body.title = "docn";
  //       } else if (typeInput.id === "user_dl") {
  //         body.data = {
  //           value: val,
  //           type: "Driving"
  //         };
  //         body.title = "docn";
  //       } else if (typeInput.id === "user_passport") {
  //         body.data = {
  //           value: val,
  //           type: "Passport"
  //         };
  //         body.title = "docn";
  //       } else if (typeInput.id === "user_pancard") {
  //         body.data = val;
  //         body.title = "pann";
  //       }
  //     }
  //   } catch (e) {
  //     console.log("setkyc: ", e);
  //   }
  //   N_setKyc(body).then((d) => {
  //     let res = d.data;
  //     if (res && res.status === 200) {
  //       // console.log("success: ", res);
  //       $("#" + event.target.id).text("Resend");
  //     }
  //     if (res && res.status === 400) {
  //       console.log("error: ", res);
  //       toast.error(res.message);
  //       $("#" + event.target.id).text("Resend");
  //     }
  //     formControl.querySelector(".spinner-border").style.display = "none";
  //   });
  // }

  function updateKycData(event, user_id, type) {
    const formControl = event.target.parentElement;
    let body = {};
    try {
      formControl.querySelector(".spinner-border").style.display = "block";
      body.user_id = user_id;
        const val = document.getElementById(type).value;
        console.log("val", val)
        if (type === "user_adhar") {
          body.data = {
            value: val,
            type: "Adhar"
          };
          body.title = "docn";
        } else if (type === "user_dl") {
          body.data = {
            value: val,
            type: "Driving"
          };
          body.title = "docn";
        } else if (type === "user_passport") {
          body.data = {
            value: val,
            type: "Passport"
          };
          body.title = "docn";
        } else if (type === "user_pancard") {
          body.data = val;
          body.title = "pann";
        }
    } catch (e) {
      console.log("setkyc: ", e);
    }
    N_setKyc(body).then((d) => {
      let res = d.data;
      if (res && res.status === 200) {
        // console.log("success: ", res);
        toast.success(res.message);
        $("#" + event.target.id).text("Resend");
      }
      if (res && res.status === 400) {
        console.log("error: ", res);
        toast.error(res.message);
        $("#" + event.target.id).text("Resend");
      }
      formControl.querySelector(".spinner-border").style.display = "none";
    });
  }

  function updateKycImages(event, file, title, user_id) {
    event.preventDefault();
    const formControl = event.target.parentElement;
    formControl.querySelector(".spinner-border").style.display = "block";
    const formData = new FormData();
    // The third parameter is required for server
    formData.append("file", file, file.name);
    formData.append("title", title);
    formData.append("user_id", user_id);
    formData.append("data", title);
    console.log("chalform0: ", formData);
    N_test(formData).then((d) => {
      let res = d.data;
      if (res && res.status === 200) {
        console.log("chalform: ", formData);
        $("#" + event.target.id).text("Resend");
      }
      if (res && res.status === 400) {
        toast.error(res.message);
        $("#" + event.target.id).text("Resend");
      }
      formControl.querySelector(".spinner-border").style.display = "none";
    });
  }

  return (
    <>
      {/* <Kychead/> */}
      <div class="page_title">
        <div class="container">
          <div class="page_title_inner">
            <div class="row">
              <div class="col-lg-12 col-12">
                {" "}
                <h1>Complete your KYC</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container page_minheight">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <div class="card my-3">
              <div class="card-body">
                <div className="">
                  <form
                    className="signupform"
                    method="post"
                    id="welcome_form"
                    encType="multipart/form-data"
                  >
                    <div className="">
                      <h5>Complete your KYC</h5>
                      <p className="fs-14">
                        You will be redirected to a trusted third-party service
                        for instant KYC verification where you have to:
                      </p>
                      <ul className="m-0 p-0 fs-13">
                        <li>1. Upload Aadhar</li>
                        <li>2. Click and Upload Selfie</li>
                        <li>3. Upload Pan Card</li>
                      </ul>
                      <div className="mt-3">
                        <b>Note:</b> We have also sent you a website URL via
                        mobile SMS. If you want to continue KYC verification
                        process via mobile, please click on the link in the SMS.
                      </div>
                      <div className="my-3">
                        <button
                          type="button"
                          id="back_per_btn"
                          name="back_per_btn"
                          className="btn btn-primary me-2"
                          onClick={(e) => {
                            navigate("../kyc", { replace: true });
                          }}
                        >
                          Re-submit Kyc Form
                        </button>

                        <button
                          type="button"
                          id="proceed_btn"
                          onClick={(e) => {
                            comp_btn(e);
                          }}
                          className="btn btn-warning me-2"
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                    <div id="Doc_page" className={visibl}>
                      <div className="vis mt-3" id="adhar">
                        <div className="row">
                          <div className="col-lg-5 m-auto">
                            <label htmlFor="user_adhar">
                              ADHAR CARD NUMBER
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                name="user_adhar"
                                required="required"
                                maxLength={12}
                                minLength={12}
                                className="form-control"
                                id="user_adhar"
                                onChange={(e) => {
                                  e.target.value.length == 12
                                    ? (document.getElementById(
                                        "user_adhar_btn"
                                      ).style.display = "block")
                                    : (document.getElementById(
                                        "user_adhar_btn"
                                      ).style.display = "none");
                                }}
                                placeholder="XXXXXXXXXXXX"
                              />
                              <div className="input-group-text bg-info text-white">
                                <button
                                  type="button"
                                  id="user_adhar_btn"
                                  onClick={(e) => {
                                    document.getElementById("user_adhar").value
                                      ? updateKycData(e, userid, "user_adhar")
                                      : toast.error("Not a valid adhaar");
                                  }}
                                  className="btn btn-transparent text-white btn-sm h"
                                >
                                  Send
                                </button>
                                <div
                                  className="spinner-border text-primary"
                                  style={{ display: "none" }}
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-9 m-auto mt-4 ">
                            <div className="row">
                              <div className="col-lg-4 mt-2">
                                <h6>Upload front image Aadhar </h6>
                                <img
                                  src="/img/adhar_front.png"
                                  height="150px"
                                  // width="200px"
                                  id="front_adhar"
                                  alt="adhar pic"
                                  className="img-fluid"
                                />

                                {/* <div className="mt-3 mb-2"> */}
                                  <input
                                    id="upload_front_adhar"
                                    type="file"
                                    onChange={(e) => {
                                      readURL(e);
                                    }}
                                    className="form-control kyc_image mt-3 mb-2"
                                  />
                                {/* </div> */}
                                <div className="d-grid">
                                  <button
                                    type="button"
                                    id="adhar_front_btn"
                                    onClick={(e) => {
                                      updateKycImages(
                                        e,
                                        filedata,
                                        "docf",
                                        userid
                                      );
                                    }}
                                    className="btn btn-warning h"
                                  >
                                    Upload
                                  </button>
                                  <div
                                    className="spinner-border text-primary"
                                    style={{ display: "none" }}
                                    role="status"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-4 mt-2">
                                <h6>Upload back image Aadhar </h6>

                                <img
                                  src="/img/adhar_back.png"
                                  height="150px"
                                  // width="200px"
                                  id="back_adhar"
                                  alt="adhar pic"
                                  className="img-fluid"
                                />
                                {/* <div className="mt-3 mb-2"> */}
                                  <input
                                    id="upload_back_adhar"
                                    type="file"
                                    onChange={(e) => {
                                      readURL(e);
                                    }}
                                    className="form-control kyc_image mt-3 mb-2"
                                  />
                                {/* </div> */}
                                <div className="d-grid">
                                  <button
                                    type="button"
                                    id="adhar_back_btn"
                                    onClick={(e) => {
                                      updateKycImages(
                                        e,
                                        filedata,
                                        "docb",
                                        userid
                                      );
                                    }}
                                    className="btn btn-warning h"
                                  >
                                    Upload
                                  </button>
                                  <div
                                    className="spinner-border text-primary"
                                    style={{ display: "none" }}
                                    role="status"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                </div>
                              </div>

                              {/* <div className="text-center">
                                <button
                                  type="button"
                                  id="next_doc_btn"
                                  onClick={(e) => {
                                    nextCard(e);
                                  }}
                                  className="btn btn-primary btn-lg"
                                >
                                  Next &raquo;
                                </button>
                              </div> */}

                              {/* ==============For Addhar card holding ================= */}
                              <div className="col-lg-4 mt-2">
                                <div className="" id="doc_titile">
                                  <h6>
                                    Upload a photo with holding {doc_title}
                                  </h6>
                                  <img
                                    src="/img/selfie.png"
                                    height="150px"
                                    // width="200px"
                                    id="uploaded_img"
                                    alt="uploaded img pic"
                                    className="img-fluid mb-2"
                                  />

                                  <input
                                    id="uploaded_img"
                                    type="file"
                                    onChange={(e) => {
                                      readURL(e);
                                    }}
                                    className="form-control kyc_image"
                                  />
                                  <div className="d-grid mt-2">
                                    <button
                                      type="button"
                                      id="uploaded_img_btn"
                                      onClick={(e) => {
                                        updateKycImages(
                                          e,
                                          filedata,
                                          "docs",
                                          userid
                                        );
                                      }}
                                      className="btn btn-warning h"
                                    >
                                      Upload
                                    </button>
                                    <div
                                      className="spinner-border text-primary"
                                      style={{ display: "none" }}
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="text-center">
                                  <button
                                    type="button"
                                    id="next_selfie_btn"
                                    onClick={(e) => {
                                      nextCard(e);
                                    }}
                                    className="btn btn-primary btn-lg"
                                    style={{ display: "none" }}
                                  >
                                    Next &raquo;
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4">
                              {/* ==============For PAN card Front Image ================= */}

                              <div className="col-lg-6 m-auto">
                                <div className="" id="pan_detail">
                                  <label htmlFor="user_pancard">
                                    PAN CARD NUMBER *
                                  </label>
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      name="user_pancard"
                                      required="required"
                                      className="form-control"
                                      maxLength={10}
                                      minLength={10}
                                      onChange={(e) => {
                                        e.target.value.length == 10
                                          ? (document.getElementById(
                                              "user_pancard_btn"
                                            ).style.display = "block")
                                          : (document.getElementById(
                                              "user_pancard_btn"
                                            ).style.display = "none");
                                      }}
                                      id="user_pancard"
                                      placeholder="XXXXXXXXXXXX"
                                    />
                                    <div className="input-group-text bg-info text-white">
                                      <button
                                        type="button"
                                        id="user_pancard_btn"
                                        onClick={(e) => {
                                          updateKycData(e, userid, "user_pancard");
                                        }}
                                        className="btn btn-transparent text-white btn-sm h"
                                      >
                                        Send
                                      </button>
                                      <div
                                        className="spinner-border text-primary"
                                        style={{ display: "none" }}
                                        role="status"
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="my-4">
                                  <div className="mt-2">
                                    <h6>Upload front image Pan Card </h6>
                                    <img
                                      src="/img/pan_front.png"
                                      height="150px"
                                      // width="200px"
                                      id="front_pan"
                                      alt="pancard pic"
                                      className="img-fluid mb-2"
                                    />

                                    {/* <div className="mb-2"> */}
                                      <input
                                        id="upload_front_pan"
                                        type="file"
                                        onChange={(e) => {
                                          readURL(e);
                                        }}
                                        className="form-control kyc_image mb-2"
                                      />  
                                      {/* </div> */}
                                      <div className="d-grid">
                                        <button
                                          type="button"
                                          id="front_pan_btn"
                                          onClick={(e) => {
                                            updateKycImages(
                                              e,
                                              filedata,
                                              "panf",
                                              userid
                                            );
                                          }}
                                          className="btn btn-warning"
                                        >
                                          Upload
                                        </button>
                                        <div
                                          className="spinner-border text-primary"
                                          style={{ display: "none" }}
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </div>
                                  
                                  </div>
                                </div>
                              </div>
                            </div>
                            
                          </div>
                          
                        </div>
                        <div className="card-footer bg-transparent text-center">
                              <button
                                type="button"
                                id="Done_btn"
                                onClick={(e) => {
                                  e.preventDefault();
                                  N_ischeckKycSubmited(
                                    userid,
                                    document.getElementById("user_pancard")
                                      .value
                                  ).then((res) => {
                                    let d = res.data;
                                    if (d.status === 200) {
                                      toast.success(d.message);
                                      navigate("../exchange/btc-inr", {
                                        replace: true
                                      });
                                    } else {
                                      toast.error(d.message);
                                    }
                                  });
                                }}
                                className="btn btn-primary btn-lg"
                              >
                                <i className="loading-icon fas fa-spinner fa-spin h"></i>
                                <span id="reg">SUBMIT</span>
                              </button>
                            </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthKyc;
